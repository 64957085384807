import React, { Fragment } from 'react';
import { useState } from 'react';
import { Controller, useForm } from "react-hook-form";
import { Dialog } from '@headlessui/react';
import Drawer from '../../components/Drawer';
import { XIcon, PencilIcon } from '@heroicons/react/outline';
import Select from 'react-select';
import Button from '../../components/Button';
import Toggle from '../../components/Toggle';
import ItemFirst from '../../utils/itemFirstHelper';
import OptionGroupDrawer from './OptionGroups/OptionGroupDrawer';
import AssociatedLocations from './AssociatedLocations';
import cx from 'classnames';

export default function ItemDrawer({ form, payload, setPayload, show, onClose, onSubmit, size = 'xl', usingCache}) {
    const { control, register, handleSubmit } = form;
    const [showDrawer, setShowDrawer] = useState(false);
    const nestedForm = useForm();

    const tabs = [
        { name: 'Details', href: '#' },
        { name: 'Associated Locations', href: '#' },
    ];
    const updateOptionGroup = (obj) => {
        setPayload((prev) => ({
            ...prev,
            ...ItemFirst.updateObject(obj, payload, 'option_groups')
        }));
        setShowDrawer(false);
    };

    const openDrawer = (element) => {
        nestedForm.reset(element);
        setShowDrawer(true);
    };



    const [currentTab, setCurrentTab] = useState(tabs[0]);


    return (
        <Drawer show={show} onClose={onClose} size={size} disableBackdropEvent={true}>
            <div className='p-8 pt-2 pb-0'>
                <div className='border-b flex w-full justify-between'>
                    <Dialog.Title as='h3' className='text-lg text-left leading-6 font-medium text-gray-900 pb-3 mb-5'>
                        Edit Item
                    </Dialog.Title>
                    <span onClick={onClose}>
                        <XIcon className='h-4 w-4'></XIcon>
                    </span>
                </div>

            </div>
            <div className='flex min-w-full h-full flex-col p-8 pt-2 pb-0'>
                <div className="sm:hidden">
                    <select
                        id="tabs"
                        name="tabs"
                        className="block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
                        defaultValue={currentTab}
                    >
                        {tabs.map((tab) => (
                            <option key={tab.name}>{tab.name}</option>
                        ))}
                    </select>
                </div>
                <div className="hidden sm:block min-w-full">
                    <div className="border-b border-gray-200">
                        <nav className="-mb-px flex space-x-8" aria-label="Tabs">
                            {tabs.map((tab) => (
                                <a
                                    onClick={() => setCurrentTab(tab)}
                                    key={tab.name}
                                    href={tab.href}
                                    className={cx(
                                        tab.name == currentTab.name
                                            ? 'border-indigo-500 text-indigo-600'
                                            : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300',
                                        'whitespace-nowrap py-4 px-1 border-b-2 font-medium text-sm'
                                    )}
                                    aria-current={tab.name == currentTab.name ? 'page' : undefined}
                                >
                                    {tab.name}
                                </a>
                            ))}
                        </nav>
                    </div>
                </div>
                <div className="mt-4">
                    {currentTab.name === 'Details' && (
                        <form
                            className='h-full'
                            onSubmit={handleSubmit(onSubmit)}>
                            <div>
                                <div className="mt-4">
                                    <label className='block text-sm font-medium text-gray-700'>
                                        Title
                                    </label>
                                    <div className='mt-1'>
                                        <input
                                            className='appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm'
                                            defaultValue={form.getValues("title")}
                                            {...register("title")}
                                        />
                                    </div>
                                </div>
                                <div className="mt-4">
                                    <label className='block text-sm font-medium text-gray-700'>
                                        Price
                                    </label>
                                    <div className='mt-1'>
                                        <input
                                            className='appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm'
                                            type="number"
                                            defaultValue={form.getValues("price")}
                                            {...register("price", { valueAsNumber: true, })}
                                        />
                                    </div>
                                </div>
                                <div className="mt-4">
                                    <label className='block text-sm font-medium text-gray-700'>
                                        Description
                                    </label>
                                    <div className='mt-1'>
                                        <textarea
                                            className='appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm'
                                            defaultValue={form.getValues("description")}
                                            {...register("description")}
                                        />
                                    </div>
                                </div>
                                <div className="mt-4">
                                    <label className='block text-sm font-medium text-gray-700'>
                                        Categories
                                    </label>
                                    <Controller
                                        name="category_ref_ids"
                                        control={control}
                                        render={
                                            ({ field }) => <Select
                                                {...field}
                                                isMulti
                                                isClearable={false}
                                                className='mt-1'
                                                options={payload.categories}
                                                value={payload.categories.filter(el => form.getValues("category_ref_ids").includes(el.ref_id))}
                                                getOptionLabel={option => option.name}
                                                getOptionValue={option => option.ref_id}
                                                onChange={(e) => field.onChange(e.map(e => e.ref_id))}
                                            >

                                            </Select>
                                        }
                                    >

                                    </Controller>
                                </div>
                                <div className="mt-4">
                                    <label className='block text-sm font-medium text-gray-700'>
                                        Option Groups
                                    </label>
                                    <Controller
                                        name="option_group_ref_ids"
                                        control={control}
                                        render={
                                            ({ field }) => <Select
                                                {...field}
                                                isMulti
                                                className='mt-1'
                                                options={payload.option_groups}
                                                value={payload.option_groups.filter(el => form.getValues("option_group_ref_ids").includes(el.ref_id))}
                                                getOptionLabel={option => option.title}
                                                getOptionValue={option => option.ref_id}
                                                onChange={(e) => field.onChange(e.map(e => e.ref_id))}
                                            >
                                            </Select>
                                        }
                                    >
                                    </Controller>
                                </div>
                                <div className="mt-4">
                                    <label className='block text-sm font-medium text-gray-700'>
                                        Sort Order
                                    </label>
                                    <div className='mt-1'>
                                        <input
                                            className='appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm'
                                            type="number"
                                            defaultValue={form.getValues("sort_order")}
                                            {...register("sort_order", { valueAsNumber: true, })}
                                        />
                                    </div>
                                </div>
                                <div className='mt-4 flex justify-between '>
                                    <div className='flex align-middle w-1/2 justify-between mr-2'>
                                        <label className='text-sm mr-2 font-medium text-gray-700'>
                                            Active
                                        </label>
                                        <Controller
                                            name="active"
                                            control={control}
                                            render={
                                                ({ field }) => <Toggle
                                                    enabled={form.getValues('active')}
                                                    onChange={(e) => field.onChange(e)}
                                                />
                                            }
                                        >
                                        </Controller>
                                    </div>
                                    <div className='flex align-middle w-1/2 justify-between'>
                                        <label className='text-sm mr-2 font-medium text-gray-700'>
                                            Recommended
                                        </label>
                                        <Controller
                                            name="recommended"
                                            control={control}
                                            render={
                                                ({ field }) => <Toggle
                                                    enabled={form.getValues('recommended')}
                                                    onChange={(e) => field.onChange(e)}
                                                />
                                            }
                                        >
                                        </Controller>
                                    </div>
                                </div>
                                <div className='mt-4 pb-4'>
                                    <label className='text-sm mr-2 font-medium text-gray-700'>
                                        Option Groups
                                    </label>
                                    <OptionGroups
                                        ogIds={form.getValues('option_group_ref_ids')}
                                        openDrawer={openDrawer}
                                        payload={payload}
                                    />
                                </div>
                                <div className='flex mt-auto justify-between mt-8'>
                                    <Button onClick={onClose} type="plain">Cancel</Button>
                                    <Button htmlType='submit'>Submit</Button>
                                </div>
                            </div>
                        </form>)}
                    {currentTab.name == 'Associated Locations' && (
                        <AssociatedLocations
                            usingCache={usingCache}
                            objKey='item'
                            obj={form.getValues()}
                        >
                        </AssociatedLocations>
                    )}
                </div>

            </div>

            <OptionGroupDrawer
                show={showDrawer}
                payload={payload}
                setPayload={setPayload}
                form={nestedForm}
                onClose={(e) => setShowDrawer(false)}
                onSubmit={updateOptionGroup}
                size='lg'
            />
        </Drawer>
    );
}
export const OptionGroups = ({ ogIds, openDrawer, payload }) => {
    let options = ogIds.map(el => ItemFirst.getObjectById(el, payload.option_groups, 'ref_id'));
    return options.map(el => {
        return (
            <Fragment key={el?._id}>
                <div className="flex mt-2 text-sm justify-between" key={el?._id}>
                    <span>
                        - {el?.title}
                    </span>
                    <span
                        className='mr-4'
                        onClick={() => openDrawer(el)}
                    >
                        <PencilIcon className="w-4 h-4 hover:text-indigo-800 text-indigo-500" />
                    </span>
                </div>
            </Fragment>);
    });
};
