import React, { Fragment, useState } from 'react';
import { useQuery } from 'react-query';
import { useHistory, useParams } from 'react-router-dom';
import EntityApi from '../../apis/entity';
import ListBox from '../../components/ListBox';
import AnalyticsService from "../../services/AnalyticsService";

const InputAggUrl = () => {
    let { envId = '' } = useParams();
    const history = useHistory();
    const [selected, setSelected] = useState({});
    const [form, setForm] = useState({});


    const { isLoading, isError, data } = useQuery(['supportedAggregators'],
        () => EntityApi.getSupportedAggregators(),
        {
            onSuccess: (data) => {
                setSelected(data[0]);
            }
        }
    );

    const submit = () => {
        let data = { ...form };
        Object.keys(data).map(element => {
            if (typeof data[element] != 'string') {
                data[element] = data[element].value;
            }
        });
        EntityApi.menuImport(envId, 'AGGREGATOR', selected.name, data).then(response => {
            AnalyticsService.onImport('Aggregator', {
                name: data.aggregator,
            });

            history.push({
                pathname: '/entity/' + response.id
            });
        });
    };
    const inputHandler = (e, name = null) => {
        if ('target' in e) {
            setForm({ ...form, [e.target.name]: e.target.value });
            var reader = new FileReader();
            if (e.target.name === 'json_file') {
                reader.onload = function () {
                    let jsonData = JSON.parse(reader.result);
                    jsonData = JSON.stringify(jsonData);
                    setForm({ ...form, [e.target.name]: jsonData });

                };
                reader.readAsBinaryString(e.target.files[0]);
            }
        }
        else {
            setForm({ ...form, [name]: e });
        }
        // setInput(e.target.value);
    };

    if (!isLoading && !isError) {
        return (
            <div className="w-full">
                <div className="mt-32 max-w-md mx-auto bg-white w-1/4 h-min shadow overflow-hidden sm:rounded-lg pb-7">
                    <div className="px-4 py-6 sm:px-6">
                        <h3 className="text-md leading-6 font-medium text-gray-900">Aggregator Menu</h3>
                        <p className="mt-1 max-w-xs text-sm text-gray-500">Import menu directly from an aggregator</p>
                    </div>
                    <div className="px-4 sm:px-6 mt-2">
                        <ListBox value={selected}
                            onChange={setSelected}
                            label="Aggregator"
                            labelKey="name"
                            options={data}
                        >
                        </ListBox>
                        {
                            selected.config && (
                                Object.keys(selected.config).map((element, index) => {
                                    return (
                                        <Fragment key={index}>
                                            <label className='block mt-2 text-sm font-medium text-gray-700'>
                                                {selected.config[element].label}
                                            </label>
                                            {
                                                selected.config[element].type != 'select' && selected.config[element].type != 'textarea' &&  selected.config[element].type != 'object' && (
                                                    <input type={selected.config[element].type} name={element} onChange={inputHandler}
                                                        className="mt-1 appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm">
                                                    </input>
                                                )
                                            }
                                            {
                                                selected.config[element].type == 'select' && (
                                                    <ListBox value={form[element]}
                                                        onChange={(e) => inputHandler(e, element)}
                                                        labelKey="label"
                                                        options={selected.config[element].values}
                                                    >
                                                    </ListBox>
                                                )
                                            }
                                            {
                                                selected.config[element].type == 'textarea' && (
                                                    <textarea name={element} onChange={inputHandler}
                                                        className="mt-1 appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm">
                                                    </textarea>
                                                )
                                            }
                                            {
                                                selected.config[element].type == 'object' && (
                                                    <div className="w-full">
                                                        <input
                                                            className='mt-3'
                                                            type="file"
                                                            accept=".json,application/json"
                                                            name={element}
                                                            onChange={inputHandler}
                                                        />
                                                    </div>
                                                )
                                            }
                                        </Fragment>
                                    );
                                })
                            )
                        }
                        <button
                            type="button"
                            className="mt-8 w-full inline-flex items-center justify-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                            onClick={submit}
                        >
                            Submit
                        </button>
                    </div>
                </div>
            </div>
        );
    } else {
        return <div>Loading</div>;
    }
};
export default InputAggUrl;
