import {
  ClockIcon,
  RefreshIcon,
  XCircleIcon,
  ChevronDoubleRightIcon,
} from '@heroicons/react/outline';

import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import Button from '../../components/Button';
import Uuid from '../../components/Uuid';
import MenuLayout from '../MenuDetail/MenuLayout';
import EISApi from '../../apis/eis';
import Swal from 'sweetalert2';
import JsonModal from '../../components/JsonModal';


function useCountdownTimer(initialCountdown) {
  const [countdown, setCountdown] = React.useState(initialCountdown);

  React.useEffect(() => {
    const interval = setInterval(() => {
      setCountdown((countdown) => countdown - 1);
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  return countdown;
}


const MenuDetailCPS = () => {
  let { bizId, eisEnv, purposeId } = useParams();

  const [menuRequestStatus, setMenuRequestStatus] = useState('loading');
  const [menuRequestError, setMenuRequestError] = useState('');
  const [activeCategory, setActiveCategory] = useState(null);
  const [payload, setPayload] = useState({
    categories: [],
    items: [],
  });

  const [showJson, setShowJson] = useState(false);
  const countdown = useCountdownTimer(5);

  const handleViewClick = () => {
    setShowJson(true);
  };

  const handleCloseClick = () => {
    setShowJson(false);
  };

  const handleDownload = () => {
    const json = JSON.stringify(payload, null, 2);
    const blob = new Blob([json], { type: 'application/json' });
    const url = URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = `${bizId}_${purposeId}.json`;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    URL.revokeObjectURL(url);
  };

  const handlePushToCodex = () => {
    Swal.fire({
      title: `Push to Codex?`,
      html: `Are you sure you want to push the menu to <br><strong>Biz ID:</strong> ${bizId}?`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#4F46E5',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, push it!',
    }).then((result) => {
      if (result.isConfirmed) {
        EISApi.pushMenu(eisEnv, bizId, purposeId)
          .then((response) => {
            if (response.status === 'success') {
              Swal.fire({
                title: 'Success!',
                html: `The menu has been pushed to <strong>Biz ID:</strong> ${bizId}.`,
                icon: 'success',
                confirmButtonColor: '#4F46E5',
                confirmButtonText: 'OK',
              });
            } else {
              Swal.fire({
                title: 'Error!',
                html: `There was an error pushing the menu to <strong>Biz ID:</strong> ${bizId}. Please try again later.`,
                icon: 'error',
                confirmButtonColor: '#4F46E5',
                confirmButtonText: 'OK',
              });
            }
          })
          .catch((error) => {
            Swal.fire({
              title: 'Error!',
              html: `There was an error pushing the menu to <strong>Biz ID:</strong> ${bizId}. Please try again later.`,
              icon: 'error',
              confirmButtonColor: '#4F46E5',
              confirmButtonText: 'OK',
            });
          });
      }
    });
  };

  React.useEffect(() => {
    if (
      countdown === 0 &&
      menuRequestStatus !== 'processed' &&
      menuRequestStatus !== 'completed' &&
      menuRequestStatus !== 'pushed' &&
      menuRequestStatus !== 'failed'
    ) {
      window.location.reload();
    }
  }, [countdown, menuRequestStatus]);

  React.useEffect(() => {
    EISApi.viewMenu(eisEnv, bizId, purposeId) // Pass flush value to menuRequest function
      .then((response) => {
        if (response.status === 'success') {
          setMenuRequestStatus(response.details.menu_request_status);
          setMenuRequestError(response.details.menu_request_error);
          if (Object.keys(response.details.menu_data).length > 0) {
            setPayload(JSON.parse(response.details.menu_data));
          }
        } else {
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: response,
          });
          return;
        }
      })
      .catch((error) => {
        Swal.fire({
          icon: 'error',
          title: error.message,
          text: error.details,
        });
      });
  }, []);

  const isProcessed = menuRequestStatus === 'processed';
  const isPushed = menuRequestStatus === 'pushed';
  const isLoading = menuRequestStatus === 'loading';
  const isPending = menuRequestStatus === 'pending';
  const isInProgress = menuRequestStatus === 'in_progress';
  const isFailed = menuRequestStatus === 'failed';
  const isCompleted = menuRequestStatus === 'completed';

  const pushToCodexText = isProcessed
    ? 'Push to Codex'
    : isPushed
    ? 'Push Again to Codex'
    : isCompleted
    ? 'Repush to Codex'
    : '';

  return (
    <div className='w-full max-w-7xl mx-auto shadow-md bg-white p-5'>
      <div className='border-b pb-5'>
        <div className='px-4 py-2 flex justify-between'>
          <div className='flex'>
            <div className='p-3 bg-blue-100 px-2 rounded-lg'>
              <h3 className='text-blue-600 text-xs'>
                Biz ID - <Uuid>{bizId}</Uuid>
              </h3>
            </div>
            <div className='mx-1 p-3 bg-green-100 px-2 rounded-lg'>
              <h3 className='text-green-600 text-xs'>
                Purpose ID - <Uuid>{purposeId}</Uuid>
              </h3>
            </div>
            <div className='p-3 bg-yellow-100 px-2 rounded-lg'>
              <h3 className='text-yellow-600 text-xs'>CPS Env - {eisEnv}</h3>
            </div>
          </div>

          {(isProcessed || isCompleted || isPushed) && (
            <div>
              <Button type='' onClick={handleViewClick}>
                View
              </Button>

              <JsonModal
                show={showJson}
                onClose={handleCloseClick}
                data={payload}
                title={purposeId}
                width='50%'
                height='700px'
              />

              <Button
                type='secondary'
                onClick={handleDownload}
                className='mx-2'
              >
                Download
              </Button>

              <Button type='primary' onClick={handlePushToCodex}>
                {pushToCodexText}
              </Button>
            </div>
          )}
        </div>
      </div>

      {!(isProcessed || isPushed || isCompleted) ? (
        <div className='px-10 py-16 h-4/5'>
          <div className='flex justify-center px-10 py-16 h-4/5 items-center'>
            {isLoading && (
              <div className='flex flex-col items-center'>
                <span className='bg-yellow-100 p-5 rounded-md w-min'>
                  <RefreshIcon className='h-8 w-8 text-yellow-500 animate-spin' />
                </span>
                <span className='pt-2 text-yellow-500 uppercase text-sm font-bold'>
                  Loading...
                </span>
              </div>
            )}
            {isPending && (
              <div className='flex flex-col items-center'>
                <span className='bg-yellow-100 p-5 rounded-md w-min'>
                  <ClockIcon className='h-8 w-8 text-yellow-500' />
                </span>
                <span className='pt-2 text-yellow-500 uppercase text-sm'>
                  In Queue
                </span>
              </div>
            )}
            {isInProgress && (
              <div className='flex flex-col items-center'>
                <span className='bg-green-100 p-5 rounded-md w-min'>
                  <ChevronDoubleRightIcon className='h-8 w-8 text-green-500' />
                </span>
                <span className='pt-2 uppercase text-sm text-green-500'>
                  In Progress
                </span>
              </div>
            )}
            {isFailed && (
              <div className='flex flex-col items-center'>
                <span className='bg-red-100 p-5 rounded-md w-min'>
                  <XCircleIcon className='h-8 w-8 text-red-500' />
                </span>
                <span className='pt-2 uppercase text-sm text-red-500'>
                  Failed
                </span>
                <span className='text-sm mt-5 text-gray-600'>
                  {menuRequestError}
                </span>
              </div>
            )}
          </div>

          {(isPending || isInProgress) && (
            <div className='mt-5 flex flex-col items-center'>
              <div className='bg-blue-100 p-5 rounded-md w-90 flex items-center justify-center'>
                <RefreshIcon className='h-8 w-8 text-blue-500 animate-spin mr-2' />
                <span className='text-blue-500 uppercase text-sm font-bold'>
                  Reloading in{' '}
                  <span className='text-2xl font-bold'>{countdown}</span>{' '}
                  seconds...
                </span>
              </div>
            </div>
          )}
        </div>
      ) : (
        <MenuLayout
          payload={payload}
          setPayload={setPayload}
          activeCategory={activeCategory}
          setActiveCategory={setActiveCategory}
        />
      )}
    </div>
  );
};

export default MenuDetailCPS;
