import cx from 'classnames';
import React from 'react';
import { useHistory } from 'react-router-dom';

export default function Button({ children, Icon, onClick, to, type = 'primary', className, htmlType = 'button', size='md'}) {
    const history = useHistory();

    function _onClick() {
        if (to) {
            history.push(to);
            return;
        }

        if (onClick) {
            onClick();
        }
    }

    // add size logic with options xs, sm, md, lg, xl
    const sizeClass = size === 'xs' ? 'px-2 py-1 text-xs' : size === 'sm' ? 'px-3 py-2 text-sm' : size === 'md' ? 'px-4 py-2 text-base' : size === 'lg' ? 'px-6 py-3 text-lg' : size === 'xl' ? 'px-8 py-4 text-xl' : 'px-4 py-2 text-base';

    // add icon size logic with options xs, sm, md, lg, xl
    const iconSizeClass = size === 'xs' ? 'w-4 h-4' : size === 'sm' ? 'w-5 h-5' : size === 'md' ? 'w-6 h-6' : size === 'lg' ? 'w-7 h-7' : size === 'xl' ? 'w-8 h-8' : 'w-6 h-6';

    let classString = null;

    if (type === 'error') {
        classString = 'text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500';
    } else if (type === 'primary') {
        classString = 'text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500';

    } else {
        classString = 'border-gray-300 text-gray-600 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500';
    }

    return (
        <button
            type={htmlType}
            className={cx(`inline-flex items-center justify-center ${sizeClass} border border-transparent shadow-sm font-medium rounded-md`, classString, className)}
            onClick={_onClick}
        >
            {children}
            {Icon && <Icon className={`${iconSizeClass} `} />}
        </button>
    );
}
