import { Axios } from 'axios';
import { AxiosInstance } from '../services/axios';

const inputJson = (data, enId) => {
    return AxiosInstance.post('/api/v1/import/' + 'json/' + enId, data).then(
        (response) => response.data
    );
};

const menuImport = (enId, adapterType='', name='', params={}, useDefaultConfig=false) => {
    let data = {};
    if (useDefaultConfig) {
        data['use_default_config'] = true;
    } else {
        data['params'] = params;
        data['name'] = name;
        data['type'] = adapterType;
    }
    return AxiosInstance.post(`/api/v1/import/${enId}`, data).then(
        (response) => response.data
    ); 
};

const menuImportUseDefaultConfig = (entityId) => {
    return AxiosInstance.post(`/api/v1/import/${entityId}`, {use_default_config: true}).then(
        (response) => response.data
    );
};

const menuExport = (enId, adapterType, name, params) => {
    let data = {};
    data['params'] = params;
    data['name'] = name;
    data['type'] = adapterType;
    return AxiosInstance.post(`/api/v1/export/${enId}`, data).then(
        (response) => response.data
    );
};
// discard
const inputPOS = (data, enId) => {
    return AxiosInstance.post('/api/v1/import/' + 'pos/' + enId, data).then(
        (response) => response.data
    );
};

const inputAggregator = (data, enId) => {
    return AxiosInstance.post('/api/v1/import/' + 'aggregator_url/' + enId, data).then(
        (response) => response.data
    );
};

const inputCodex = (data, enId) => {
    return AxiosInstance.post('/api/v1/import/' + 'codex/' + enId, data).then(
        (response) => response.data
    );
};
// 
const getBizdetails = (data) => {
    return AxiosInstance.post('/api/v1/codex/biz-details', data).then(
        (response) => response.data
    );
};


const getEntity = (id) => {
    return AxiosInstance.get('/api/v1/entity/' + id).then(
        (response) => response.data
    );
};

const updateEntity = (id, data) => {
    return AxiosInstance.put('/api/v1/entity/' + id, data).then(
        (response) => response.data
    );
};

const exportOutputJSON = (enId, data) => {
    return AxiosInstance.post(`/api/v1/${enId}/output/json`, data).then(
        (response) => response.data
    );
};

const exportPublicUrl = (enId, data) => {
    return AxiosInstance.post(`/api/v1/${enId}/output/public-url`, data).then(
        (response) => response.data
    );
};

const publishToCodex = (enId, data) => {
    return AxiosInstance.post(`/api/v1/${enId}/output/codex`, data).then(
        (response) => response.data
    );
};

// create a function to get object from cache
const getFromCache = (revId, key, refId) => {
    return AxiosInstance.post(`/api/v1/revision/${revId}/cache/`, {
        'key': key,
        'ref_id': refId,
        'action': 'get'
    }).then((response) => response.data);
};

const downloadJson = (data) => {
    return AxiosInstance.post('api/v1/download', data, { responseType: 'blob' }).then(
        (response) => response
    );
};

const listEntities = (data) => {
    return AxiosInstance.get('/api/v1/entities', {
        params: data,
    }).then(
        (response) => response.data
    );
};

const allEntities = (data) => {
    return AxiosInstance.get('/api/v1/all-entities', {
        params: data,
    }).then(
        (response) => response.data
    );
};

const getRevision = ({queryKey}) => {
    const [_, id, params] = queryKey;
    return AxiosInstance.get(`/api/v1/revision/${id}/`, {
        'params': params
    }).then(
        (response) => response.data
    );
};

const createRevision = (entity_id, data) => {
    return AxiosInstance.post('/api/v1/entity/' + entity_id + '/revision/', data).then(
        (response) => response.data
    );
};

const getSupportedAggregators = () => {
    return AxiosInstance.get('/api/v1/merchant/supported-aggregators').then(
        (response) => response.data
    );
};

const getSupportedPOS = () => {
    return AxiosInstance.get('/api/v1/merchant/supported-pos').then(
        (response) => response.data
    );
};

const getPublicReview = (public_url_id) => {
    return AxiosInstance.get('/api/v1/public/url/' + public_url_id + '/menu').then(
        (response) => response.data
    );
};

const confirmReview = (entity_id, url_id, data) => {
    return AxiosInstance.post('/api/v1/public/entity/' + entity_id + '/url/' + url_id + '/menu-revision', data).then(
        response => response.data
    );
};
const bizValidate = (data) => {
    return AxiosInstance.post('/api/v1/validate-biz', data).then(
        (response) => response.data
    );
};

const createEntity = (data) => {
    return AxiosInstance.post('api/v1/entity', data).then(
        (response) => response.data
    );
};

const oauthCallback = (vendor, data) => {
    return AxiosInstance.post(`/api/v1/oauth/callback/${vendor}`, data).then(
        response => response.data
    );
};

const EntityApi = {
    inputJson,
    getEntity,
    updateEntity,
    listEntities,
    allEntities,
    getRevision,
    exportOutputJSON,
    exportPublicUrl,
    getSupportedAggregators,
    getSupportedPOS,
    inputAggregator,
    publishToCodex,
    getPublicReview,
    confirmReview,
    getBizdetails,
    inputPOS,
    inputCodex,
    downloadJson,
    createRevision,
    menuImport,
    getFromCache,
    menuExport,
    oauthCallback,
    bizValidate,
    createEntity,
    menuImportUseDefaultConfig
};

export default EntityApi;
