

const findParentOG = (optionGroups, payload) => {
    let parents = [];
    optionGroups.forEach(element => {
        let options = payload?.options.filter(el => el.nested_opt_grps.includes(element));
        let prev = options.map(el => el.option_group_ref_ids);
        // flatten
        prev = [].concat(...prev);
        parents.push(...prev);
    });
    if (parents?.length > 0) {
        parents.push(...findParentOG(parents, payload));
    }
    return parents;
};

const findOptions = (optionGroup, payload) => {
    let options = payload?.options.filter(el => el.option_group_ref_ids.includes(optionGroup.ref_id));
    return options;
};

const getObjectById = (ref_id, list, key) => {
    let object = list.find(element => element[key] == ref_id);  
    return object;
};

const deleteObjectByIndex = (payload, key, index) => {
    let result = [
        ...payload[key].slice(0, index),
        ...payload[key].slice(index + 1)
    ];
    return {
        [key]: result
    };
};

const deleteObject = (item, payload, key) => {
    let pos = (payload[key] || []).findIndex(el => el.ref_id === item.ref_id);
    if (pos !== -1) {
        return deleteObjectByIndex(payload, key, pos);
    } else return pos;
};

// returns new array
const updateObjectByIndex = (item, payload, key, index) => {
    let items =  [
        ...payload[key].slice(0, index),
        item,
        ...payload[key].slice(index + 1)
    ];
    return {[key]: items};
};

const updateObject = (item, payload, key) => {
    let pos = (payload[key] || []).findIndex(el => el.ref_id === item.ref_id);
    if (pos !== -1) {
        return updateObjectByIndex(item, payload, key, pos);
    } else return pos;
};

const getItems = (optionGroup, payload) => {
    let items = [];
    let parents = ItemFirst.findParentOG([optionGroup], payload);
    parents.push(optionGroup);
    parents.forEach(ref_id => {
        let filtered = payload.items.filter(el => 
            el.option_group_ref_ids.includes(ref_id)
        );
        items.push(...filtered);
    });
    items = items.filter((value, index) => {
        const _value = value.ref_id;
        return index === items.findIndex(obj => {
            return obj.ref_id === _value;
        });
    });
    return items;
};

const deleteItem = (item_id, payload) => {
    let items = [...payload.items];
    let deletedItem = items.find((element) => element.ref_id == item_id);
    items = items.filter(item => item.ref_id != item_id);
    let options = payload.options.map(element => Object.assign({}, element));
    let optionGroups = payload.option_groups.map(element => Object.assign({}, element));
    let optGrpToBeChecked = [...deletedItem.option_group_ref_ids];
    optGrpToBeChecked = optGrpToBeChecked.map(element => {
        let flag = false;
        items.some(item => {
            if ((item?.option_group_ref_ids || []).includes(element)) {
                flag = true;
                return true;
            }
        });
        options.some(item => {
            if ((item?.nested_opt_grps || []).includes(element)) {
                flag = true;
                return true;
            }
        });
        if (flag) return null;
        else return element;
    });
    let optionsToBeDeleted = [];
    options.forEach(option => {
        optGrpToBeChecked.map(element => {
            let position = option.option_group_ref_ids.indexOf(element);
            if (position > -1) {
                option.option_group_ref_ids = [...option.option_group_ref_ids.slice(0, position), ...option.option_group_ref_ids.slice(position + 1, option.option_group_ref_ids.length)];
            }
        });
        if (option.option_group_ref_ids.length <= 0) {
            optionsToBeDeleted.push(option.ref_id);
        }
    });
    options = options.filter(element => !optionsToBeDeleted.includes(element.ref_id));
    optionGroups = optionGroups.filter(element => !optGrpToBeChecked.includes(element.ref_id));
    // setPayload((prev) => ({
    //     ...prev,
    //     'items': items,
    //     'options': options,
    //     'option_groups': optionGroups
    // }));
    return {
        'items': items,
        'options': options,
        'option_groups': optionGroups
    };
};

const ItemFirst = {
    findParentOG,
    findOptions,
    getObjectById,
    getItems,
    updateObject,
    updateObjectByIndex,
    deleteItem,
    deleteObjectByIndex,
    deleteObject
};


export default ItemFirst;