import React from 'react';
import { Link, useParams } from 'react-router-dom';
import {
    CodeIcon,
    GlobeAltIcon,
    ClockIcon,
    CashIcon
} from '@heroicons/react/outline';

function classNames(...classes) {
    return classes.filter(Boolean).join(' ');
}

const Home = () => {
    let { envId } = useParams();
    const actions = [
        {
            icon: CodeIcon,
            title: 'JSON',
            href: '/input/json',
            iconForeground: 'text-red-700',
            iconBackground: 'bg-red-50',
            description: 'Import menu from an item first JSON file',
        },
        {
            icon: ClockIcon,
            title: 'Codex',
            href: '/input/codex',
            iconForeground: 'text-green-700',
            iconBackground: 'bg-green-50',
            description: 'Import Menu from Codex',
        },
        {
            icon: GlobeAltIcon,
            title: 'Aggregator Menu',
            href: '/input/agg-url',
            iconForeground: 'text-blue-700',
            iconBackground: 'bg-blue-50',
            description: 'Import menu directly from an aggregator',
        },
        {
            icon: CashIcon,
            title: 'POS',
            href: '/input/pos',
            iconForeground: 'text-pink-700',
            iconBackground: 'bg-pink-50',
            description: 'Import menu directly from an aggregator',
        },
    ];
    actions.map(el => {
        if(envId) {
            el.href = el.href + '/' + envId;
        }
    });
    return (
      <div className='w-full'>
        <section
          aria-labelledby='quick-links-title'
          className='px-18 flex flex-col items-center'
        >
          <header className='mt-32'>
            <div className='max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 text-center'>
              <h1 className='text-3xl font-bold leading-tight text-gray-900'>
                Import a Menu
              </h1>
              <p className='text-gray-500 mt-1'>
                Select how you want to Import your menu to MARS
              </p>
            </div>
          </header>
          <div className='mt-16'>
            <div className='text-center py-4 lg:px-4'>
              <Link to='input/cps' className='focus:outline-none'>
                <div
                  className='p-2 bg-indigo-800 items-center text-indigo-100 leading-none lg:rounded-full flex lg:inline-flex'
                  role='alert'
                >
                  <span className='flex rounded-full bg-indigo-500 uppercase px-2 py-1 text-xs font-bold mr-3'>
                    beta
                  </span>
                  <span className='font-semibold mr-2 text-left flex-auto'>
                    Import menu from POS with CPS backend!
                  </span>
                  <svg
                    className='fill-current opacity-75 h-4 w-4'
                    xmlns='http://www.w3.org/2000/svg'
                    viewBox='0 0 20 20'
                  >
                    <path d='M12.95 10.707l.707-.707L8 4.343 6.586 5.757 10.828 10l-4.242 4.243L8 15.657l4.95-4.95z' />
                  </svg>
                </div>
              </Link>
            </div>
          </div>

          <div className='mt-16'>
            <GridCard actions={actions} />
          </div>
        </section>
      </div>
    );
};

function GridCard({ actions }) {
    return (
        <div className="rounded-lg bg-gray-200 overflow-hidden shadow divide-y divide-gray-200 sm:divide-y-0 sm:grid sm:grid-cols-2 sm:gap-px">

            {actions.map((action, actionIdx) => (
                <div
                    key={action.title}
                    className={classNames(
                        actionIdx === 0 ? 'rounded-tl-lg rounded-tr-lg sm:rounded-tr-none' : '',
                        actionIdx === 1 ? 'sm:rounded-tr-lg' : '',
                        actionIdx === actions.length - 2 ? 'sm:rounded-bl-lg' : '',
                        actionIdx === actions.length - 1 ? 'rounded-bl-lg rounded-br-lg sm:rounded-bl-none' : '',
                        'relative group bg-white p-6 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-500'
                    )}
                >
                    <div>
                        <span
                            className={classNames(
                                action.iconBackground,
                                action.iconForeground,
                                'rounded-lg inline-flex p-3 ring-4 ring-white'
                            )}
                        >
                            <action.icon className="h-6 w-6" aria-hidden="true" />
                        </span>
                    </div>
                    <div className="mt-8">
                        <h3 className="text-lg font-medium">
                            <Link to={action.href} className="focus:outline-none">
                                {/* Extend touch target to entire panel */}
                                <span className="absolute inset-0" aria-hidden="true" />
                                {action.title}
                            </Link>
                        </h3>
                        <p className="mt-2 text-sm text-gray-500">
                            {action.description}
                        </p>
                    </div>
                    <span
                        className="pointer-events-none absolute top-6 right-6 text-gray-300 group-hover:text-gray-400"
                        aria-hidden="true"
                    >
                        <svg className="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 24 24">
                            <path d="M20 4h1a1 1 0 00-1-1v1zm-1 12a1 1 0 102 0h-2zM8 3a1 1 0 000 2V3zM3.293 19.293a1 1 0 101.414 1.414l-1.414-1.414zM19 4v12h2V4h-2zm1-1H8v2h12V3zm-.707.293l-16 16 1.414 1.414 16-16-1.414-1.414z" />
                        </svg>
                    </span>
                </div>
            ))}
        </div>
    );
}

export default Home;
