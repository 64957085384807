import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useQuery } from "react-query";
import EntityApi from "../../apis/entity";
import Button from "../Button";
import ConfigSelector from "../ConfigSelector";
import Modal from "../Modal";

export default function EditDefaultConfig({ configs, show, onClose, onSave }) {

    const form = useForm();

    const { isLoading, isError, data, error } = useQuery(['supportedPOS'],
        () => EntityApi.getSupportedPOS(),
        {
            onSuccess: (data) => {
                if (configs?.length > 0) {
                    form.reset({ ...configs[0] });
                }
            }
        }
    );
    
    const getConfigSchemaByName = (schemas, name) => {
        return schemas.find((el) => el.name == name);
    };

    if (isLoading || isError) {
        return <div>Loading..</div>;
    }

    return <Modal show={show} onClose={onClose}>
        <div className='bg-white flex flex-col py-8 px-4 shadow sm:rounded-lg sm:px-10'>
            {/* <form className='space-y-6' onSubmit={handleSubmit(onSubmit)}> */}
            <div className="pb-4">
                Edit Default Config
            </div>
            <ConfigSelector
                form={form}
                configSchema={data}
            >

            </ConfigSelector>
            <Button
                onClick={() => onSave(form.getValues())}
                className='ml-auto'
            >
                Submit
            </Button>
        </div>
    </Modal>;
}
