// create react component badge with tailwind ui with variable size and color
//
// Path: mars-ui/src/components/Badge/index.js

import React from 'react';
import cx from 'classnames';

const Badge = ({ children, size, type }) => {
    // add size logic with options xs, sm, md, lg, xl
    const sizeClass = size === 'xs' ? 'px-2 py-1 text-xs' : size === 'sm' ? 'px-3 py-2 text-sm' : size === 'md' ? 'px-4 py-2 text-base' : size === 'lg' ? 'px-6 py-3 text-lg' : size === 'xl' ? 'px-8 py-4 text-xl' : 'px-4 py-2 text-base';

    // add color logic with primary, secondary, success, danger, warning, info, light, dark
    const colorClass = type === 'primary' ? 'bg-indigo-100 text-indigo-800' : type === 'secondary' ? 'bg-gray-100 text-gray-800' : type === 'success' ? 'bg-green-100 text-green-800' : type === 'danger' ? 'bg-red-100 text-red-800' : type === 'warning' ? 'bg-yellow-100 text-yellow-800' : type === 'info' ? 'bg-white-800 text-grey-800' : type === 'light' ? 'bg-gray-200 text-gray-800' : type === 'dark' ? 'bg-gray-800 text-white' : 'bg-indigo-100 text-indigo-800';

    // add border color logic with primary, secondary, success, danger, warning, info, light, dark
    const borderColorClass = type === 'primary' ? 'border-indigo-200' : type === 'secondary' ? 'border-gray-200' : type === 'success' ? 'border-green-200' : type === 'danger' ? 'border-red-200' : type === 'warning' ? 'border-yellow-200' : type === 'info' ? 'border-white-200' : type === 'light' ? 'border-gray-200' : type === 'dark' ? 'border-gray-800' : 'border-indigo-200';

    // add border to badge
    const borderClass = 'border';

    const classString = cx(colorClass, borderClass, borderColorClass)

    return (
        <span className={`inline-flex fit-content items-center ${sizeClass} rounded-md ${ classString }`}>
            {children}
        </span>
    );
};

export default Badge;
