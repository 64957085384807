import React from 'react';
import { Redirect } from 'react-router-dom';
import About from '../pages/About';
import Home from '../pages/Home';
import MenuDetail from '../pages/MenuDetail';
import ExportAs from '../pages/ExportAs';
import EntityDetail from '../pages/EntityDetail';
import InputJson from '../pages/InputJson';
import InputCodex from '../pages/InputCodex';
import InputAggUrl from '../pages/InputAggUrl';
import InputCPS from '../pages/InputCPS';
import Login from '../pages/Login';
import Router from '../router';
import LocalStorageService from '../services/LocalStorage';
import EntityList from '../pages/EntityList';
import ExportJson from '../pages/ExportJson';
import ExportReview from '../pages/ExportReview';
import ExportCodex from '../pages/ExportCodex';
import PublicReview from '../pages/PublicReview';
import ThankYou from '../pages/Thankyou';
import InputPOS from '../pages/InputPOS';
import AllEntityList from '../pages/AllEntities';
import CreateEntity from '../pages/CreateEntity';
import V2Entity from '../pages/V2Entity/container';
import WorkflowList from '../pages/V2/WorkflowList';
import Request from '../pages/V2/Request';
import AuthorizeVendor from '../pages/AuthorizeVendor';
import AuthThankyou from '../pages/AuthorizeVendor/AuthorizationThankyou';
import V2List from '../pages/V2List';
import MenuDetailCPS from '../pages/MenuDetailCPS/container';

const routes = [
    {
        path: '/login',
        key: 'ROOT',
        exact: true,
        component: (props) => {
            if (LocalStorageService.getAccessToken()) {
                return <Redirect to={'/my-entities'} />;
            }
            return <Login {...props} />;
        },
    },
    {
        path: '/public/menu/:id',
        key: 'PUBLIC_MENU',
        component: PublicReview,
    },
    {
        path: '/public/thank-you',
        key: 'THANK_YOU',
        component: ThankYou,
    },
    {
        path: '/public/thank-you-callback',
        key: 'THANK_YOU_CALLBACK',
        component: (props) => <ThankYou confirmed={false} {...props} />,
    },
    // create a page to authorize square pos app
    {
        path: '/authorize/:vendor',
        key: 'AUTHORIZE',
        component: AuthorizeVendor,
    },
    {
        path: '/oauth/callback/:vendor',
        key: 'OAUTH_CALLBACK',
        component: AuthThankyou
    },
    {
        path: '/',
        key: 'APP',
        component: (props) => {
            if (!LocalStorageService.getAccessToken()) {
                return <Redirect to={'/login'} />;
            }
            return <Router {...props} />;
        },
        routes: [
            // {
            //   path: '/',
            //   key: 'APP_HOME',
            //   exact: true,
            //   component: Home,
            // },
            {
                path: '/view-menu/',
                key: 'MENU_DETAIL',
                exact: true,
                component: MenuDetail,
            },
            {
                path: '/export/:envId/:revId',
                key: 'EXPORT_AS',
                exact: true,
                component: ExportAs
            },
            {
                path: '/my-entities',
                key: 'MY_ENTITIES',
                exact: true,
                component: EntityList
            },
            {
                path: '/v2-entities',
                key: 'MY_ENTITIES',
                exact: true,
                component: V2List
            },
            {
                path: '/all-entities',
                key: 'ALL_ENTITIES',
                exact: true,
                component: AllEntityList,
            },
            {
                path: '/input/json/:envId?',
                key: 'INPUT_JSON',
                exact: true,
                component: InputJson
            },
            {
                path: '/input/pos/:envId?',
                key: 'INPUT_POS',
                exact: true,
                component: InputPOS
            },
            {
                path: '/input/codex/:envId?',
                key: 'INPUT_CODEX',
                exact: true,
                component: InputCodex
            },
            {
                path: '/input/agg-url/:envId?',
                key: 'INPUT_AGG_URL',
                exact: true,
                component: InputAggUrl
            },
            {
                path: '/input/cps/:envId?',
                key: 'INPUT_CPS',
                exact: true,
                component: InputCPS
            },
            {
                path: '/input/:envId?',
                key: 'INPUTS',
                exact: true,
                component: Home
            },
            {
                path: '/export/json/:envId/:revId',
                key: 'EXPORT_JSON',
                exact: true,
                component: ExportJson
            },
            {
                path: '/export/codex/:envId/:revId',
                key: 'EXPORT_CODEX',
                exact: true,
                component: ExportCodex
            },
            {
                path: '/export/review/:envId/:revId',
                key: 'EXPORT_REVIEW',
                exact: true,
                component: ExportReview
            },
            {
                path: '/about',
                key: 'ABOUT_PAGE',
                exact: true,
                component: About,
            },
            {
                path: '/entity/:id/',
                key: 'ENTITY',
                component: EntityDetail,
            },
            {
                path: '/revision/:envId/:revId',
                key: 'REVISION',
                component: MenuDetail,
            },
            {
                path: '/cps/revision/:bizId/:eisEnv/:purposeId',
                key: 'CPS_REVISION',
                component: MenuDetailCPS,
            },
            // V2
            // {
            //     path: '/v2/entity/:id/',
            //     key: 'v2entity',
            //     component: V2Entity
            // },
            // {
            //     path: '/v2/entity/:id/workflows',
            //     key: 'v2entity',
            //     component: WorkflowList
            // },
            // {
            //     path: '/v2/entity/:id/request',
            //     key: 'v2entity',
            //     component: Request
            // },
            {
                path: '/create-entity',
                key: 'create-entity',
                exact: true,
                component: CreateEntity
            }
        ],
    },
];
export default routes;
