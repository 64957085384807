import cx from 'classnames';
import React from 'react';
import {
  CheckIcon,
  ClipboardCopyIcon,
} from '@heroicons/react/outline';


export default function Uuid({ children, className }) {
  const [icon, setIcon] = React.useState('copy');

  React.useEffect(() => {
    if (icon === 'done') {
      const timeoutId = setTimeout(() => {
        setIcon('copy');
      }, 2000);

      return () => clearTimeout(timeoutId);
    }
  }, [icon, setIcon]);

  function onClick() {
    setIcon('done');
    navigator.clipboard.writeText(children);
  }

  const iconStyles = {
    width: 15,
    height: 15,
    marginBottom: 2,
    marginLeft: 5,
  };

  return (
    <span
      onClick={onClick}
      className={cx(className, 'bg-gray-100 text-gray-500 hover:text-gray-800 cursor-pointer px-2 py-1 text-xs rounded-full')}
    >
      {children}

      {icon === 'copy'
        ? <ClipboardCopyIcon style={iconStyles} className='inline' />
        : <CheckIcon className="inline text-green-500" style={iconStyles} />}
    </span>
  );
}
