import React from 'react';
import cx from 'classnames';

export default function DescriptionCard({ title, subtitle, actions, data, className }) {
  return (
    <div className={cx('bg-white shadow overflow-hidden sm:rounded-lg', className)}>
      <div className='px-4 py-5 sm:px-6 flex justify-between'>
        <div>
          <h3 className='text-md leading-6 font-medium text-gray-900'>{title}</h3>
          {subtitle && <div className='text-sm text-gray-500'>{subtitle}</div>}
        </div>
        {actions}
      </div>
      <div className='border-t border-gray-200 px-4 py-5 sm:p-0'>
        <dl className='sm:divide-y sm:divide-gray-200'>
          {data.map(datum => (
            <div className='py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6' key={datum.name}>
              <dt className='text-sm font-medium text-gray-500'>{datum.name}</dt>
              <dd className='mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2'>
                {datum.value}
              </dd>
            </div>
          ))}
        </dl>
      </div>
    </div>
  );
}
