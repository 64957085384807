import React from 'react';
import Button from '../Button';

export default function Pagination({ paginationProps, onPaginationChange }) {
  const start = paginationProps.offset + 1;
  const offsetEnd = paginationProps.offset + paginationProps.limit;
  const end = offsetEnd > paginationProps.count ? paginationProps.count : offsetEnd;

  const hasPrevious = start > 1;
  const hasNext = offsetEnd < paginationProps.count;

  function onChange(direction) {
    let newOffset = paginationProps.offset + direction * paginationProps.limit;
    if (newOffset < 0) {
      newOffset = 0;
    }

    onPaginationChange(newOffset);
  }

  return (
    <nav className="bg-red px-4 py-3 flex items-center justify-between border-t border-gray-200 sm:px-6" aria-label="Pagination">
      <div className="hidden sm:block">
        <p className="text-sm text-gray-700">
          Showing{' '}
          <span className="font-medium">{start}</span>
          {' '}to{' '}
          <span className="font-medium">{end}</span>
          {' '}of{' '}
          <span className="font-medium">{paginationProps.count}</span>
          {' '}results{' '}
        </p>
      </div>
      <div className="flex-1 flex justify-between sm:justify-end">
        {hasPrevious && <Button type="default" onClick={() => onChange(-1)}>Previous</Button>}
        {hasNext && <Button type="default" className="ml-3" onClick={() => onChange(1)}>Next</Button>}
      </div>
    </nav>
  );
}