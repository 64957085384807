import get from 'lodash/get';
import Axios from 'axios';
import { API_URL } from '../configs/api';
import Constants from '../configs/constants';
import LocalStorageService from './LocalStorage';
import GlobalNotification from '../components/GlobalNotification';
import NProgress from 'nprogress';

import 'nprogress/nprogress.css';


export const AxiosInstance = Axios.create({
  baseURL: API_URL,
  headers: {
    accept: 'application/json',
    'content-type': 'application/json',
    'Google-Token': LocalStorageService.get(Constants.STORAGE_USER_KEY, {}).tokenId
  },
});

AxiosInstance.interceptors.request.use(
  function onSucces(request) {
    NProgress.start();
    return request;
  },  function onFailure(err) {
    return Promise.reject(err);
  }
);

AxiosInstance.interceptors.response.use(
  function onSucces(response) {
    NProgress.done();
    return response;
  },
  function onFailure(error) {
    if (error.response && error.response.status === 401) {
      LocalStorageService.set(Constants.STORAGE_USER_KEY, null);
      window.location.replace('/');
      return;
    }

    const errorMessage = get(error, 'response.data.detail', 'Something went wrong');

    GlobalNotification.showError({ message: 'Request failed', description: errorMessage });

    return Promise.reject(error);
  }
);
