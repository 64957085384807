import React from 'react';
import { useRef } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import EntityApi from '../../apis/entity';
import AnalyticsService from "../../services/AnalyticsService";

const InputJson = () => {
    let { envId = '' } = useParams();
    const inputFile = useRef(null);
    const history = useHistory();

    const changeHandler = (event) => {
        var reader = new FileReader();
        reader.onload = function () {
            let jsonData = JSON.parse(reader.result);
            EntityApi.menuImport(envId, 'FILE', 'JSON', {'data': jsonData}).then(response => {
                AnalyticsService.onImport('JSON');
                history.push({
                    pathname: '/entity/' + response.id,
                    state: {
                        data: jsonData,
                    },
                });
            });

        };
        reader.readAsBinaryString(event.target.files[0]);
    };
    // const onButtonClick = () => {
    //   inputFile.current.click();
    // };

    return (
        <div className="w-full">
            <div className="mt-32 max-w-sm mx-auto bg-white shadow overflow-hidden sm:rounded-lg pb-5">
                <div className="px-4 py-5 sm:px-6">
                    <h3 className="text-md leading-6 font-medium text-gray-900">Upload JSON</h3>
                    <p className="mt-3 max-w-2xl text-sm text-gray-500">Import menu from an item first JSON file</p>
                </div>
                <div className="mx-8 my-4 border-2 border-gray-300 border-dashed rounded-md px-4 py-8  flex justify-center">
                    <div className="space-y-1 text-center">
                        <svg
                            className="mx-auto h-12 w-12 text-gray-400"
                            stroke="currentColor"
                            fill="none"
                            viewBox="0 0 48 48"
                            aria-hidden="true"
                        >
                            <path
                                d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02"
                                strokeWidth={2}
                                strokeLinecap="round"
                                strokeLinejoin="round"
                            />
                        </svg>
                        <div className="flex text-sm text-gray-600">
                            <label
                                htmlFor="file-upload"
                                className="relative cursor-pointer bg-white rounded-md font-medium text-indigo-600 hover:text-indigo-500 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-indigo-500"
                            >
                                <span>Upload a file</span>
                                <input id="file-upload" name="file-upload" accept="application/json" type="file" ref={inputFile} onChange={changeHandler} className="sr-only" />
                            </label>
                        </div>
                        <p className="text-xs text-gray-500">JSON file</p>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default InputJson;
