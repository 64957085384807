import { DownloadIcon, ArrowSmRightIcon } from '@heroicons/react/outline';
import React from 'react';
import Utils from '../../apis/utils';
import DetailCard from './DetailCard';
import Button from '../../components/Button';

export default function OutputCards({ entityId, outputs, onView }) {
  return (
    <DetailCard
      data={outputs}
      render={(output) => {
        const publicUrl = `https://mars.urbanpiper.com/public/menu/${output.output_data.public_url_id}`;
        return (
          <>
            <div className='w-0 flex-1 flex items-center'>
              <DownloadIcon
                className='flex-shrink-0 h-5 w-5 text-gray-400'
                aria-hidden='true'
              />
              <div className='flex-1 ml-3'>
                <div className='flex'>
                  <span className='text-md'>{output?.type}</span>
                  {output.type === 'LINK' ? (
                    output.params.call_requested ? (
                      <div className='ml-2 bg-red-100 px-2 rounded-lg'>
                        <span className='text-red-600 text-xs'>
                          Call requested
                        </span>
                      </div>
                    ) : output.params.is_reviewed ? (
                      <div className='ml-2 bg-green-100 px-2 rounded-lg'>
                        <span className='text-green-600 text-xs'>Reviewed</span>
                      </div>
                    ) : (
                      <div className='ml-2 bg-yellow-100 px-2 rounded-lg'>
                        <span className='text-yellow-600 text-xs'>
                          Pending review
                        </span>
                      </div>
                    )
                  ) : null}
                </div>
                {output.type === 'Public url' ? (
                  <>
                    <div className='mt-2 text-blue-600 cursor-pointer underline text-xs'>
                      <a href={publicUrl} rel='noreferrer' target='_blank'>
                        {publicUrl}
                      </a>
                    </div>
                    {output.params.form && (
                      <Button className="mt-2" onClick={() => onView(output.params.form)}>
                        View Merchant Feedback
                      </Button>
                    )}
                  </>
                ) : null}
                <div className='mt-2 text-gray-500'>
                  Exported by {output?.created_by?.name}{' '}
                  {Utils.humanize(output?.created_at)}
                </div>
              </div>
            </div>
            <div className='ml-4 flex-shrink-0'>
              <span
                className='font-medium text-indigo-600 hover:text-indigo-500 cursor-pointer'
                onClick={() => onView(output)}
              >
                View
              </span>
            </div>
          </>
        );
      }}
    />
  );
}
