import React, { useState } from 'react';
import { useQuery } from 'react-query';
import { useHistory, useParams } from 'react-router-dom';
import EntityApi from '../../apis/entity';
import Button from '../../components/Button';
import ItemInfo from '../../components/InfoCard';
import JsonModal from '../../components/JsonModal';
import ListBox from '../../components/ListBox';
import AnalyticsService from "../../services/AnalyticsService";
import SubmitModal from './SubmitModal';
import { Switch } from '@headlessui/react';
import cx from 'classnames';


const env = [
    { value: 'stage', label: 'pos-int' },
    { value: 'prod', label: 'prod' },
];

const ExportCodex = () => {
    const history = useHistory();
    const [show, setShow] = useState(false);
    const [submitShow, setSubmitShow] = useState(false);
    const [selected, setSelected] = useState(env[0]);
    const [biz, setBiz] = useState({});
    let { envId, revId } = useParams();

    const {
        isLoading: enLoading,
        isError: enError,
        data: enData,
    } = useQuery(['entity', envId], () => EntityApi.getEntity(envId));
    const {
        isLoading: reLoading,
        isError: reError,
        data: reData,
    } = useQuery(['revision', revId, {exclude: 'payload'}], EntityApi.getRevision);

    const isLoading = enLoading || reLoading;
    const isError = enError || reError;

    const [form, setFormField] = useState({
        username: '',
        password: '',
        locations: '',
        flush: false,
    });

    const inputHandler = (e) => {
        const newForm = { ...form };
        newForm[e.target.name] = e.target.value;
        setFormField(newForm);
    };
    const changeFlush = (value) => {
        const newForm = { ...form };
        newForm['flush'] = value;
        setFormField(newForm);
    };
    const getBizDetails = () => {
        let data = { ...form };
        data['environment'] = selected.value;
        EntityApi.getBizdetails(data).then((response) => {
            setBiz(response);
            setSubmitShow(true);
        });
    };
    const submit = () => {
        let data = { ...form };
        data['environment'] = selected.value;
        data['menu_revision_id'] = revId;
        data['biz_id'] = biz.id;
        EntityApi.menuExport(envId, 'INTERNAL', 'Codex', data).then((response) => {
            AnalyticsService.onExport('Codex', {
                entity_name: enData?.name,
                entity_id: envId,
                revision_id: revId,
                environment: data.environment,
                biz_id: data.biz_id,
            });

            history.push({
                pathname: '/entity/' + response.id
            });
        });
    };
    if (!isLoading && !isError) {
        return (
            <div className="w-full">
                <div className="mt-32 max-w-md mx-auto w-1/4 h-min overflow-hidden sm:rounded-lg ">
                    <ItemInfo
                        name={enData.name}
                        uid={envId}
                        onClick={() => setShow(true)}
                    />
                    <div className="bg-white shadow pb-7 mt-4">
                        <div className="px-4 py-5 sm:px-6 pb-3">
                            <h3 className="text-md leading-6 font-medium text-gray-900">Publish to Codex</h3>
                            <p className="mt-1 max-w-xs text-sm text-gray-500">Publish your latest revision to Codex</p>
                        </div>
                        <div className="px-4 sm:px-6 flex flex-col items-center">

                            <div className="mt-2 w-full">
                                <ListBox value={selected}
                                    onChange={setSelected}
                                    label="Environment"
                                    options={env}
                                    labelKey="label"
                                ></ListBox>
                                <div className="mt-4">
                                    <label className='block text-sm font-medium text-gray-700'>
                                        Username
                                    </label>
                                    <div className='mt-1'>
                                        <input
                                            className='appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm'
                                            defaultValue={form.username}
                                            name='username'
                                            onChange={inputHandler}
                                            placeholder="Username"
                                        />
                                    </div>
                                </div>

                                <div className="mt-4">
                                    <label className='block text-sm font-medium text-gray-700'>
                                        Api Key
                                    </label>
                                    <div className='mt-1'>
                                        <input
                                            className='appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm'
                                            defaultValue={form.password}
                                            name='password'
                                            type="password"
                                            onChange={inputHandler}
                                            placeholder="Key"
                                        />
                                    </div>
                                </div>
                                <div className="mt-4">
                                    <label className='block text-sm font-medium text-gray-700'>
                                        Locations
                                    </label>
                                    <div className='mt-1'>
                                        <input
                                            className='appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm'
                                            defaultValue={form.locations}
                                            name='locations'
                                            type="locations"
                                            onChange={inputHandler}
                                            placeholder="Locations"
                                        />
                                    </div>
                                </div>
                                <div className="mt-4 flex justify-evenly">
                                    <label className='block text-sm font-medium text-gray-700'>
                                        Flush
                                    </label>
                                    <Switch
                                        checked={form.flush}
                                        onChange={changeFlush}
                                        name='flush'
                                        className={cx(
                                            form.flush ? 'bg-indigo-600' : 'bg-gray-200',
                                            'relative inline-flex flex-shrink-0 h-5 w-10 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500'
                                        )}
                                    >
                                        <span className="sr-only">Use setting</span>
                                        <span
                                            aria-hidden="true"
                                            className={cx(
                                                form.flush ? 'translate-x-5' : 'translate-x-0',
                                                'pointer-events-none inline-block h-4 w-4 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200'
                                            )}
                                        />
                                    </Switch>
                                </div>
                                <div className='flex flex-row justify-center'>
                                    <Button onClick={getBizDetails} className='mt-8 w-full justify-center'>Publish</Button>
                                </div>

                            </div>

                            {/* <button
                type="button"
                className="mt-8 w-full inline-flex items-center justify-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                onClick={() => setSubmitShow(true)}
              >
                Confirm
              </button> */}
                            <SubmitModal
                                show={submitShow}
                                onClose={() => setSubmitShow(false)}
                                onSave={() => submit()}
                                data={biz}
                            />
                            <JsonModal
                                show={show}
                                onClose={() => setShow(false)}
                                data={reData.payload}
                                title={'JSON'}
                            />
                        </div>
                    </div>
                </div>
            </div>
        );
    } else {
        return (<div>Loading</div>);
    }

};

export default ExportCodex;
