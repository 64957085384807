/* This example requires Tailwind CSS v2.0+ */
import React, { Fragment, useState } from 'react';
import { Disclosure, Menu, Transition } from '@headlessui/react';
import { ChevronDownIcon, MenuIcon, XIcon } from '@heroicons/react/outline';
import { Link, useHistory } from 'react-router-dom';
import { useLocalStorage } from '../hooks/useLocalStorage';
import AnalyticsService from "../services/AnalyticsService";
import AuthService from '../services/Auth';
import cx from 'classnames';
import { PlusSmIcon } from '@heroicons/react/solid';
import Constants from '../configs/constants';
import Button from '../components/Button';

function classNames(...classes) {
    return classes.filter(Boolean).join(' ');
}

const navigation = [
    { name: 'My Workflows', href: '/my-entities' },
    { name: 'All Workflows', href: '/all-entities', admin: true },
    { name: 'V2', href: '/v2-entities', admin: true },
];

const AppLayout = ({ children }) => {
    const history = useHistory();
    const [current, setCurrent] = useState(navigation[0]);
    const [userInfo] = useLocalStorage(Constants.STORAGE_USER_KEY, {});

    const V2 = [
        { 
            name: 'Quick Request', 
            onClick: () => history.push({pathname: '/input/'})
        },
        {
            name: 'Create Entity',
            onClick: () => history.push({pathname: '/create-entity'})
        }
    ];

    const {
        profileObj: { email = '', name: userName = '', imageUrl = '' } = {},
        accessToken,
    } = userInfo || {};

    const onLogout = () => {
        AnalyticsService.onLogout();
        AuthService.logout();
        window.location.reload();
    };

    const selectNav = (item) => {
        setCurrent(item);
    };

    const userNavRoutes = navigation.filter(nav => {
        if (!accessToken) {
            return false;
        }

        if (nav.admin && userInfo.role !== 'ADMIN') {
            return false;
        }

        return true;
    });

    const userNavigation = [{ name: 'Sign out', onClick: onLogout }];
    if (window.location.href.includes('public')) {
        return (
            <main className='bg-white pt-7 pb-6 sm:p-6 flex flex-1'>{children}</main>
        );
    } else {

        return (
            <div className='bg-gray-200 h-screen flex flex-col'>
                <Disclosure as='nav' className='bg-gray-800'>
                    {({ open }) => (
                        <>
                            <div className='max-w-7xl mx-auto px-4 sm:px-6 lg:px-8'>
                                <div className='flex items-center justify-between h-16'>
                                    <div className='flex items-center'>
                                        <Link to="/">
                                            <div className='max-w-7xl mx-auto py-6 px-4 sm:px-6 lg:px-8 cursor-pointer'>
                                                <h1 className='text-3xl font-bold text-gray-50'>MARS</h1>
                                            </div>
                                        </Link>
                                        <div className='hidden md:block'>
                                            <div className='ml-10 flex items-baseline space-x-4'>
                                                {(userNavRoutes.map((item) => (
                                                    <Link
                                                        onClick={() => selectNav(item)}
                                                        key={item.name}
                                                        to={item.href}
                                                        className={classNames(
                                                            item.href === current.href
                                                                ? 'bg-gray-900 text-white'
                                                                : 'text-gray-300 hover:bg-gray-700 hover:text-white',
                                                            'px-3 py-2 rounded-md text-sm font-medium'
                                                        )}
                                                        aria-current={item.current ? 'page' : undefined}
                                                    >
                                                        {item.name}
                                                    </Link>
                                                )))}
                                            </div>
                                        </div>
                                    </div>
                                    <div className='hidden md:block'>
                                        <div className='ml-4 flex items-center md:ml-6'>
                                            {/* <button
                      type='button'
                      className='bg-gray-800 p-1 rounded-full text-gray-400 hover:text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-white'
                    >
                      <span className='sr-only'>View notifications</span>
                      <BellIcon className='h-6 w-6' aria-hidden='true' />
                    </button> */}
                                            {!!accessToken && (<div className="flex-shrink-0">
                                                <Link
                                                    className="mr-8 relative inline-flex items-center px-3 py-1 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-500 hover:bg-indigo-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-indigo-500"
                                                    to="/input"
                                                >
                                                    <PlusSmIcon className="-ml-1 mr-2 h-4 w-4" aria-hidden="true" />
                                                    <span>Import</span>
                                                </Link>

                                            </div>)}

                                            {/* {!!accessToken && userInfo.role === 'ADMIN' && (
                                                <Menu as="div" className="ml-1 relative block">
                                                    <Menu.Button className="relative inline-flex items-center px-2.5 py-1 rounded-md bg-indigo-500 text-sm font-medium text-white focus:z-10 focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500">
                                                        <span className="sr-only">Open options</span>
                                                        V2
                                                        <ChevronDownIcon className="h-4 w-4" aria-hidden="true" />
                                                    </Menu.Button>
                                                    <Transition
                                                        as={Fragment}
                                                        enter="transition ease-out duration-100"
                                                        enterFrom="transform opacity-0 scale-95"
                                                        enterTo="transform opacity-100 scale-100"
                                                        leave="transition ease-in duration-75"
                                                        leaveFrom="transform opacity-100 scale-100"
                                                        leaveTo="transform opacity-0 scale-95"
                                                    >
                                                        <Menu.Items className="z-40 origin-top-right absolute right-0 mt-2 -mr-1 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                                                            <div className="py-1">
                                                                {V2.map((item) => (
                                                                    <Menu.Item key={item.name}>
                                                                        {({ active }) => (
                                                                            <a
                                                                                onClick={() => item.onClick()}
                                                                                className={cx(
                                                                                    active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                                                                                    'block px-4 py-2 text-sm'
                                                                                )}
                                                                            >
                                                                                {item.name}
                                                                            </a>
                                                                        )}
                                                                    </Menu.Item>
                                                                ))}
                                                            </div>
                                                        </Menu.Items>
                                                    </Transition>
                                                </Menu>
                                            )} */}

                                            {!!accessToken && (
                                                <Menu as='div' className='ml-3 relative'>
                                                    <div>
                                                        <Menu.Button className='max-w-xs bg-gray-800 rounded-full flex items-center text-sm focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-white'>
                                                            <span className='sr-only'>Open user menu</span>
                                                            <img
                                                                className='h-8 w-8 rounded-full'
                                                                src={imageUrl}
                                                                alt=''
                                                            />
                                                        </Menu.Button>
                                                    </div>
                                                    <Transition
                                                        as={Fragment}
                                                        enter='transition ease-out duration-100'
                                                        enterFrom='transform opacity-0 scale-95'
                                                        enterTo='transform opacity-100 scale-100'
                                                        leave='transition ease-in duration-75'
                                                        leaveFrom='transform opacity-100 scale-100'
                                                        leaveTo='transform opacity-0 scale-95'
                                                    >
                                                        <Menu.Items className='origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none'>
                                                            {userNavigation.map((item) => (
                                                                <Menu.Item key={item.name}>
                                                                    {({ active }) => (
                                                                        <span
                                                                            onClick={item.onClick}
                                                                            className={classNames(
                                                                                active ? 'bg-gray-100' : '',
                                                                                'block px-4 py-2 text-sm text-gray-700 cursor-pointer'
                                                                            )}
                                                                        >
                                                                            {item.name}
                                                                        </span>
                                                                    )}
                                                                </Menu.Item>
                                                            ))}
                                                        </Menu.Items>
                                                    </Transition>
                                                </Menu>
                                            )}
                                        </div>
                                    </div>
                                    {!!accessToken && (
                                        <div className='-mr-2 flex md:hidden'>
                                            <Disclosure.Button className='bg-gray-800 inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-white hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-white'>
                                                <span className='sr-only'>Open main menu</span>
                                                {open ? (
                                                    <XIcon className='block h-6 w-6' aria-hidden='true' />
                                                ) : (
                                                    <MenuIcon
                                                        className='block h-6 w-6'
                                                        aria-hidden='true'
                                                    />
                                                )}
                                            </Disclosure.Button>
                                        </div>
                                    )}
                                </div>
                            </div>

                            <Disclosure.Panel className='md:hidden'>
                                {/* <div className='px-2 pt-2 pb-3 space-y-1 sm:px-3'>
                {navigation.map((item) => (
                  <a
                    key={item.name}
                    href={item.href}
                    className={classNames(
                      item.current
                        ? 'bg-gray-900 text-white'
                        : 'text-gray-300 hover:bg-gray-700 hover:text-white',
                      'block px-3 py-2 rounded-md text-base font-medium'
                    )}
                    aria-current={item.current ? 'page' : undefined}
                  >
                    {item.name}
                  </a>
                ))}
              </div> */}
                                <div className='pt-4 pb-3 border-t border-gray-700'>
                                    <div className='flex items-center px-5'>
                                        <div className='flex-shrink-0'>
                                            <img
                                                className='h-10 w-10 rounded-full'
                                                src={imageUrl}
                                                alt=''
                                            />
                                        </div>
                                        <div className='ml-3'>
                                            <div className='text-base font-medium leading-none text-white'>
                                                {userName}
                                            </div>
                                            <div className='text-sm font-medium leading-none text-gray-400'>
                                                {email}
                                            </div>
                                        </div>
                                        {/* <button
                    type='button'
                    className='ml-auto bg-gray-800 flex-shrink-0 p-1 rounded-full text-gray-400 hover:text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-white'
                  >
                    <span className='sr-only'>View notifications</span>
                    <BellIcon className='h-6 w-6' aria-hidden='true' />
                  </button> */}
                                    </div>
                                    <div className='mt-3 px-2 space-y-1'>
                                        {userNavigation.map((item) => (
                                            <span
                                                key={item.name}
                                                onClick={item.onClick}
                                                className='block px-3 py-2 rounded-md text-base font-medium text-gray-400 hover:text-white hover:bg-gray-700'
                                            >
                                                {item.name}
                                            </span>
                                        ))}
                                    </div>
                                </div>
                            </Disclosure.Panel>
                        </>
                    )}
                </Disclosure>

                {/* <header className='bg-white shadow'>
        <div className='max-w-7xl mx-auto py-6 px-4 sm:px-6 lg:px-8'>
          <h1 className='text-3xl font-bold text-gray-900'>Dashboard</h1>
        </div>
      </header> */}
                <main className='bg-gray-50 p-6 flex flex-1'>{children}</main>
                <footer className='bg-gray-100 flex justify-center p-5'>
                    <span className='text-gray-500 text-xs text-center'>
                        © 2023 UrbanPiper Technology Pvt. Ltd. All Rights Reserved.
                    </span>
                </footer>
            </div>
        );
    }
};

export default AppLayout;
