import { Dialog } from "@headlessui/react";
import { XIcon } from "@heroicons/react/outline";
import React, { Fragment, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useQuery } from "react-query";
import { useHistory } from "react-router-dom";
import Select from "react-select";
import EntityApi from "../../apis/entity";
import Button from "../../components/Button";
import ConfigSelector from "../../components/ConfigSelector";

const CreateEntity = () => {
    const history = useHistory();
    const { register, handleSubmit, reset, control, getValues, setValue } = useForm({});

    // const [formBuilder, setFormBuilder] = useState([]);

    const [bizDetail, setDetail] = useState(null);

    const { isLoading, isError, data, error } = useQuery(['supportedPOS'],
        () => EntityApi.getSupportedPOS(),
        {
            onSuccess: (data) => {
            }
        }
    );

    function onSubmit(values) {
        EntityApi.createEntity({...values, type: 'POS'}).then(response => {
            history.push({ pathname: `/entity/${response.id}/` });
        });
    }

    function validate(values) {
        EntityApi.bizValidate(values).then(response => {
            setDetail(values?.biz_id);
        });
    }

    let formPopulate = [
        {
            label: 'Biz ID',
            field: 'biz_id',
            required: true,
            placeholder: 'Enter Biz ID',
            type: 'text'
        },
        {
            label: 'Environment',
            field: 'environment',
            required: true,
            placeholder: 'Select the Environment',
            type: 'select',
            values: [
                {
                    'label': 'Staging',
                    'value': 'stage',
                },
                {
                    'label': 'Production',
                    'value': 'prod',
                }
            ]
        },
    ];
    if (!isLoading && !isError) {
        return (
            <div className="w-full">
                <form className="mx-16 grid grid-cols-5 gap-6 h-full" onSubmit={handleSubmit(onSubmit)}>
                    <div className="col-span-2 px-4 py-5 h-full bg-white shadow overflow-hidden sm:rounded-lg">
                        <div className='flex w-full justify-between'>
                            <h3 as='h3' className='text-lg text-left leading-6 font-medium text-gray-900 pb-3 mb-5'>
                                Create Entity
                            </h3>
                            <Button className="mb-auto" type="plain" onClick={() => validate(getValues())}>Validate</Button>
                        </div>
                        <div className="h-5/6 relative">
                            {formPopulate.map(el =>
                                <div className="mt-2" key={el.field}>
                                    <label className='block text-sm font-normal text-gray-500'>
                                        {el.label}
                                    </label>
                                    <div className='mt-1'>
                                        {
                                            el.type !== 'select' && (
                                                <input
                                                    type={el.type}
                                                    className='appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm'
                                                    placeholder={el.placeholder}
                                                    {...register(el.field, { required: el.required })}
                                                />
                                            )
                                        }

                                        {
                                            el.type === 'select' && el.field !== 'pos' && (
                                                <Controller
                                                    name={el.field}
                                                    control={control}
                                                    render={
                                                        ({ field }) => <Select
                                                            {...field}
                                                            isClearable={false}
                                                            className='mt-1'
                                                            options={el.values}
                                                            value={el.values.find(c => c.value === field.value)}
                                                            getOptionLabel={option => option.label}
                                                            getOptionValue={option => option.value}
                                                            onChange={(e) => field.onChange(e.value)}
                                                        >
                                                        </Select>
                                                    }
                                                >
                                                </Controller>
                                            )
                                        }

                                    </div>
                                </div>
                            )}
                            <div>
                                <div className="col-span-1 mt-4">
                                    <div>
                                        Number Of Stores : {bizDetail?.length}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {
                        bizDetail && (
                            <div className="col-span-3 px-4 py-5 bg-white shadow overflow-hidden sm:rounded-lg">
                                <div className="flex justify-between">
                                    <div>
                                        <h3 className="text-md leading-6 font-medium text-gray-900">Select Default POS</h3>
                                        <p className="mt-1 max-w-xs text-sm text-gray-500">Default config helps you in saving the configuration and using it later. You can add this later too.</p>
                                    </div>
                                    <div className='flex justify-end mb-auto'>
                                        <Button htmlType='submit' size="md">Submit</Button>
                                    </div>
                                </div>
                                <ConfigSelector
                                    configSchema={data}
                                    form={{ register, handleSubmit, reset, control, getValues, setValue }}
                                ></ConfigSelector>
                            </div>
                        )
                    }
                </form>
            </div>
        );
    } else {
        return <div>Loading...</div>;
    }
};

export default CreateEntity;