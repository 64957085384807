import cx from 'classnames';
import React, { useState } from 'react';
import { Collapse } from 'react-collapse';
import ItemFirst from '../../utils/itemFirstHelper';

const Option = ({ optionGroup, options, optionGroups, nested }) => {

    const [open, setOpen] = useState(false);

    const handleChange = (e) => {
        setOpen({ ...open, [e.target.name]: e.target.checked });
    };

    const getType = (og) => {
        if (og?.min_selectable === 1 && og?.max_selectable === 1) {
            return (
                <span
                    className='inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-blue-100 text-blue-800'>
                    Variant
                </span>
            );
        } else {
            return (
                <span
                    className='inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-green-100 text-green-800'>
                    Add On
                </span>
            );
        }
    };

    let groupOptions = options.filter(o => (o.option_group_ref_ids || []).includes(optionGroup?.ref_id));
    
    groupOptions = groupOptions.slice().sort((a, b) => a.sort_order - b.sort_order);

    return (
        <div className={cx(nested ? '' : 'border-b pb-5 mb-5')}>
            <div className='flex flex-row justify-between mb-1'>
                <div className='font-bold'>{optionGroup?.title}<span className='font-normal text-gray-600'>(min: {optionGroup?.min_selectable}, max: {optionGroup?.max_selectable})</span></div>
                <div>{getType(optionGroup)}</div>
            </div>
            {groupOptions.map(option => {
                return (
                    <div key={option._id} className='flex items-center flex-row flex-wrap mb-1'>
                        {
                            'nested_opt_grps' in option && option.nested_opt_grps.length > 0
                            &&
                            (<input
                                id={option._id}
                                name={option._id}
                                type="checkbox"
                                value={option[option._id]}
                                onChange={handleChange}
                                className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded"
                            />)
                        }
                        <div className="ml-2">{option.title}</div>
                        <div className="ml-auto">{option.price}</div>
                        <div className="flex-100">
                            {open[option._id] && 'nested_opt_grps' in option &&
                                option.nested_opt_grps.map(groupId => {
                                    return <Option
                                        key={groupId}
                                        optionGroup={ItemFirst.getObjectById(groupId, optionGroups, 'ref_id')}
                                        options={options}
                                        optionGroups={optionGroups}
                                    />;
                                })
                            }
                        </div>
                    </div>
                );
            }
            )}
        </div>
    );
};

export default Option;
