import { AxiosInstance } from '../services/axios';

const authenticateGoogleToken = (payload) => {
  return AxiosInstance.post('/api/v1/auth', payload).then(
    (response) => response.data
  );
};

const AuthApi = {
  authenticateGoogleToken,
};

export default AuthApi;
