import React from 'react';

export default function QueryStatusCard({ isLoading, isError, error }) {
  if (!isError && !isLoading) {
    return null;
  }

  return (
    <div className='w-full'>
      <div className='mt-32 max-w-sm w-full mx-auto bg-white rounded-md shadow-md p-5 flex'>
        {isLoading && (
          <div>Loading...</div>
        )}

        {isError && (
          <div>
            <div className='text-red-600 font-bold text-sm'>There was an error</div>
            {error?.message && <div className='mt-1'>{error.message}</div>}
          </div>
        )}
      </div>
    </div>
  );
}
