import React from 'react';
import { PencilIcon } from '@heroicons/react/outline';


export default function EditIcon({ onClick }) {
  return (
    <PencilIcon
      onClick={onClick}
      className="w-3 h-3 ml-1 text-gray-400 hover:text-gray-600 inline-block cursor-pointer"
    />
  );
}