import React from 'react';
import { useLocalStorage } from '../../hooks/useLocalStorage';
import GoogleLogin from 'react-google-login';
import { CLIENT_ID } from '../../configs/googleLogin';
import Constants from '../../configs/constants';

const Login = () => {
  const [, setUserInfo] = useLocalStorage(Constants.STORAGE_USER_KEY, null);

  const onLoginSuccess = (info) => {
    setUserInfo(info);
    window.location.replace('/');
  };

  const onLoginFailure = () => {
    alert('Login failed. Please try again.');
  };

  return (
    <div className='flex flex-col flex-1 justify-center items-center width-full height-full'>
      <div className='mb-5 text-center'>
        <span className='text-gray-600'>
          PLEASE LOGIN USING YOUR{' '}
          <span className='font-bold'>URBANPIPER EMAIL ID</span>
        </span>
      </div>
      <GoogleLogin
        clientId={CLIENT_ID}
        buttonText='Login with Google'
        onSuccess={onLoginSuccess}
        onFailure={onLoginFailure}
        cookiePolicy={'single_host_origin'}
      />
    </div>
  );
};

export default Login;
