import { DownloadIcon, ClockIcon, RefreshIcon, XCircleIcon, ChevronDoubleRightIcon } from '@heroicons/react/outline';
import React, { useState } from 'react';
import { useQuery } from 'react-query';
import { useHistory, useParams } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import EntityApi from '../../apis/entity';
import Button from '../../components/Button';
import QueryStatusCard from '../../components/QueryStatusCard';
import Uuid from '../../components/Uuid';
import MenuLayout from './MenuLayout';
import { useNotification } from '../../components/Notification';

function addIdentityToAllObjects({queryKey}) {
    return EntityApi.getRevision({queryKey})
        .then(response => {
            ['categories', 'items', 'options', 'option_groups'].forEach(field => {
                (response.payload[field] || []).forEach(object => {
                    object._id = uuidv4();
                });
            });
            
            return response;
        });
}
// REFACTOR THIS.
const MenuDetail = () => {
    let { envId, revId } = useParams();

    const [activeCategory, setActiveCategory] = useState(null);
    const [payload, setPayload] = useState({
        categories: [],
        items: []
    });

    const notification = useNotification();


    const history = useHistory();
    const [edit, setEdit] = useState(false);
    const entityQuery = useQuery(['entity', envId], () => EntityApi.getEntity(envId), {
        enabled: true
    });
    const revisionQuery = useQuery(['revision', revId, {}], addIdentityToAllObjects);
    
    const setStateProperty = (key, items) => {
        return setPayload((prev) => ({
            ...prev,
            key: items
        }));
    };

    const saveRevision = () => {
        ['categories', 'items', 'options', 'option_groups'].forEach(field => {
            (payload[field] || []).forEach(object => {
                delete object._id;
            });
        });
        EntityApi.createRevision(envId, { payload: payload, using_cache: revision?.using_cache, revision_id: revId}).then(response => {
            entityQuery.refetch();
            setEdit(false);
            history.push({ pathname: `/revision/${envId}/${response.id}` });
        });
    };

    React.useEffect(() => {
        if (!revisionQuery.data) {
            return;
        }
        if ('success' == revisionQuery.data.meta.status && !('error' in revisionQuery.data.payload)) {
            setPayload(revisionQuery.data.payload);
            setActiveCategory(revisionQuery.data.payload?.categories[0]?.ref_id);
            if (revisionQuery.data.using_cache) {
                notification.open({title:'Warning', message:'This menu is large. Using experimental feature. Please verify data after save is completed', type:'warning'});
            }
            
        }
    }, [revisionQuery.data]);

    if (entityQuery.isLoading || entityQuery.isError) {
        return <QueryStatusCard
            isLoading={entityQuery.isLoading}
            isError={entityQuery.isError}
            error={entityQuery.error}
        />;
    }

    if (revisionQuery.isLoading || revisionQuery.isError) {
        return <QueryStatusCard
            isLoading={revisionQuery.isLoading}
            isError={revisionQuery.isError}
            error={revisionQuery.error}
        />;
    }
    if (revisionQuery.isSuccess && 'error' in revisionQuery.data.payload) {
        return <QueryStatusCard
            isLoading={false}
            isError={true}
            error={{message: revisionQuery.data.payload.error}}
        />;
    }

    if (revisionQuery.isSuccess && 'error' in revisionQuery.data.payload) {
        return <QueryStatusCard
            isLoading={false}
            isError={true}
            error={{message: revisionQuery.data.payload.error}}
        />;
    }

    const entity = entityQuery.data;
    const revision = revisionQuery.data;
    const status = revision.meta.status == 'success' ? true : false;
    const revisionNumber = entity.revision.find(r => r.menu_revision_id === revision.id)?.number;
    const isLatestRevision = entity.revision[entity.revision.length - 1].menu_revision_id === revision.id;

    return (
        <div className='w-full max-w-7xl mx-auto shadow-md bg-white p-5'>
            <div className='border-b pb-5'>
                <div className='px-4 py-2 flex justify-between'>
                    <div>
                        <h3 className='text-md leading-6 font-bold text-gray-900'>
                            Revision {revisionNumber}
                            {isLatestRevision ? ' (Latest)' : ' (Older Revision)'}
                        </h3>
                        <div className='text-sm text-gray-500'>From Entity {entity.name} <Uuid>{entity.id}</Uuid></div>
                    </div>
                    <div>
                        {isLatestRevision && (
                            <Button
                                className='mx-3'
                                onClick={() => {
                                    history.push({ pathname: `/export/${envId}/${revId}`, state: { data: entity } });
                                }}
                                Icon={DownloadIcon}
                                type='white'
                            >
                                Export
                            </Button>
                        )}
                        {isLatestRevision && edit && (
                            <Button type='primary'
                                onClick={saveRevision}
                            >
                                Save
                            </Button>
                        )}
                        {isLatestRevision && !edit && (
                            <Button type=''
                                onClick={() => setEdit(true)}
                            >
                                Edit
                            </Button>
                        )}

                    </div>

                </div>
            </div>
            {!status && <div className="flex justify-center px-10 py-16 h-4/5 items-center">
                {
                    revision.meta.status == 'inqueue' &&
                    <div className="flex flex-col items-center">
                        <span className="bg-yellow-100 p-5 rounded-md w-min">
                            <ClockIcon className="h-8 w-8 text-yellow-500" />
                        </span>
                        <span className="pt-2 text-yellow-500 uppercase text-sm">
                            In Queue
                        </span>
                    </div>
                }
                {
                    revision.meta.status == 'inprogress' &&
                    <div className="flex flex-col items-center">
                        <span className="bg-green-100 p-5 rounded-md w-min">
                            <ChevronDoubleRightIcon className="h-8 w-8 text-green-500" />
                        </span>
                        <span className="pt-2 uppercase text-sm text-green-500">
                            In Progress
                        </span>
                    </div>
                }
                {
                    revision.meta.status == 'failed' &&
                    <div className="flex flex-col items-center">
                        <span className="bg-red-100 p-5 rounded-md w-min">
                            <XCircleIcon className="h-8 w-8 text-red-500" />
                        </span>
                        <span className="pt-2 uppercase text-sm text-red-500">
                            Failed
                        </span>
                        <span className="text-sm mt-5 text-gray-600">
                            {JSON.stringify(revision.payload, null, 2)}
                        </span>
                    </div>
                }
                {
                    revision.meta.status == 'error' &&
                    <div className="flex flex-col items-center">
                        <span className="bg-red-100 p-5 rounded-md w-min">
                            <XCircleIcon className="h-8 w-8 text-red-500" />
                        </span>
                        <span className="pt-2 uppercase text-sm text-red-500">
                            Error
                        </span>
                        <span className="text-sm mt-5 text-gray-600">
                            {JSON.stringify(revision.payload, null, 2)}
                        </span>
                    </div>
                }
                {
                    revision.meta.status == 'retrying' &&
                    <div className="flex flex-col items-center">
                        <span className="bg-orange-100 p-5 rounded-md w-min">
                            <RefreshIcon className="h-8 w-8 text-orange-500" />
                        </span>
                        <span className="pt-2 uppercase text-sm text-orange-500">
                            Retrying
                        </span>
                        <span className="text-sm mt-5 text-gray-600">
                            {JSON.stringify(revision.meta.error, null, 2)}
                        </span>
                    </div>
                }
            </div>
            }

            {status &&
                <MenuLayout
                    payload={payload}
                    setPayload={setPayload}
                    activeCategory={activeCategory}
                    setActiveCategory={setActiveCategory}
                    editable={edit}
                    usingCache={revision.using_cache}
                >

                </MenuLayout>}
        </div>
    );
};

export default MenuDetail;
