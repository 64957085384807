import React from 'react';
import { QueryClient, QueryClientProvider } from 'react-query';
import { BrowserRouter } from 'react-router-dom';
import { ReactQueryDevtools } from 'react-query/devtools';
import routes from './configs/routes';
import AppLayout from './layout';
import Router from './router';
import Authenticate from './components/wrappers/Authenticate';
import GlobalNotification from './components/GlobalNotification';
import './assets/css/custom.scss';


const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    }
  }
});

const App = () => {
  return (
    <QueryClientProvider client={queryClient}>
      <Authenticate>
        <BrowserRouter>
          <AppLayout>
            <Router routes={routes} />
          </AppLayout>
        </BrowserRouter>
      </Authenticate>
      <ReactQueryDevtools initialIsOpen={false} />
      <GlobalNotification />
    </QueryClientProvider>
  );
};

export default App;
