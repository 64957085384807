import React from 'react';
import { CheckCircleIcon, ExclamationCircleIcon, XCircleIcon, ExclamationIcon, XIcon } from '@heroicons/react/outline';
import { Fragment } from 'react';
import { Transition } from '@headlessui/react';
import useTimeout from '../../hooks/useTimeout';
// Component for Notification using headlessui

export default function Notification({ show, onClose, title, message, type }) {
    useTimeout(onClose, 5000);

    // type logic for notification component
    const typeLogic = (type) => {
        switch (type) {
        case 'success':
            return 'text-green-800';
        case 'danger':
            return 'text-red-800';
        case 'warning':
            return 'text-yellow-800';
        case 'info':
            return 'text-grey-800';
        default:
            return 'text-indigo-800';
        }
    };

    // icon logic for notification component using heroicons
    const getIcon = ({type}) => {
        switch (type) {
        case 'success':
            return CheckCircleIcon;
        case 'danger':
            return XCircleIcon;
        case 'warning':
            return ExclamationCircleIcon;
        case 'info':
            return ExclamationIcon;
        default:
            return ExclamationIcon;
        }
    };
    // function to select icon
    const Icon = getIcon({type});

    // color logic for icons
    const colorIcons = (type) => {
        switch (type) {
        case 'success':
            return 'text-green-400';
        case 'danger':
            return 'text-red-400';
        case 'warning':
            return 'text-yellow-400';
        case 'info':
            return 'text-grey-400';
        default:
            return 'text-indigo-400';
        }
    };

    return (

        <div
            aria-live="assertive"
            className="pointer-events-none fixed inset-0 flex items-end px-4 py-6 sm:items-start sm:p-6"
        >
            <div className="flex w-full flex-col items-center space-y-4 sm:items-end">
                <Transition
                    show={show}
                    as={Fragment}
                    enter="transform ease-out duration-300 transition"
                    enterFrom="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
                    enterTo="translate-y-0 opacity-100 sm:translate-x-0"
                    leave="transition ease-in duration-100"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div className={`max-w-sm w-full bg-white shadow-lg rounded-lg pointer-events-auto ring-1 ring-black ring-opacity-5 overflow-hidden ${typeLogic(type)}`}>
                        <div className="p-4">
                            <div className="flex items-start">
                                <div className="flex-shrink-0">
                                    <Icon className={`h-6 w-6 ${colorIcons(type)}`} aria-hidden="true" />
                                </div>
                                <div className="ml-3 w-0 flex-1 pt-0.5">
                                    <p className="text-sm font-medium text-gray-900">
                                        {title}
                                    </p>
                                    <p className="mt-1 text-sm text-gray-500">
                                        {message}
                                    </p>
                                </div>
                                <div className="ml-4 flex-shrink-0 flex">
                                    <button onClick={onClose} className="rounded-md inline-flex text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                                        <span className="sr-only">Close</span>
                                        <XIcon className="h-5 w-5" aria-hidden="true" />
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </Transition>
            </div>
        </div>
    );

};
