import React, { Fragment } from "react";
import { PencilIcon, PlusSmIcon, TrashIcon, XIcon } from '@heroicons/react/outline';
import FoodType from "../../components/FoodType";
import Button from '../../components/Button';
import LocationGrid from '../../pages/MenuDetail/LocationGrid';
import Badge from "../../components/Badge";
import DropDown from "../../components/DropDown";

export default function ItemCard (props) {
    const index = props?.index;
    const element = props.data?.items[index];
    const editable = props.data?.edit;
    const actions = props.data?.actions;
    return (
        <Fragment key={element._id}>
            <div className='h-52 py-4 px-1 border-b relative flex flex-col content-start' style={props.style}>
                <div className='w-full pb-2 flex justify-between flex-1'>
                    <div className='flex'>
                        <div className='h-20 w-20'>
                            {element.img_url && (
                                <div className='rounded-md shadow-md overflow-hidden h-20 w-20'>
                                    <img src={element.img_url} className='bg-gray-100 object-cover w-full h-full' />
                                </div>
                            )}
                        </div>
                        <div className='ml-2'>
                            <div className='flex items-baseline'>
                                <div className='text-gray-600 font-medium'>
                                    {element.title}
                                </div>
                                <div className='ml-2'>
                                    <FoodType foodType={element.food_type} />
                                </div>
                            </div>
                            <div className='text-gray-600 text-sm pb-2 font-medium'>
                                Rs. {element.price}
                            </div>
                            <div className='text-xs max-h-10 overflow-hidden break-words text-gray-600'>
                                {element.description}
                            </div>
                        </div>
                    </div>
                    {
                        editable && (
                            <div className='flex'>
                                <Button
                                    size={'xs'}
                                    type='info'
                                    onClick={() => {
                                        actions.delete(element, 'items');
                                    }}
                                    Icon={TrashIcon}
                                    className='px-1 py-1 mr-1 fit-content w-min'
                                >
                                </Button>
                                <Button
                                    size={'xs'}
                                    type='info'
                                    onClick={() =>
                                        actions.edit(element, 'items')
                                    }   
                                    className='px-1 py-1 fit-content w-min'
                                    Icon={PencilIcon}
                                ></Button>
                            </div>
                        )
                    }

                </div>
                <div className='w-full flex justify-between items-end'>
                    {element?._meta && <Badge size="sm">
                        Locations - { element?._meta?.location_count }
                    </Badge>}
                    {/* {element.locations?.length > 0 && <LocationGrid
                                        locations={element.locations}
                                    />} */}
                    {(element.option_group_ref_ids || []).length > 0 && (
                        <Button
                            className='mt-2 ml-auto'
                            onClick={() => actions.openOptions(element)}
                            type='white'
                            Icon={PlusSmIcon}
                        >
                            Customise
                        </Button>
                    )}
                </div>

            </div>
        </Fragment>
    );
}