import cx from 'classnames';
import React, { useState, Fragment } from 'react';
import { DotsVerticalIcon } from '@heroicons/react/solid';
import { Dialog, Menu, Transition} from '@headlessui/react';
import { useForm } from "react-hook-form";


import CategoryDrawer from './CategoryDrawer';
import DeleteModal from './DeleteModal';

export default function Categories({ payload, activeCategory, setActiveCategory, setPayload, editable }) {
    // get all parent categories
    const [showModal, setShowModal] = useState(false);

    const defaultCategory = {
        ref_id: '',
        name: '',
        sort_order: '',
    };

    const form = useForm({
    });

    const [showDrawer, setShowDrawer] = useState(false);

    const parentCategories = payload?.categories.filter(c => !c.parent_ref_id) || [];

    const deleteCategory = (item) => {
        let id = item.ref_id;
        const childCategories = payload?.categories.filter(element => element.parent_ref_id === id).map(element => element.ref_id);
        let allCategories = [...childCategories];
        allCategories.push(id);
        let categories = JSON.parse(JSON.stringify(payload?.categories));
        categories = categories.filter(element => !allCategories.includes(element.ref_id));
        // let items = JSON.parse(JSON.stringify(payload.items));
        let items = payload?.items || [].map(element => Object.assign({}, element));
        let options = payload?.options || [].map(element => Object.assign({}, element));
        let optionGroups = payload?.option_groups || [].map(element => Object.assign({}, element));
        let itemsToBeDeleted = [];
        let optGrpToBeChecked = [];
        items.forEach((element) => {
            allCategories.map(cat => {
                let position = element.category_ref_ids.indexOf(cat);
                if (position > -1) {
                    element.category_ref_ids = [...element.category_ref_ids.slice(0, position), ...element.category_ref_ids.slice(position + 1, element.category_ref_ids.length)];
                }
            });
            optGrpToBeChecked.push(...element.option_group_ref_ids);
            if (element.category_ref_ids.length <= 0) {
                itemsToBeDeleted.push(element.ref_id);
            }
        });
        items = items.filter(element => !itemsToBeDeleted.includes(element.ref_id));
        // check if option in any other item then cant delete
        // check the same if it exists in any nested_opt_grps cant be deleted
        // delete option_group from options array 
        // if the option_group array is empty delete option
        optGrpToBeChecked = optGrpToBeChecked.map(element => {
            let flag = false;
            items.some(item => {
                if ((item?.option_group_ref_ids || []).includes(element)) {
                    flag = true;
                    return true;
                }
            });
            options.some(item => {
                if ((item?.nested_opt_grps || []).includes(element)) {
                    flag = true;
                    return true;
                }
            });
            if (flag) return null;
            else return element;
        });
        optGrpToBeChecked = new Set(optGrpToBeChecked);
        optGrpToBeChecked = [...optGrpToBeChecked];
        let optionsToBeDeleted = [];
        options.forEach(option => {
            optGrpToBeChecked.map(element => {
                let position = option.option_group_ref_ids.indexOf(element);
                if (position > -1) {
                    option.option_group_ref_ids = [...option.option_group_ref_ids.slice(0, position), ...option.option_group_ref_ids.slice(position + 1, option.option_group_ref_ids.length)];
                }
            });
            if (option.option_group_ref_ids.length <= 0) {
                optionsToBeDeleted.push(option.ref_id);
            }
        });
        options = options.filter(element => !optionsToBeDeleted.includes(element.ref_id));
        optionGroups = optionGroups.filter(element => !optGrpToBeChecked.includes(element.ref_id));
        setPayload((prev) => ({
            ...prev,
            'items': items,
            'categories': categories,
            'options': options,
            'option_groups': optionGroups
        }));
    };

    const setCategories = (categories) => {
        console.log(payload.categories.length);
        console.log(categories.length);
        return setPayload(prev => ({
            ...prev,
            'categories': categories
        }));
    };

    const updateCategory = (category) => {
        let newCategories = payload.categories.filter(el => el.ref_id != category.ref_id);
        newCategories = [...newCategories, category];
        setCategories(newCategories);
    };

    const initFormOnCategorySelect = (category) => {
        form.reset(category);
        setActiveCategory(category.ref_id);
    };

    const openDrawer = () => {
        setShowDrawer(true);
    };

    const openModal = () => {
        setShowModal(true);
    };
    
    const deleteConfirm = (ref_id) =>{
        deleteCategory(ref_id);
        setShowModal(false);
    }

    const onSubmit = (e) => {
        updateCategory(e);
        setShowDrawer(false);
        form.reset(defaultCategory);
    };
    // get all child categories
    parentCategories.map(c => {
        c.child_categories = payload?.categories.filter(cInner => cInner.parent_ref_id === c.ref_id);
        return c;
    });

    return (
        <div>
            {parentCategories.map(element => (
                <div key={element._id}>
                    <Category
                        category={element}
                        setCategory={initFormOnCategorySelect}
                        selectedId={activeCategory}
                        editable={editable}
                        showDrawer={openDrawer}
                        showModal={openModal}
                    />
                    {(element.child_categories || []).map(c => (
                        <div key={c._id} className="pl-8">
                            <Category
                                category={c}
                                setCategory={initFormOnCategorySelect}
                                selectedId={activeCategory}
                                editable={editable}
                                showDrawer={openDrawer}
                                showModal={openModal}
                            />
                        </div>
                    ))}
                </div>
            ))}
            <CategoryDrawer
                item={activeCategory}
                show={showDrawer}
                form={form}
                onSubmit={onSubmit}
                onClose={() => setShowDrawer(false)}
            ></CategoryDrawer>
            <DeleteModal
                item={form.getValues()}
                show={showModal}
                onClose={() => setShowModal(false)}
                onSubmit={deleteConfirm}
                dataKey='name'
            >
            </DeleteModal>
        </div>
    );
}

function Category({ category, setCategory, selectedId, editable, showDrawer, showModal }) {

    return (
        <div
            key={category._id}
            className={cx(
                category.ref_id === selectedId
                    ? 'bg-indigo-50 border-indigo-600 text-indigo-600'
                    : 'border-transparent text-gray-600 hover:bg-gray-50 hover:text-gray-900',
                'cursor-pointer group flex hover-on-display justify-between items-center px-3 py-2 text-sm font-medium border-r-4',
            )}
            onClick={() => setCategory(category)}
        >
            {category.name}
            {
                editable && (
                    // <span className="hidden w-4 h-4" onClick={showDrawer}>
                    //     <TrashIcon className="w-4 h-4 hover:text-red-800" />
                    // </span>
                    <Menu as="div" className="relative inline-block text-left">
                        <div className='z-0'>
                            <Menu.Button className="z-0 rounded-full flex items-center text-gray-400 hover:text-gray-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-indigo-500">
                                <span className="sr-only">Open options</span>
                                <DotsVerticalIcon className="h-4 w-4" aria-hidden="true" />
                            </Menu.Button>
                        </div>

                        <Transition
                            as={Fragment}
                            enter="transition ease-out duration-100"
                            enterFrom="transform opacity-0 scale-95"
                            enterTo="transform opacity-100 scale-100"
                            leave="transition ease-in duration-75"
                            leaveFrom="transform opacity-100 scale-100"
                            leaveTo="transform opacity-0 scale-95"
                        >
                            <Menu.Items className="z-10 origin-top-right absolute right-0 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                                <div className="py-1">
                                    <Menu.Item>
                                        {({ active }) => (
                                            <a  
                                                onClick={showDrawer}
                                                href="#"
                                                className={cx(
                                                    active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                                                    'block px-4 py-2 text-sm'
                                                )}
                                            >
                                                Edit
                                            </a>
                                        )}
                                    </Menu.Item>
                                    <Menu.Item>
                                        {({ active }) => (
                                            <a
                                                onClick={showModal}
                                                href="#"
                                                className={cx(
                                                    active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                                                    'block px-4 py-2 text-sm'
                                                )}
                                            >
                                                Delete
                                            </a>
                                        )}
                                    </Menu.Item>
                                </div>
                            </Menu.Items>
                        </Transition>
                    </Menu>
                )
            }
        </div>
    );
}
