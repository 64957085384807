import cx from 'classnames';
import React from 'react';
import { Switch } from '@headlessui/react';

export default function Indicator({ className, size, status}) {

    // 
    const sizeClass = size === 'xs' ? 'w-2 h-2' : size === 'sm' ? 'w-3 h-3' : size === 'md' ? 'w-4 h-4' : size === 'lg' ? 'w-5 h-5' : size === 'xl' ? 'w-6 h-6' : 'w-4 h-4';

    // 
    const statusClass = status === 'success' ? 'bg-green-500' : status === 'inqueue' ? 'bg-yellow-500' : status === 'failed' ? 'bg-red-500' : 'bg-gray-500';

    const classString = cx(
        sizeClass,
        statusClass,
        'rounded-full',
        'border',
        'border-black'
    );

    return (
        <div className={cx(classString, className)}>
        </div>
    );
}
