import { PlusSmIcon } from '@heroicons/react/outline';
import React from 'react';
import { useForm } from 'react-hook-form';
import Button from '../../components/Button';
import OptionModal from '../MenuDetail/OptionModal';
import EditIcon from './EditIcon';


export default function ItemCardsMob({ payload, activeCategory, onEdit }) {
  const [showOptions, setShowOptions] = React.useState(false);
  const form = useForm({
});

  const filtered = payload.items.filter(element => {
    return (element.category_ref_ids || []).filter(id => activeCategory === id).length > 0;
  });

  function onOptions(selectedItem) {
    form.reset(selectedItem);
    setShowOptions(true);
  }

  return (
    <div>
      {filtered.map(element => (
        <div key={element._id} className="menu-item-list px-6 pb-2 border-b w-full">
          <div className='flex justify-between items-center'>
            <div className='rounded-md mr-3'>
              {element.img_url && (
                <img src={element.img_url} style={{
                  minWidth: '88px', minHeight: '88px',
                  maxWidth: '88px', maxHeight: '88px',
                }} className='bg-gray-100 object-cover rounded-md shadow-md' />
              )}
              {!element.img_url && (
                <div className='bg-gray-200 object-cover py-11 px-11 rounded-md shadow-md' />
              )}

            </div>

            <div>
              <div className='text-base pt-2 font-semibold'>
                {element.title} <EditIcon onClick={() => onEdit(element)} />
              </div>
              <div className='text-xs pb-2 overflow-ellipsis h-10 overflow-hidden text-gray-600'>
                {element.description}
              </div>
              <div className='text-md py-2 font-semibold flex flex-row justify-between items-center'>
                Rs. {element.price} {(element.option_group_ref_ids || []).length > 0 && (
                  <Button
                    className='ml-5 px-0 py-0'
                    onClick={() => onOptions(element)}
                    type='white'
                    Icon={PlusSmIcon}
                  >
                    Customise
                  </Button>
                )}
              </div>

            </div>
          </div>
        </div>
      ))}
      <OptionModal
        show={showOptions}
        payload={payload}
        form={form}
        onClose={() => setShowOptions(false)}
      />
    </div>
  );
}
