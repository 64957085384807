import get from 'lodash/get';
import React from 'react';
import Button from '../../components/Button';


export default function FormStep({meta, taxes, charges, onRequestCall, onConfirm, formFields, onFormChange, callRequested, confirmed }) {
    return (
        <div>
            <section className="w-full flex flex-col items-center pb-4 relative">
                <div className="text-3xl mt-6 sm:font-semibold">
                    Enter your <span className="text-primary">details</span>
                </div>
                {   meta.task_name === 'Swiggy' && (
                    <div className="mt-3 text-gray-500 text-center">
                    Fill in taxes and charges applied on your restaurant
                    </div>
                )
                }
            </section>

            <div className="flex justify-center mt-12 px-8">
                <section className="max-w-2xl w-full pb-20">
                    {meta?.task_name === 'Swiggy' && taxes.map(t => (
                        <div className="flex flex-row justify-between mb-5" key={t.name}>
                            <div className="flex-1">
                                <label className="text-sm text-gray-700 font-medium">Tax Name</label>
                                <input
                                    className='appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm'
                                    value={t.name}
                                    disabled
                                />
                            </div>
                            <div className="p-2" />
                            <div className="flex-1">
                                <label className="text-sm text-gray-700 font-medium">Tax Percentage</label>
                                <div className="flex">
                                    <input
                                        className='appearance-none block w-full px-3 py-2 border border-r-0 border-gray-300 rounded-l-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm'
                                        value={get(formFields, `taxes.${t.name}`)}
                                        onChange={e => onFormChange(`taxes.${t.name}`, e.target.value)}
                                        disabled={confirmed}
                                    />
                                    <span className="inline-flex items-center px-3 shadow-sm rounded-r-md border border border-gray-300 bg-gray-50 text-gray-500 sm:text-sm">
                                        %
                                    </span>
                                </div>
                            </div>
                        </div>
                    ))}
                    <div className="flex flex-row justify-between mb-5">
                        <div className="flex-1">
                            <label className="text-sm text-gray-700 font-medium">Contact Number</label>
                            <input
                                className='appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm'
                                value={get(formFields, 'contact_number')}
                                onChange={e => onFormChange('contact_number', e.target.value)}
                            />
                        </div>
                    </div>
                    {meta?.task_name === 'Swiggy' && charges.map(t => (
                        <div className="flex flex-row justify-between mb-5" key={t.name}>
                            <div className="flex-1">
                                <label className="text-sm text-gray-700 font-medium">Charge Name</label>
                                <input
                                    className='appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm'
                                    value={t.name}
                                    disabled
                                />
                            </div>
                            <div className="p-2" />
                            <div className="flex-1">
                                <label className="text-sm text-gray-700 font-medium">Charge Value</label>
                                <div className="flex">
                                    <input
                                        className='appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm'
                                        value={get(formFields, `taxes.${t.name}`)}
                                        onChange={e => onFormChange(`taxes.${t.name}`, e.target.value)}
                                        disabled={confirmed}
                                    />
                                </div>
                            </div>
                        </div>
                    ))}

                    <div>
                        <div className="mt-4 w-full">
                            <label className="text-sm text-gray-700 font-medium">Additional Comments</label>
                            <textarea
                                rows={4}
                                className="shadow-sm block w-full focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm border border-gray-300 rounded-md px-3 py-2 px-2"
                                value={formFields['comments'] || ''}
                                onChange={e => onFormChange('comments', e.target.value)}
                                disabled={confirmed}
                            />
                        </div>

                        {confirmed ? (
                            <div className="mt-8 text-center text-green-600 font-semibold">
                                You have already confirmed your menu!
                            </div>
                        ) : (
                            <div>
                                <div className="mt-8 w-full flex justify-between fixed left-0 bottom-0 px-8 sm:px-0 sm:relative bg-white py-3 sm:py-0 border-t sm:border-0">
                                    {!callRequested && (
                                        <Button
                                            onClick={onRequestCall}
                                            className="outline-button py-3 sm:px-7 sm:py-3 w-full uppercase justify-center mr-4">
                                            Request a call
                                        </Button>
                                    )}
                                    <Button
                                        onClick={onConfirm}
                                        className="bg-primary py-3 sm:px-7 sm:py-3 w-full uppercase justify-center">
                                        Confirm
                                    </Button>
                                </div>

                                {callRequested && (
                                    <div className="text-sm mt-3 text-center text-gray-600">
                                        We have received your callback request. Our team will be in contact shortly
                                    </div>
                                )}
                            </div>
                        )}
                    </div>
                </section>
            </div>
        </div>
    );
}