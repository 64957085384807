export const squareConfig = {
    'stage': {
        url: 'https://connect.squareupsandbox.com/oauth2/authorize', 
        clientId: 'sandbox-sq0idb-LxWdbb3tZkVFuMpxaONgDQ',
    },
    'prod': {
        url: 'https://connect.squareup.com/oauth2/authorize',
        clientId: 'sq0idp-tl2cBpRC2cg9RYLmHVvGvg',
    },
    'scope': [
        'APPOINTMENTS_ALL_READ',
        'APPOINTMENTS_ALL_WRITE',
        'APPOINTMENTS_BUSINESS_SETTINGS_READ',
        'APPOINTMENTS_READ',
        'APPOINTMENTS_WRITE',
        'BANK_ACCOUNTS_READ',
        'CASH_DRAWER_READ',
        'CUSTOMERS_READ',
        'CUSTOMERS_WRITE',
        'DEVICE_CREDENTIAL_MANAGEMENT',
        'DISPUTES_READ',
        'DISPUTES_WRITE',
        'EMPLOYEES_READ',
        'EMPLOYEES_WRITE',
        'GIFTCARDS_READ',
        'GIFTCARDS_WRITE',
        'INVENTORY_READ',
        'INVENTORY_WRITE',
        'INVOICES_READ',
        'INVOICES_WRITE',
        'ITEMS_READ',
        'ITEMS_WRITE',
        'LOYALTY_READ',
        'LOYALTY_WRITE',
        'MERCHANT_PROFILE_READ',
        'MERCHANT_PROFILE_WRITE',
        'ONLINE_STORE_SITE_READ',
        'ONLINE_STORE_SNIPPETS_READ',
        'ONLINE_STORE_SNIPPETS_WRITE',
        'ORDERS_READ',
        'ORDERS_WRITE',
        'PAYMENTS_READ',
        'PAYMENTS_WRITE',
        'PAYMENTS_WRITE_ADDITIONAL_RECIPIENTS',
        'PAYMENTS_WRITE_IN_PERSON',
        'PAYMENTS_WRITE_SHARED_ONFILE',
        'PAYOUTS_READ',
        'RESERVATIONS_READ',
        'RESERVATIONS_WRITE',
        'SETTLEMENTS_READ',
        'SUBSCRIPTIONS_READ',
        'SUBSCRIPTIONS_WRITE',
        'TIMECARDS_READ',
        'TIMECARDS_SETTINGS_READ',
        'TIMECARDS_SETTINGS_WRITE',
        'TIMECARDS_WRITE',
        'VENDOR_READ',
        'VENDOR_WRITE'
    ]
};

