import React from 'react';
import EntityApi from '../../apis/entity';
import EntityListComponent from '../../components/EntityListComponent';

const V2List = () => {
    return (
        <EntityListComponent
            requestName="entities"
            api={EntityApi.listEntities}
            title="My Workflows"
            subtitle="You can view all your workflows here"
            type='PERMANENT'
        />
    );
};

export default V2List;
