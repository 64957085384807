import { Dialog } from '@headlessui/react';
import React from 'react';
import Modal from '../../components/Modal';
import Option from '../../components/Option';
import Button from '../../components/Button';

export default function DeleteModal({ item, show, onClose, onSubmit}) {
    const confirm = (value) => {
        onSubmit(value);
    };
    return (
        <Modal show={show} onClose={onClose} maxWidth={'max-w-lg'}>
            <div className='p-8 max-w-lg'>
                <Dialog.Title as='h3' className='text-lg text-left leading-6 font-medium text-gray-900 border-b pb-5 mb-5'>
                    Delete Item
                </Dialog.Title>
                <div className='mt-2'>
                    Are you sure you want to delete {item?.ref_id} - {item?.title} {item?.name}?
                </div>
                <div className='mt-5 flex justify-end'>
                    <Button
                        onClick={() => confirm(item)}
                        className='mr-3'
                        type='primary'
                    >
                        Yes
                    </Button>
                    <Button
                        type=''
                        onClick={onClose}
                    >
                        No
                    </Button>
                </div>
            </div>
        </Modal>
    );
}
