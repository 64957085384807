import React from 'react';
import EntityApi from '../../apis/entity';
import EntityListComponent from '../../components/EntityListComponent';

const AllEntityList = () => {
  return (
    <EntityListComponent
      requestName="all-entities"
      api={EntityApi.allEntities}
      title="All Workflows"
      subtitle="You can view workflows from all users here"
    />
  );
};

export default AllEntityList;
