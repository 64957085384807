import React, { useState, useEffect } from "react";
import Button from "../Button";
import { TrashIcon } from "@heroicons/react/outline";

const KeyValueInput = ({ defaultValue = [], onChange }) => {
    // initialize state with default value if provided else array with 1 element
    const [pairs, setPairs] = useState(defaultValue.length ? defaultValue : []);

    // update parent state when pairs change
    useEffect(() => {
        onChange(pairs);
    }, [pairs]);

    const setState = (pairs) => {
        setPairs(pairs);
        onChange(pairs);
    }

    const handleInputChange = (e, index) => {
        const { name, value } = e.target;
        const newPairs = [...pairs];
        newPairs[index][name] = value; // update only the specific pair
        setState(newPairs);
    };

    const handleAddPair = () => {
        setState([...pairs, { key: "", value: "" }]); // append a new pair
    };

    const handleDeletePair = (index) => {
        const newPairs = [...pairs];
        newPairs.splice(index, 1); // remove the specific pair
        setState(newPairs);
    };

    return (
        <div className="space-y-2">
            {pairs.map((pair, index) => (
                <div key={index} className="flex">
                    <input
                        type="text"
                        name="key"
                        value={pair.key}
                        onChange={(e) => handleInputChange(e, index)}
                        className="mt-1 mr-1 appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                        placeholder="Key"
                    />
                    <input
                        type="text"
                        name="value"
                        value={pair.value}
                        onChange={(e) => handleInputChange(e, index)}
                        className="mt-1 mr-1 appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                        placeholder="Value"
                    />
                    <Button
                        onClick={() => handleDeletePair(index)}
                        type="error"
                        size="xs"
                    >
                        <TrashIcon className="w-4 h-4" />
                    </Button>
                </div>
            ))}
            <Button
                onClick={handleAddPair}
                className="w-full"
            >
                Add
            </Button>
        </div>
    );
};

export default KeyValueInput;