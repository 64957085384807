import React, { useState, Fragment } from 'react';
import { useForm } from "react-hook-form";
import { VariableSizeList as List } from 'react-window';

import OptionModal from './OptionModal';
import ItemCard from './ItemCard';

export default function ItemCards({ payload, activeCategory, editable, actions }) {
    const key = 'items';
    const [showOptions, setShowOptions] = React.useState(false);
    const form = useForm({
    });

    const childCategories = payload.categories.filter(element => element.parent_ref_id === activeCategory).map(element => element.ref_id);
    let categoriesSelected = childCategories;
    categoriesSelected.push(activeCategory);
    const filtered = payload.items.filter(element => {
        return (element.category_ref_ids || []).filter(id => categoriesSelected.includes(id)).length > 0;
    }).slice().sort((a, b) => a.sort_order - b.sort_order);
    // TODO: While updating items shift. this can be fixed using index to do operations.

    function onOptionGroup(selectedItem) {
        form.reset(selectedItem);
        setShowOptions(true);
    }

    function findKey(index, data) {
        const item = data?.items[index];
        return item._id;
    }

    const newActions = {...actions};
    
    newActions.openOptions = onOptionGroup;

    const data = {
        edit: editable,
        actions: newActions,
        items: (filtered || []),
    };

    return (
        <Fragment>
            {filtered.length == 0 ?
                (<div className='w-full flex justify-center text-gray-500 text-md uppercase py-20'>
                    No items associated
                </div>) : (
                    <List
                        style={''}
                        height={700}
                        estimatedItemSize={(filtered || []).length}
                        itemCount={(filtered || []).length}
                        itemSize={() => 200}
                        itemData={data}
                        itemKey={findKey}
                    >   
                        {ItemCard}
                    </List>
                )}
            <OptionModal
                show={showOptions}
                payload={payload}
                form={form}
                onClose={() => setShowOptions(false)}
            />
        </Fragment>
    );
}

