import React, { useState, useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import EISApi from '../../apis/eis';
import ListBox from '../../components/ListBox';
import Swal from 'sweetalert2';
import cx from 'classnames';
import { Switch } from '@headlessui/react';

const InputCPS = () => {
  const history = useHistory();
  const [form, setForm] = useState({});
  const [flush, setFlush] = useState(false);
  const [eisEnvironments, setEISEnvironments] = useState([]);
  const [selectedEISEnv, setSelectedEISEnv] = useState({
    name: 'Choose an environment',
    value: '',
  });

  useEffect(() => {
    const envVars = [
      { name: 'Production', value: 'prod' },
      { name: 'Staging', value: 'staging' },
      { name: 'QA1', value: 'qa1' },
      { name: 'QA2', value: 'qa2' },
    ];
    setEISEnvironments(envVars);
  }, []);

  const submit = () => {
    if (!selectedEISEnv.value) {
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Please select an environment',
      });
      return;
    }

    let data = { ...form };
    Object.keys(data).map((element) => {
      if (typeof data[element] != 'string') {
        data[element] = data[element].value;
      }
    });

    const { bizId, bizLocationId, brandName, bizLocationIds } = data;

    let bizLocationIdsArray = [];
    if (bizLocationIds && bizLocationIds.trim() !== '') {
      bizLocationIdsArray = bizLocationIds.split(',')
        .map((id) => parseInt(id.trim()), 10);
    }

    if (!bizId) {
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Please enter the Biz ID',
      });
      return;
    }

    EISApi.menuRequest(
      selectedEISEnv.value,
      bizId,
      brandName,
      bizLocationIdsArray,
      flush
    )
      .then((response) => {
        console.log('response', response);
        if (response.status === 'success') {
          history.push({
            pathname:
              '/cps/revision/' +
              bizId +
              '/' +
              selectedEISEnv.value +
              '/' +
              response.details.purpose_id,
          });
        } else {
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: response,
          });
          return;
        }
      })
      .catch((error) => {
        console.log('error', error);
        Swal.fire({
          icon: 'error',
          title: error.message,
          text: error.details,
        });
      });
  };

  const inputHandler = (e, name = null) => {
    if (typeof e === 'boolean') {
      setFlush(e);
      return;
    }
    if ('target' in e) {
      setForm({ ...form, [e.target.name]: e.target.value });
    } else {
      setForm({ ...form, [name]: e });
    }
  };

  return (
    <div className='w-full'>
      <div className='mt-16'>
        <div className='text-center py-4 lg:px-4'>
          <div
            className='p-2 bg-indigo-800 items-center text-indigo-100 leading-none lg:rounded-full flex lg:inline-flex'
            role='alert'
          >
            <span className='flex rounded-full bg-indigo-500 uppercase px-2 py-1 text-xs font-bold mr-3'>
              Note
            </span>
            <span className='font-semibold mr-2 text-left flex-auto'>
              You are importing menu using CPS backend!
            </span>
          </div>
        </div>
      </div>
      <div className='mt-5 max-w-md mx-auto bg-white w-1/4 h-min shadow overflow-hidden sm:rounded-lg pb-7'>
        <div className='px-4 py-6 sm:px-6'>
          <h3 className='text-md leading-6 font-medium text-gray-900'>
            POS Menu
          </h3>
          <p className='mt-1 max-w-xs text-sm text-gray-500'>
            Import menu directly from a POS
          </p>
        </div>
        <div className='px-4 sm:px-6 mt-2'>
          <div className='mb-2'>
            <ListBox
              value={selectedEISEnv}
              onChange={setSelectedEISEnv}
              label='EIS environment*'
              labelKey='name'
              options={eisEnvironments}
            ></ListBox>
          </div>
          <div>
            <label className='block mt-2 text-sm font-medium text-gray-700'>
              Biz ID*
            </label>
            <input
              type='number'
              name='bizId'
              className='mt-1 appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm'
              onChange={inputHandler}
            ></input>
          </div>

          <div>
            <label className='block mt-2 text-sm font-medium text-gray-700'>
              Brand Name
            </label>
            <input
              type='text'
              name='brandName'
              className='mt-1 appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm'
              onChange={inputHandler}
            ></input>
          </div>

          <div>
            <label className='block mt-2 text-sm font-medium text-gray-700'>
              Biz Location IDs
            </label>
            <input
              type='text'
              name='bizLocationIds'
              className='mt-1 appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm'
              onChange={inputHandler}
            ></input>
          </div>

          <div className='mt-4 flex justify-evenly'>
            <label className='block text-sm font-medium text-gray-700'>
              Flush
            </label>
            <Switch
              checked={flush}
              onChange={inputHandler}
              name='flush'
              className={cx(
                flush ? 'bg-indigo-600' : 'bg-gray-200',
                'relative inline-flex flex-shrink-0 h-5 w-10 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500'
              )}
            >
              <span className='sr-only'>Use setting</span>
              <span
                aria-hidden='true'
                className={cx(
                  flush ? 'translate-x-5' : 'translate-x-0',
                  'pointer-events-none inline-block h-4 w-4 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200'
                )}
              />
            </Switch>
          </div>

          <button
            type='button'
            className='mt-8 w-full inline-flex items-center justify-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500'
            onClick={submit}
          >
            Submit
          </button>
        </div>
      </div>
    </div>
  );
};
export default InputCPS;
