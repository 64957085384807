import moment from 'moment';

const humanize = (dateTime) => {
  return moment(dateTime).format('D MMMM YYYY, HH:mm A');
};

const Utils = {
  humanize
};

export default Utils;