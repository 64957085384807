// create a react component
import React, { Fragment, useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useQuery } from 'react-query';
import Select from 'react-select';
import EntityApi from '../../apis/entity';
import ListBox from '../ListBox';
import KeyValueInput from '../KeyValueInput';

export default function ConfigSelector({ configSchema, form }) {

    const [selected, setSelected] = useState();
    const [adapters, setAdapters] = useState([]);

    // setSelected(data[0]);


    const inputHandler = (e, name = null) => {
        if ('target' in e) {
            // setForm({ ...form, [e.target.name]: e.target.value });
        } else {
            // setForm({ ...form, [name]: e });
        }
        // setInput(e.target.value);
    };

    const selectAdapter = (field, data) => {
        let config = getConfig(data.value);
        setSelected(config);
        form.setValue('params', {});
        field.onChange(data.value);
    };

    const getConfig = (name) => {
        let config = configSchema.find((el) => el.name == name);
        return config || {};
    };
    // use effect to set the selected adapter
    useEffect(() => {
        if (configSchema?.length > 0) {
            let opt_arr = [];
            configSchema.forEach((el) => {
                opt_arr.push({ label: el.name, value: el.name });
            });
            setAdapters(opt_arr);
            if (form.getValues().name !== undefined) {
                setSelected(getConfig(form.getValues().name));
            } else {
                setSelected(configSchema[0]);
            }
        };
    }, [configSchema]);


    return (
        <Fragment>
            <div className='mt-2'>
                <Controller
                    name={'name'}
                    control={form.control}
                    render={
                        ({ field }) => <Select
                            {...field}
                            isClearable={false}
                            className='mt-1'
                            options={adapters}
                            value={adapters.find(c => c.value === selected.name)}
                            getOptionLabel={option => option.label}
                            getOptionValue={option => option.value}
                            onChange={(e) => selectAdapter(field, e)}
                        >
                        </Select>
                    }
                >
                </Controller>
                <div className="mx-auto h-min pb-8">
                    {
                        selected?.config && (
                            Object.keys(selected.config).map((element, index) => {
                                return (
                                    <Fragment key={index}>
                                        <label className='block mt-2 text-sm font-medium text-gray-700'>
                                            {selected.config[element].label}
                                        </label>
                                        {
                                            (selected.config[element].type !== 'select' &&
                                                selected.config[element].type !== 'dict'
                                            ) && (
                                                <input
                                                    type={selected.config[element].type} name={element}
                                                    defaultValue={form.getValues(element)}
                                                    {...form.register('params.' + element)}
                                                    className="mt-1 appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm">
                                                </input>
                                            )
                                        }
                                        {
                                            selected.config[element].type == 'select' && (
                                                // <ListBox value={form[element]}
                                                //     onChange={(e) => inputHandler(e, element)}
                                                //     labelKey="label"
                                                //     options={selected.config[element].values}
                                                // >
                                                // </ListBox>
                                                <Controller
                                                    name={`params.${element}`}
                                                    control={form.control}
                                                    render={
                                                        ({ field }) => <Select
                                                            {...field}
                                                            isClearable={false}
                                                            className='mt-1'
                                                            options={selected.config[element].values}
                                                            value={adapters.find(c => c.value === field.value)}
                                                            getOptionLabel={option => option.label}
                                                            getOptionValue={option => option.value}
                                                            onChange={(e) => field.onChange(e.value)}
                                                        >
                                                        </Select>
                                                    }
                                                >
                                                </Controller>
                                            )
                                        }
                                        {
                                            selected.config[element].type == 'dict' && (
                                                <Controller
                                                    name={`params.${element}`}
                                                    control={form.control}
                                                    render={
                                                        ({ field }) => <KeyValueInput
                                                            {...field}
                                                            className='mt-1'
                                                            onChange={(e) => field.onChange(e)}
                                                        >
                                                        </KeyValueInput>
                                                    }
                                                >
                                                </Controller>
                                            )
                                        }
                                    </Fragment>
                                );
                            })
                        )
                    }
                </div>
            </div>
        </Fragment>
    );
}
