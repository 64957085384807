import cx from 'classnames';
import { Dialog, Transition } from '@headlessui/react';
import React, { Fragment } from 'react';

export default function Modal({ show, onClose, children, size = 'md', disableBackdropEvent=false}) {
    let maxWidthClass = '';
    if (disableBackdropEvent) {
        onClose = () => {};
    }
    switch (size) {
    case 'xl':
        maxWidthClass = 'max-w-xl';   
        break;
    case 'lg':
        maxWidthClass = 'max-w-lg';   
        break;
    case 'md':
        maxWidthClass = 'max-w-md';   
        break;
    case 'sm':
        maxWidthClass = 'max-w-sm';   
        break;
    case 'xs':
        maxWidthClass = 'max-w-xs';    
        break;
    }


    return (
        <Transition.Root show={show} as={Fragment}>
            <Dialog as="div" className="fixed inset-0 overflow-hidden" onClose={onClose}>
                <div className="absolute inset-0 overflow-hidden">
                    <Dialog.Overlay className="absolute inset-0" />

                    <div className="fixed inset-y-0 right-0 pl-10 max-w-full flex">
                        <Transition.Child
                            as={Fragment}
                            enter="transform transition ease-in-out duration-500 sm:duration-700"
                            enterFrom="translate-x-full"
                            enterTo="translate-x-0"
                            leave="transform transition ease-in-out duration-500 sm:duration-700"
                            leaveFrom="translate-x-0"
                            leaveTo="translate-x-full"
                        >
                            <div className={cx('w-screen', maxWidthClass)}>
                                <div className="h-full flex flex-col py-6 bg-white shadow-xl overflow-y-scroll">
                                    {children}
                                </div>
                            </div>
                        </Transition.Child>
                    </div>
                </div>
            </Dialog>
        </Transition.Root>
    );
}
