import React from 'react';
import { useForm } from 'react-hook-form';
import Button from '../../components/Button';
import Modal from '../../components/Modal';


export default function EntityEditModal({ entity, show, onClose, onSave }) {
  const { register, handleSubmit } = useForm();

  function onSubmit(values) {
    onSave(values)
      .then(() => {
        onClose();
      });
  }

  return (
    <Modal show={show} onClose={onClose}>
      <div className='bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10' style={{ width: 400 }}>
        <form className='space-y-6' onSubmit={handleSubmit(onSubmit)}>
          <div>
            <label className='block text-sm font-medium text-gray-700'>
              Name
            </label>
            <div className='mt-1'>
              <input
                className='appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm'
                defaultValue={entity.name}
                {...register('name', { required: true })}
              />
            </div>
          </div>

          <div>
            <label className='block text-sm font-medium text-gray-700'>
              Identifier
            </label>
            <div className='mt-1'>
              <input
                className='appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm'
                defaultValue={entity.identifier}
                {...register('identifier')}
              />
            </div>
            {/* <p className='mt-2 text-sm text-gray-500'>CRM ID</p> */}
          </div>

          <div>
            <label htmlFor='password' className='block text-sm font-medium text-gray-700'>
              Note
            </label>
            <div className='mt-1'>
              <textarea
                rows={3}
                className='max-w-lg appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm border border-gray-300'
                defaultValue={entity.note}
                {...register('note')}
              />
            </div>
          </div>

          <div className='flex flex-row justify-center'>
            <Button type='white' onClick={onClose}>Cancel</Button>
            <Button htmlType='submit' className='ml-5'>Save</Button>
          </div>
        </form>
      </div>
    </Modal>
  );
}
