import React, { Fragment, useState } from 'react';
import { Dialog } from '@headlessui/react';
import { XIcon, PencilIcon } from '@heroicons/react/outline';
import { Controller, useForm } from "react-hook-form";
import Button from '../../../components/Button';
import Toggle from '../../../components/Toggle';
import Drawer from '../../../components/Drawer';
import ItemFirst from '../../../utils/itemFirstHelper';
import OptionDrawer from '../Options/OptionDrawer';

export default function OptionGroupDrawer({ form, setPayload, payload, show, onClose, onSubmit, size }) {
    const { control, register, handleSubmit } = form;
    const [showDrawer, setShowDrawer] = useState(false);
    const nestedForm = useForm();

    const updateOption = (obj, e) => {
        setPayload((prev) => ({
            ...prev,
            ...ItemFirst.updateObject(obj, payload, 'options')
        }));
        setShowDrawer(false);
    };

    const openDrawer = (element) => {
        nestedForm.reset(element);
        setShowDrawer(true);
    };

    return (
        <Drawer show={show} onClose={onClose} size={size} disableBackdropEvent={true}>
            <div className='p-8 pt-2 pb-0'>
                <div className='border-b flex justify-between'>
                    <Dialog.Title as='h3' className='text-lg text-left leading-6 font-medium text-gray-900 pb-3 mb-5'>
                        Edit Option Group
                    </Dialog.Title>
                    <span onClick={onClose}>
                        <XIcon className='h-4 w-4'></XIcon>
                    </span>
                </div>

            </div>
            <form
                className='h-full'
                onSubmit={handleSubmit(onSubmit)}>
                <div className='h-full flex flex-col p-8 pt-2 pb-0'>
                    <div className="mt-4">
                        <label className='block text-sm font-medium text-gray-700'>
                            Title
                        </label>
                        <div className='mt-1'>
                            <input
                                className='appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm'
                                defaultValue={form.getValues("title")}
                                {...register("title")}
                            />
                        </div>
                    </div>
                    <div className="mt-4">
                        <label className='block text-sm font-medium text-gray-700'>
                            Description
                        </label>
                        <div className='mt-1'>
                            <textarea
                                className='appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm'
                                defaultValue={form.getValues("description")}
                                {...register("description")}
                            />
                        </div>
                    </div>
                    <div className="mt-4">
                        <label className='block text-sm font-medium text-gray-700'>
                            Sort Order
                        </label>
                        <div className='mt-1'>
                            <input
                                className='appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm'
                                defaultValue={form.getValues("sort_order")}
                                {...register("sort_order", { valueAsNumber: true, default: -1 })}
                            />
                        </div>
                    </div>
                    <div className="mt-4">
                        <label className='block text-sm font-medium text-gray-700'>
                            Min Selectable
                        </label>
                        <div className='mt-1'>
                            <input
                                className='appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm'
                                defaultValue={form.getValues("min_selectable")}
                                {...register("min_selectable", { valueAsNumber: true, })}
                            />
                        </div>
                    </div>
                    <div className="mt-4">
                        <label className='block text-sm font-medium text-gray-700'>
                            Max Selectable
                        </label>
                        <div className='mt-1'>
                            <input
                                className='appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm'
                                defaultValue={form.getValues("max_selectable")}
                                {...register("max_selectable", { valueAsNumber: true, })}
                            />
                        </div>
                    </div>
                    <div className='mt-4 flex justify-between '>
                        <div className='flex align-middle w-full justify-between mr-2'>
                            <label className='text-sm mr-2 font-medium text-gray-700'>
                                Active
                            </label>
                            <Controller
                                name="active"
                                control={control}
                                render={
                                    ({ field }) => <Toggle
                                        enabled={form.getValues('active')}
                                        onChange={(e) => field.onChange(e)}
                                    />
                                }
                            >
                            </Controller>
                        </div>
                    </div>
                    <div className='mt-4 pb-4'>
                        <label className='text-sm mr-2 font-medium text-gray-700'>
                            Options
                        </label>
                        <Options
                            optionGroup={form.getValues()}
                            openDrawer={openDrawer}
                            payload={payload}
                        />
                    </div>
                    <div className='flex mt-auto justify-between mt-8'>
                        <Button onClick={onClose} type="plain">Cancel</Button>
                        <Button htmlType='submit'>Submit</Button>
                    </div>
                </div>
            </form>
            <OptionDrawer
                show={showDrawer}
                payload={payload}
                form={nestedForm}
                onClose={(e) => setShowDrawer(false)}
                onSubmit={updateOption}
                size='md'
            />
        </Drawer>
    );
}

export const Options = ({ optionGroup, openDrawer, payload }) => {
    const options = ItemFirst.findOptions(optionGroup, payload);
    return options.map(el => {
        return (
            <Fragment key={el._id}>
                
                <div className="flex text-sm justify-between" key={el?._id}>
                    <span>
                       - {el?.title}
                    </span>
                    <span
                        className='mr-4'
                        onClick={() => openDrawer(el)}
                    >
                        <PencilIcon className="w-4 h-4 hover:text-indigo-800 text-indigo-500" />
                    </span>
                </div>
            </Fragment>
        );
    });
};
