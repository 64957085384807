import React from 'react';
import { useQuery } from 'react-query';
import { Link, useHistory, useParams } from 'react-router-dom';
import { useState, useEffect } from 'react';
import entity from '../../apis/entity';
import EntityApi from '../../apis/entity';
import { CodeIcon, GlobeAltIcon, ClockIcon } from '@heroicons/react/outline';
import QueryStatusCard from '../../components/QueryStatusCard';
import Uuid from '../../components/Uuid';
import ItemInfo from '../../components/InfoCard';
import JsonModal from '../../components/JsonModal';

function classNames(...classes) {
    return classes.filter(Boolean).join(' ');
}

function GridCard({ actions }) {
    return (
        <div
            className='rounded-lg bg-gray-200 overflow-hidden shadow divide-y divide-gray-200 sm:divide-y-0 sm:grid sm:grid-cols-2 sm:gap-px'>
            {actions.map((action, actionIdx) => (
                <div
                    key={action.title}
                    className={classNames(
                        actionIdx === 0 ? 'rounded-tl-lg rounded-tr-lg sm:rounded-tr-none' : '',
                        actionIdx === 1 ? 'sm:rounded-tr-lg' : '',
                        actionIdx === actions.length - 2 ? 'sm:rounded-bl-lg' : '',
                        actionIdx === actions.length - 1 ? 'rounded-bl-lg rounded-br-lg sm:rounded-bl-none' : '',
                        'relative group bg-white p-6 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-500',
                    )}
                >
                    <div>
                        <span
                            className={classNames(
                                action.iconBackground,
                                action.iconForeground,
                                'rounded-lg inline-flex p-3 ring-4 ring-white',
                            )}
                        >
                            <action.icon className='h-6 w-6' aria-hidden='true' />
                        </span>
                    </div>
                    <div className='mt-8'>
                        <h3 className='text-lg font-medium'>
                            <Link to={action.href} className='focus:outline-none'>
                                {/* Extend touch target to entire panel */}
                                <span className='absolute inset-0' aria-hidden='true' />
                                {action.title}
                            </Link>
                        </h3>
                        <p className='mt-2 text-sm text-gray-500'>
                            {action.description}
                        </p>
                    </div>
                    <span
                        className='pointer-events-none absolute top-6 right-6 text-gray-300 group-hover:text-gray-400'
                        aria-hidden='true'
                    >
                        <svg className='h-6 w-6' xmlns='http://www.w3.org/2000/svg' fill='currentColor' viewBox='0 0 24 24'>
                            <path
                                d='M20 4h1a1 1 0 00-1-1v1zm-1 12a1 1 0 102 0h-2zM8 3a1 1 0 000 2V3zM3.293 19.293a1 1 0 101.414 1.414l-1.414-1.414zM19 4v12h2V4h-2zm1-1H8v2h12V3zm-.707.293l-16 16 1.414 1.414 16-16-1.414-1.414z' />
                        </svg>
                    </span>
                </div>
            ))}
        </div>
    );
}

const ExportAs = () => {
    let { envId, revId } = useParams();
    const [show, setShow] = useState(false);

    const { isLoading: enLoading, isError: enError, data: enData, error: eError } = useQuery(['entity', envId], () => EntityApi.getEntity(envId));
    const { isLoading: reLoading, isError: reError, data: reData, error: rError } = useQuery(['revision', revId, {'exclude': 'payload'}], EntityApi.getRevision);

    const isLoading = enLoading || reLoading;
    const isError = enError || reError;
    const error = [eError, rError];

    if (isLoading || isError) {
        return <QueryStatusCard isLoading={isLoading} isError={isError} error={error} />;
    }

    const actions = [
        {
            icon: CodeIcon,
            title: 'JSON',
            href: '/export/json/' + envId + '/' + revId,
            iconForeground: 'text-red-700',
            iconBackground: 'bg-red-50',
            description: 'Export item first menu to a JSON file',
        },
        {
            icon: ClockIcon,
            title: 'Codex',
            href: '/export/codex/' + envId + '/' + revId,
            iconForeground: 'text-green-700',
            iconBackground: 'bg-green-50',
            description: 'Publish Menu to Codex pos-int business',
        },
        {
            icon: GlobeAltIcon,
            title: 'Merchant Review',
            href: '/export/review/' + envId + '/' + revId,
            iconForeground: 'text-blue-700',
            iconBackground: 'bg-blue-50',
            description: 'Export to public link for review',
        },
    ];
    return (
        <div className='w-full'>
            <section aria-labelledby='quick-links-title' className='px-18 flex flex-col items-center'>
                <header className='mt-32'>
                    <div className='max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 text-center'>
                        <h1 className='text-3xl font-bold leading-tight text-gray-900'>Export a Menu</h1>
                        <p className='text-gray-500 mt-1'>Select how you want to Export your menu</p>
                    </div>
                </header>
                <ItemInfo name={enData.name} uid={enData.id} onClick={() => setShow(true)}></ItemInfo>
                <div className='mt-16'>
                    <GridCard actions={actions} />
                </div>
                <JsonModal
                    show={show}
                    onClose={() => setShow(false)}
                    data={reData?.payload}
                    title={'JSON'}
                />
            </section>
        </div>
    );
};


export default ExportAs;
