import React, { useState } from "react";

import cx from 'classnames';
import Categories from "./Categories";
import ItemCards from "./ItemCards";
import OptionGroupView from "./OptionGroups/OptionGroupsView";
import OptionsView from "./Options/OptionsView";
import { useForm } from "react-hook-form";
import DeleteModal from "./DeleteModal";
import ItemDrawer from "./ItemDrawer";
import ItemFirst from "../../utils/itemFirstHelper";
import OptionGroupDrawer from "./OptionGroups/OptionGroupDrawer";
import OptionDrawer from "./Options/OptionDrawer";
import { useNotification } from "../../components/Notification";

export default function MenuLayout({ payload, setPayload, activeCategory, setActiveCategory, editable, usingCache }) {
    const tabs = [
        { name: 'Menu', href: '#' },
        { name: 'Option Groups', href: '#' },
        { name: 'Options', href: '#' },
    ];

    const [showModal, setShowModal] = useState(false);
    
    const [showLocations, setShowLocation] = useState(false);
    
    const [showDrawer, setShowDrawer] = useState({
        items: false,
        options: false,
        option_groups: false
    });

    const form = useForm({});

    const [selectedKey, setSelectedKey] = useState();

    const openEdit = (element, key) => {
        setSelectedKey(key);
        form.reset(element);
        setDrawer(true, key);
    }; 

    const openDelete = (element, key) => {
        setSelectedKey(key);
        form.reset(element);
        setShowModal(true);
    };

    const deleteObject = (obj) => {
        let key = selectedKey;
        let newPayload = null;
        if (key === 'items') {
            newPayload = ItemFirst.deleteItem(obj.ref_id, payload);
        } else {
            newPayload = ItemFirst.deleteObject(obj, payload, key);
        }
        setPayload((prev) => ({
            ...prev,
            ...newPayload
        }));
        setShowModal(false);
    };

    const updateObject = (e) => {
        let key = selectedKey;
        let result = ItemFirst.updateObject(e, payload, key);
        setPayload((prev) => ({
            ...prev,
            ...result
        }));
        setDrawer(false, key);
    };

    const openEditLocations = (element, key) => {
        setSelectedKey(key);
        form.reset(element);
        setShowLocation(true);
    };

    const updateLocation = (e) => {
    };

    const closeDrawer = (key) => {
        setDrawer(false, key);
    };

    const setDrawer = (value, drawer) => {
        setShowDrawer((prev) => ({
            ...prev,
            [drawer]: value
        }));
    };

    const actions = { 'edit': openEdit, 'delete': openDelete, editLocations: openEditLocations };
    const [currentTab, setCurrentTab] = useState(tabs[0]);
    
    return (
        <div className='flex min-w-full'>
            <div className="sm:hidden">
                <select
                    id="tabs"
                    name="tabs"
                    className="block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
                    defaultValue={currentTab}
                >
                    {tabs.map((tab) => (
                        <option key={tab.name}>{tab.name}</option>
                    ))}
                </select>
            </div>
            <div className="hidden sm:block min-w-full">
                <div className="border-b border-gray-200">
                    <nav className="-mb-px flex space-x-8" aria-label="Tabs">
                        {tabs.map((tab) => (
                            <a
                                onClick={() => setCurrentTab(tab)}
                                key={tab.name}
                                href={tab.href}
                                className={cx(
                                    tab.name == currentTab.name
                                        ? 'border-indigo-500 text-indigo-600'
                                        : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300',
                                    'whitespace-nowrap py-4 px-1 border-b-2 font-medium text-sm'
                                )}
                                aria-current={tab.name == currentTab.name ? 'page' : undefined}
                            >
                                {tab.name}
                            </a>
                        ))}
                    </nav>
                </div>

                <div className="mt-4">
                    {currentTab.name === 'Menu' && (
                        <div className="flex min-w-full">
                            <div className='border-r xl:border-b-0 xl:flex-shrink-0 xl:w-64 xl:border-r xl:border-gray-200 '>
                                <Categories
                                    payload={payload}
                                    setActiveCategory={setActiveCategory}
                                    activeCategory={activeCategory}
                                    setPayload={setPayload}
                                    editable={editable}
                                />
                            </div>

                            <div className='col-span-9 w-full lg:min-w-0 lg:flex-1'>
                                <div className='h-full pb-6 px-4 sm:px-6 lg:px-8'>
                                    <div className='relative flex flex-col' style={{ minHeight: '36rem' }}>
                                        <ItemCards
                                            payload={payload}
                                            activeCategory={activeCategory}
                                            editable={editable}
                                            actions={actions}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                    {currentTab.name === 'Option Groups' && (
                        <OptionGroupView
                            payload={payload}
                            setPayload={setPayload}
                            editable={editable}
                            actions={actions}
                        ></OptionGroupView>
                    )}
                    {currentTab.name === 'Options' && (
                        <OptionsView
                            payload={payload}
                            setPayload={setPayload}
                            editable={editable}
                            actions={actions}
                        ></OptionsView>
                    )}

                    <DeleteModal
                        item={form.getValues()}
                        show={showModal}
                        onClose={() => setShowModal(false)}
                        onSubmit={deleteObject}
                    >
                    </DeleteModal>
                    <ItemDrawer
                        show={showDrawer.items}
                        payload={payload}
                        form={form}
                        onClose={(e) => closeDrawer('items')}
                        onSubmit={updateObject}
                        size='xl'
                        setPayload={setPayload}
                        usingCache={usingCache}
                    >
                    </ItemDrawer>
                    <OptionDrawer
                        show={showDrawer.options}
                        payload={payload}
                        form={form}
                        onClose={(e) => closeDrawer('options')}
                        onSubmit={updateObject}
                        size='xl'
                        setPayload={setPayload}
                    >
                    </OptionDrawer>
                    <OptionGroupDrawer
                        show={showDrawer.option_groups}
                        payload={payload}
                        form={form}
                        onClose={(e) => closeDrawer('option_groups')}
                        onSubmit={updateObject}
                        size='xl'
                        setPayload={setPayload}
                    >
                    </OptionGroupDrawer>
                </div>
            </div>
        </div>);
}