import React from 'react';


export default function DetailCard({ data, render }) {
  if (!data || data.length === 0) {
    return null;
  }

  return (
    <ul role='list' className='border border-gray-200 rounded-md divide-y divide-gray-200'>
      {(data || []).map(d => (
        <li className='pl-3 pr-4 py-3 flex justify-between text-sm items-stretch' key={d.id}>
          {render(d)}
        </li>
      ))}
    </ul>
  );
}
