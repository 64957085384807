import React, { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { useHistory, useParams } from 'react-router-dom';
import EntityApi from '../../apis/entity';
import AnalyticsService from "../../services/AnalyticsService";


const ExportJson = () => {
    let { envId, revId } = useParams();
    const history = useHistory();
    const [data, setData] = useState({
        payload: {
            categories: [],
            items: [],
            optionsGroups: [],
            options: [],
        }
    });
    const { isLoading: reLoading, isError: reError, data: reData, error: rError } = useQuery(['revision', revId, {'exclude': 'payload'}], EntityApi.getRevision);

    const exportToJson = e => {
        // e.preventDefault();
        const payload = {
            menu_revision_id: revId
        };
        EntityApi.menuExport(envId, 'FILE', 'JSON', payload).then(response => {
            AnalyticsService.onExport('JSON', {
                entity_id: envId,
                revision_id: revId,
            });
            EntityApi.downloadJson(payload).then(response => {
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', 'payload.json');
                document.body.appendChild(link);
                link.click();
            });
            history.push({
                pathname: '/entity/' + response.id
            });
        });
    };
    return <div className="w-full">
        <div className="mt-32 max-w-md mx-auto bg-white shadow overflow-hidden sm:rounded-lg pb-5">
            <div className="px-4 pt-5 sm:px-6">
                <h3 className="text-md leading-6 font-medium text-gray-900">Download JSON</h3>
                <p className="mt-1 max-w-2xl text-sm text-gray-500">Download the selected revision as JSON file</p>
            </div>
            <div className="px-4 mt-2 sm:px-6 text-sm text-gray-500">
                Entity - {envId}
            </div>
            <div className="mx-6 my-4 border-2 border-gray-300 border-dashed rounded-md px-6 pt-5 pb-6 flex justify-center">
                <div className="space-y-1 text-center">
                    {/* <svg
            className="mx-auto h-12 w-12 text-gray-400"
            stroke="currentColor"
            fill="none"
            viewBox="0 0 48 48"
            aria-hidden="true"
          >
            <path
              d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02"
              strokeWidth={2}
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg> */}
                    <div className="flex text-sm text-gray-600">
                        <label
                            htmlFor="file-upload"
                            className="relative cursor-pointer bg-white rounded-md font-medium text-indigo-600 hover:text-indigo-500 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-indigo-500"
                        >
                            <button onClick={exportToJson}>Download file</button>
                        </label>
                    </div>
                </div>
            </div>
        </div>
    </div>;
};

export default ExportJson;
