import React from 'react';
import Button from '../../components/Button';
import Modal from '../../components/Modal';

import { ExclamationIcon } from '@heroicons/react/outline';


export default function SubmitModal({ data, show, onClose, onSave }) {
  if (!data) {
    return null;
  }

  return (
    <Modal show={show} onClose={onClose}>
      <div className='bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10' style={{ width: 400 }}>
        <h3 className="text-md leading-6 font-medium text-gray-900">
          <div className="flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-red-100 sm:mr-2 sm:h-10 sm:w-10">
            <ExclamationIcon className="h-6 w-6 text-red-600" aria-hidden="true" />
          </div>  
          <div className="mt-3">Publish to Codex</div>
        </h3>
        <div className="flex justify-center">

        </div>
        <div className="mt-4">
          <p className="text-sm text-gray-500">You are going to publish the latest revision of Item First Menu to <span className="font-bold">{data.name}</span>. Are you sure ?</p>
        </div>
        <div className="flex mt-6">
          <Button className="w-1/2" type='white' onClick={onClose}>Cancel</Button>
          <Button className='w-1/2 ml-5' onClick={onSave}>Confirm</Button>
        </div>

      </div>
    </Modal>
  );
}
