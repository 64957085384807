import React, { useCallback, useState } from 'react';
import Button from '../../components/Button';
import oauthConfigs from '../../configs/oauth_configs';
import { useLocalStorage } from '../../hooks/useLocalStorage';
import { useRef } from 'react';
import ListBox from '../../components/ListBox';
import { useParams } from 'react-router';
import Constants from '../../configs/constants';
import EntityApi from '../../apis/entity';
import Uuid from '../../components/Uuid';

const env = [
    { value: 'stage', label: 'Staging' },
    { value: 'prod', label: 'Production' },
];

const AuthorizeVendor = () => {
    // create a page to get token from square pos app
    const popupRef = useRef();
    const intervalRef = useRef();
    let { vendor } = useParams();

    const oauthConf = oauthConfigs[vendor];

    const [selected, setSelected] = useState(env[0]);
    const [state, setState] = useLocalStorage(Constants.OAUTH_KEY, {});

    const [result, setResult] = useState(null);

    const POPUP_HEIGHT = 700;
    const POPUP_WIDTH = 600;

    const generateState = () => {
        const validChars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        let array = new Uint8Array(40);
        window.crypto.getRandomValues(array);
        array = array.map((x) => validChars.codePointAt(x % validChars.length));
        const randomState = String.fromCharCode.apply(null, array);
        return randomState;
    };

    const saveState = (state) => {
        // save state in local storage
        setState(state);
    };

    const removeState = () => {
        // remove state from local storage
        setState(null);
    };

    const getScope = () => {
        let scope = '';
        if (oauthConf.scope) {
            scope = oauthConf.scope.join('+');
        }
        return scope;
    };

    const enhanceUrl = (url, client_id, state, scope) => {
        let completeUrl = `${url}?client_id=${client_id}&scope=${scope}&session=false&state=${state}`;
        return completeUrl;
    };

    const closePopup = (popupRef) => {
        popupRef.current?.close();
    };

    const cleanup = (
        intervalRef,
        popupRef,
        messageHandler,
    ) => {
        clearInterval(intervalRef.current);
        closePopup(popupRef);
        removeState();
        window.removeEventListener('message', messageHandler);
    };

    const messageHandler = async (message) => {
        try {
            const messageType = message && message.data && message.data.type;
            console.log(Constants.OAUTH_RESPONSE_KEY === messageType)
            if (messageType === Constants.OAUTH_RESPONSE_KEY) {
                let result = message.data?.result;
                if (result) {
                    EntityApi.oauthCallback(vendor, result).then((res) => {
                        setResult(res);
                    });
                };
            }
        } catch (error) {
            console.log(error);
        } finally {
            const messageType = message && message.data && message.data.type;
            if (messageType === Constants.OAUTH_RESPONSE_KEY) {
                cleanup(intervalRef, popupRef, messageHandler);
            }
        };
    };

    const openPopup = (url) => {
        const top = window.outerHeight / 2 + window.screenY - POPUP_HEIGHT / 2;
        const left = window.outerWidth / 2 + window.screenX - POPUP_WIDTH / 2;
        return window.open(
            url,
            'OAuth2 Popup',
            `height=${POPUP_HEIGHT},width=${POPUP_WIDTH},top=${top},left=${left}`
        );
    };

    const authorize = useCallback(() => {
        if (Object.hasOwn(oauthConf, selected.value)) {
            let env = oauthConf[selected.value];

            const state = generateState();

            const data = {
                'env': selected.value,
                'state': state,
            };
            saveState(data);

            let scope = getScope(oauthConf);

            const url = enhanceUrl(env.url, env.clientId, state, scope);
            
            popupRef.current = openPopup(url);
            window.addEventListener('message', messageHandler);
            intervalRef.current = setInterval(() => {
                const popupClosed = !popupRef.current || !popupRef.current.window || popupRef.current.window.closed;
                if (popupClosed) {
                    // Popup was closed before completing auth...
                    console.warn('Warning: Popup was closed before completing authentication.');
                    clearInterval(intervalRef.current);
                    removeState();
                    window.removeEventListener('message', messageHandler);
                }
            }, 250);
            return () => {
                window.removeEventListener('message', messageHandler);
                if (intervalRef.current) clearInterval(intervalRef.current);
            };
        }
    });

    return <div className='w-full flex justify-center items-center'>
        {/* select box for selecting env */}
        <div className='flex flex-col'>
            <ListBox
                value={selected}
                options={env}
                onChange={setSelected}
                label="Environment"
                labelKey="label"
            />
            <Button
                className="mt-4"
                onClick={() => authorize()}
            >Authorize Square</Button>
            <div className="mt-4">
                {result && <div>Identifier <Uuid className="">{result.identifier}</Uuid></div>}
            </div>
        </div>
    </div>;

};

export default AuthorizeVendor;
