import { ArrowLeftIcon, ExclamationCircleIcon } from '@heroicons/react/outline';
import React from 'react';

export default function Header({ title, step, setStep }) {
  return (
    <div>
      <div className="flex justify-center mb-5">
        <img
          src="https://uploads-ssl.webflow.com/5f7f0385b1e5d7b4df5810a1/5f88512b1425442db36a2141_logo-theme-copy.svg"
          alt="UrbanPiper Logo"
        />
      </div>

      <h2 className="text-gray-600 font-bold text-xl text-center text-success-dark text-3xl title px-3 uppercase">{title}</h2>
      {
        step == 1 && (
          <button
            onClick={() => setStep(0)}
            className="absolute left-5 top-1 sm:left-16 sm:top-2 inline-flex justify-center items-center font-semibold text-sm text-indigo-500 hover:text-indigo-600">
            <ArrowLeftIcon className="h-3 w-3 mr-1 stroke-2"></ArrowLeftIcon>
            Back
          </button>
        )
      }
      {step == 0 && (
        <section className="w-full flex flex-col items-center border-gray-300 border-b pb-4">
          <div className="sub-heading mt-4 text-center font-semibold px-3 text-lg">
            We{'\''}ve got you covered! Here is an overview of your <span className="text-primary">menu</span>
          </div>
          <div className="mb-5 mt-2 w-full flex justify-center items-center text-green-600 bg-success-light text-center py-2 px-3">
            <ExclamationCircleIcon
              className="w-5 h-5 mr-1 exclaim-icon hidden sm:block"
            />
            You can edit title, description or prices of any item by clicking the edit icon
          </div>
        </section>
      )
      }
    </div>
  );
}