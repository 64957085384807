import React, { useState, useEffect } from 'react';
import { useHistory, useParams } from 'react-router';
import { useQuery } from 'react-query';
import EntityApi from '../../apis/entity';
import AnalyticsService from "../../services/AnalyticsService";
import CategoriesMob from './CategoriesMob';
import Button from '../../components/Button';
import '../../assets/css/public.scss';
import QueryStatusCard from '../../components/QueryStatusCard';
import Header from './Header';
import FormStep from './FormStep';
import MenuLayout from '../MenuDetail/MenuLayout';
import { v4 as uuidv4 } from 'uuid';


const LAYOUT_BREAKPOINT = 768;

const STEP_MENU = 0;
const STEP_FORM = 1;


function addIdentityToAllObjects({queryKey}) {
    let id = queryKey[1];
    return EntityApi.getPublicReview(id)
        .then(response => {
            ['categories', 'items', 'options', 'option_groups'].forEach(field => {
                (response.payload[field] || []).forEach(object => {
                    object._id = uuidv4();
                });
            });
            return response;
        });
}

const PublicReview = () => {
    let { id } = useParams();
    const history = useHistory();
    const [width, setWidth] = React.useState(window.innerWidth);
    const [step, setStep] = useState(0);
    const [form, setForm] = useState({});
    const [activeCategory, setCategory] = useState(null);
    const [editItem, setEditItem] = useState(null);
    const [payload, setPayload] = useState(null);
    const [changes, setChanges] = useState([]);

    useEffect(() => {
        const handleResizeWindow = () => setWidth(window.innerWidth);
        // subscribe to window resize event "onComponentDidMount"
        window.addEventListener('resize', handleResizeWindow);
        return () => {
            window.removeEventListener('resize', handleResizeWindow);
        };
    }, []);

    const { isLoading, isError, data, error } = useQuery(['review', id],
        addIdentityToAllObjects
    );

    React.useEffect(() => {
        if (!data) {
            return;
        }
        setForm(data.params?.form || {});
        setCategory(data.payload.categories[0]?.ref_id);
        setPayload(data.payload);
        setChanges(data.params?.changes || []);
    }, [data]);

    function onSubmit(requestCall) {
        ['categories', 'items', 'options', 'option_groups'].forEach(field => {
            (payload[field] || []).forEach(object => {
                delete object._id;
            });
        });

        let apiData = {
            meta: {
                form,
                changes,
                call_requested: requestCall
            },
            menu: payload,
        };
        EntityApi.confirmReview(data.public_url.entity_id, id, apiData).then(() => {
            const analyticsParams = {
                entity_id: data.public_url?.entity_id,
                entity_name: data.public_url?.entity_name,
                revision_id: data.public_url?.menu_revision_id,
            };

            if (requestCall) {
                AnalyticsService.onReviewCallback(analyticsParams);
            } else {
                AnalyticsService.onReviewConfirm(analyticsParams);
            }

            history.push({
                pathname: requestCall ? '/public/thank-you-callback' : '/public/thank-you',
            });
        });
    }

    function onFormChange(field, value) {
        setForm({ ...form, [field]: value });
    }

    const handleCategorySelection = (e) => {
        if (activeCategory == e) {
            setCategory(null);
        } else {
            setCategory(e);
        }
    };

    function onEdit(item) {
        setEditItem({ ...item });
    }

    function onSave(item) {
        const newItem = { ...editItem, ...item };

        const fields = ['title', 'price', 'description'];
        fields.forEach(field => {
            if (field === 'price' && +newItem[fields] === +editItem[fields]) {
                // we compare price after casting both to a number
                return;
            }

            if (newItem[field] !== editItem[field]) {
                setChanges(c => [...c, {
                    field,
                    ref_id: newItem.ref_id,
                    old_value: editItem[field],
                    new_value: newItem[field],
                }]);
            }
        });

        const updatedItemsArray = payload.items.map(i => {
            if (i.ref_id === newItem.ref_id) {
                return newItem;
            }

            return i;
        });

        setPayload({ ...payload, items: updatedItemsArray });
        setEditItem(null);
    }

    if (isLoading || isError || !payload) {
        return <QueryStatusCard isLoading={isLoading} isError={isError} error={error} />;
    }

    return (
        <div className="public-review w-full sm:px-24 relative">
            <Header
                title={data.public_url.entity_name}
                step={step}
                setStep={setStep}
            />

            {
                step == STEP_MENU && width > LAYOUT_BREAKPOINT && (
                    <MenuLayout
                        payload={payload}
                        setPayload={setPayload}
                        activeCategory={activeCategory}
                        setActiveCategory={setCategory}
                        editable={true}
                    >

                    </MenuLayout>
                )
            }
            {
                step == STEP_MENU && width < LAYOUT_BREAKPOINT && (
                    <section className="flex min-w-full">
                        <div className='w-full xl:border-b-0 xl:flex-shrink-0 xl:w-64 xl:border-r xl:border-gray-200 '>
                            <CategoriesMob
                                payload={payload}
                                setCategory={handleCategorySelection}
                                category={activeCategory}
                                onEdit={onEdit}
                            />
                        </div>
                    </section>
                )
            }
            {
                step == STEP_FORM && (
                    <FormStep
                        meta={data.meta}
                        taxes={data.taxes}
                        charges={data.charges}
                        formFields={form}
                        onFormChange={onFormChange}
                        onConfirm={() => onSubmit(false)}
                        onRequestCall={() => onSubmit(true)}
                        callRequested={data.params?.call_requested}
                        confirmed={data.params?.is_reviewed}
                    />
                )
            }

            {step == 0 && (
                <section className="fixed w-full flex justify-end bg-white shadow-sm py-3 px-5 sm:px-14 sm:px-14 md:px-14 lg:px-24 left-0 bottom-0 border-t border-gray-300">
                    <Button
                        onClick={() => {
                            setStep(1);
                        }}
                        className="bg-primary w-full sm:w-min px-12 py-3">
                        Next
                    </Button>
                </section>
            )}
        </div>
    );
};

export default PublicReview;
