import React from "react";
import { Menu, Transition } from '@headlessui/react';
import { Fragment } from 'react';
import cx from 'classnames';
import Button from "../Button";

// create component for dropdown with headless ui Menu

export default function DropDown({ children, items, size = 'md', className = '' }) {
    // logic for different types of dropdowns
    // size, color, border, border color, rounded, shadow, hover, focus, active, disabled
    // add size logic with options xs, sm, md, lg, xl
    const sizeClass = size === 'xs' ? 'px-2 py-1 text-xs' : size === 'sm' ? 'px-3 py-2 text-sm' : size === 'md' ? 'px-4 py-2 text-base' : size === 'lg' ? 'px-4 py-4 text-lg' : size === 'xl' ? 'px-8 py-4 text-xl' : 'px-4 py-2 text-base';

    const base = 'relative inline-flex items-center rounded-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50 focus:z-10 focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500';

    return (
        <Menu as="div" className={cx(className, 'relative inline-block text-left')}>
            <div>
                <Menu.Button className={cx(sizeClass, 'inline-flex w-full justify-center gap-x-1.5 rounded-md bg-white font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50')}>
                    {children}
                </Menu.Button>
            </div>

            <Transition
                as={Fragment}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
            >
                <Menu.Items className="absolute cursor-pointer right-0 z-10 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                    <div className="py-1">
                        {items?.map((item) => (
                            <Menu.Item key={item.name} disabled={item?.disabled}>
                                {({ active }) => (
                                    <a
                                        onClick={item.onClick}
                                        className={cx(
                                            active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                                            'block px-4 py-2 text-sm'
                                        )}
                                    >
                                        {item.name}
                                    </a>
                                )}
                            </Menu.Item>
                        ))}
                    </div>
                </Menu.Items>
            </Transition>
        </Menu>
    );
}
