import { Dialog } from '@headlessui/react';
import React from 'react';
import Modal from '../../components/Modal';
import Option from '../../components/Option';


export default function OptionModal({ form, payload, show, onClose }) {
    const { getValues } = form;
    const optionGroupRefIds = getValues()?.option_group_ref_ids || [];
    if (!getValues()) {
        return null;
    }
    let optionGroups = (payload?.option_groups || []).filter(el => optionGroupRefIds.includes(el.ref_id));
    
    optionGroups = optionGroups.sort((a, b) => a.sort_order - b.sort_order);

    return (
        <Modal show={show} onClose={onClose} maxWidth={'max-w-lg'}>
            <div className='p-8 max-w-lg'>
                <Dialog.Title as='h3' className='text-lg text-left leading-6 font-medium text-gray-900 border-b pb-5 mb-5'>
                    Customise {getValues('title')}
                </Dialog.Title>
                <div className='mt-2'>
                    {optionGroups.map(og => (
                        <Option
                            key={og._id}
                            optionGroup={og}
                            options={payload.options}
                            optionGroups={payload.option_groups}
                        />
                    ))}
                </div>
                <div className='mt-5 sm:mt-6'>
                    <button
                        type='button'
                        className='inline-flex justify-center w-full rounded-md border border-transparent shadow-sm px-4 py-2 bg-indigo-600 text-base font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:text-sm'
                        onClick={() => onClose()}
                    >
                        Done
                    </button>
                </div>
            </div>
        </Modal>
    );
}
