import cx from 'classnames';
import React from 'react';
import { useHistory } from 'react-router-dom';

export default function FoodType({ foodType = '4' }) {
    let classString = null;
    if (foodType === '1') { classString = 'bg-green-600'; }
    else if (foodType === '2') { classString = 'bg-red-600'; }
    else if (foodType === '3') { classString = 'bg-yellow-600'; }
    else { classString = 'bg-gray-500'; }
    return (
        <span className='border border-gray-500 inline-flex'>
            <div className={cx('rounded-full w-3 h-3 border', classString)}>
            </div>
        </span> 
    );
}
