import React, { Fragment, useState } from 'react';
import { useQuery } from 'react-query';
import { useHistory, useParams } from 'react-router-dom';
import EntityApi from '../../apis/entity';
import Button from '../../components/Button';
import ListBox from '../../components/ListBox';
import AnalyticsService from "../../services/AnalyticsService";
import KeyValueInput from '../../components/KeyValueInput';
import { Switch } from '@headlessui/react';
import cx from 'classnames';


const InputPOS = () => {
  const history = useHistory();
  let { envId = '' } = useParams();
  const [selected, setSelected] = useState({ name: '', config: null });
  const [multiBiz, setMultiBiz] = useState(false);
  const [form, setForm] = useState({});
  // if (envId === undefined) envId = '';
  const { isLoading, isError, data, error } = useQuery(['supportedPOS'],
    () => EntityApi.getSupportedPOS(),
    {
      onSuccess: (data) => {
        setSelected(data[0]);
      }
    }
  );
  const submit = () => {
    let data = { ...form };
    Object.keys(data).map(element => {
      if (typeof data[element] === 'object' && !Array.isArray(data[element])) {
        data[element] = data[element].value;
      }
    });
    EntityApi.menuImport(envId, 'POS', selected.name, data).then(response => {
      AnalyticsService.onImport('POS', {
        name: data.name,
      });

      history.push({
        pathname: '/entity/' + response.id
      });
    }).catch(err => {
      alert(err.response.data.detail);
    });
  };
  const inputHandler = (e, name = null) => {
    if ('target' in e) {
      setForm({ ...form, [e.target.name]: e.target.value });
    } else {
      setForm({ ...form, [name]: e });
    }
    // setInput(e.target.value);
  };

  const changeMultibiz = (value) => {
    setMultiBiz(value);
    const newForm = { ...form };
    newForm['is_multi_biz'] = value;
    setForm(newForm);
  };

  if (!isLoading && !isError) {
    return (
      <div className='w-full'>
        <div className='mt-32 max-w-md mx-auto bg-white w-1/4 h-min shadow overflow-hidden sm:rounded-lg pb-8'>
          <div className='px-4 py-6 sm:px-6'>
            <h3 className='text-md leading-6 font-medium text-gray-900'>
              POS Menu
            </h3>
            <p className='mt-1 max-w-xs text-sm text-gray-500'>
              Import menu directly from an POS
            </p>
          </div>
          <div className='px-4 sm:px-6 mt-2'>
            <ListBox
              value={selected}
              onChange={setSelected}
              label='POS'
              labelKey='name'
              options={data}
            />
            {selected.config &&
              Object.keys(selected.config).map((element, index) => {
                if (selected.config[element].type === 'multi_biz_brand_name') {
                  return (
                    <Fragment key={index}>
                      <div
                        style={{
                          visibility: multiBiz ? 'visible' : 'hidden',
                          height: multiBiz ? 'auto' : '0',
                        }}
                      >
                        <label className='block mt-2 text-sm font-medium text-gray-700'>
                          {selected.config[element].label}
                        </label>
                        <input
                          type='text'
                          name={element}
                          onChange={inputHandler}
                          className='mt-1 appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm'
                        ></input>
                      </div>
                    </Fragment>
                  );
                }
                return (
                  <Fragment key={index}>
                    <label className='block mt-2 text-sm font-medium text-gray-700'>
                      {selected.config[element].label}
                    </label>
                    {selected.config[element].type !== 'select' &&
                      selected.config[element].type !== 'dict' &&
                      selected.config[element].type !== 'multi_biz' && (
                        <input
                          type={selected.config[element].type}
                          name={element}
                          onChange={inputHandler}
                          className='mt-1 appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm'
                        ></input>
                      )}
                    {selected.config[element].type == 'dict' && (
                      <KeyValueInput
                        onChange={(e) => {
                          inputHandler(e, element);
                        }}
                      ></KeyValueInput>
                    )}
                    {selected.config[element].type == 'select' && (
                      <ListBox
                        value={form[element]}
                        onChange={(e) => inputHandler(e, element)}
                        labelKey='label'
                        options={selected.config[element].values}
                      ></ListBox>
                    )}
                    {selected.config[element].type == 'multi_biz' && (
                      <Switch
                        checked={form[element]}
                        onChange={changeMultibiz}
                        name={element}
                        className={cx(
                          form[element] ? 'bg-indigo-600' : 'bg-gray-200',
                          'mt-1 relative inline-flex flex-shrink-0 h-5 w-10 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500'
                        )}
                      >
                        <span className='sr-only'>Use setting</span>
                        <span
                          aria-hidden='true'
                          className={cx(
                            form[element] ? 'translate-x-5' : 'translate-x-0',
                            'pointer-events-none inline-block h-4 w-4 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200'
                          )}
                        />
                      </Switch>
                    )}
                  </Fragment>
                );
              })}

            <Button onClick={submit} className='mt-6 w-full'>
              Submit
            </Button>
          </div>
        </div>
      </div>
    );
  } else {
    return <div>Loading</div>;
  }
};
export default InputPOS;