import React, { useRef, useState } from 'react';
import { useQuery } from 'react-query';
import { Link } from 'react-router-dom';
import Button from '../../components/Button';
import QueryStatusCard from '../../components/QueryStatusCard';
import Table from '../../components/Table';
import Uuid from '../../components/Uuid';
import { hyphenCase2Title } from '../../services/CommonUtils';
import Utils from '../../apis/utils';


export default function EntityListComponent({ requestName, api, title, subtitle, type = 'TEMPORARY' }) {
    const inputRef = useRef();
    const [offset, setOffset] = useState(0);
    const [searchQuery, setSearchQuery] = useState(null);

    const { isLoading, isError, data, error } = useQuery(
        [requestName, offset, searchQuery],
        () => api({ offset, q: { ...searchQuery }, type: type })
    );

    function onSearch() {
        setSearchQuery(inputRef.current.value);
    }

    if (isLoading || isError) {
        return (
            <QueryStatusCard isLoading={isLoading} isError={isError} error={error} />
        );
    }

    return (
        <div className='w-full'>
            <div className='max-w-6xl mx-auto mt-8'>
                <div className="flex flex-row justify-between">
                    <div className='mb-8 ml-5'>
                        <h1 className='text-xl font-bold leading-tight text-gray-900'>{title}</h1>
                        <p className='text-gray-500 mt-1 text-sm'>{subtitle}</p>
                    </div>
                    <div className="flex flex-row items-center pb-5">
                        <input
                            ref={inputRef}
                            defaultValue={searchQuery}
                            onKeyDown={e => e.key === 'Enter' ? onSearch() : null}
                            className='appearance-none block w-64 px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm'
                            placeholder="Search by UUID, Name or Identifier"
                        />
                        <Button className="ml-4" onClick={onSearch}>Search</Button>
                    </div>
                </div>
                <Table
                    paginationProps={{
                        count: data.count,
                        limit: data.limit,
                        offset: data.offset,
                    }}
                    onPaginationChange={o => setOffset(o)}
                    columns={[
                        {
                            key: 'name',
                            title: 'Name',
                            render: t => (
                                <div>
                                    <div className='text-sm font-medium text-gray-900'>{t.name}</div>
                                    <div className='text-sm text-gray-500 mt-1'><Uuid>{t.id}</Uuid></div>
                                </div>
                            ),
                        },
                        {
                            key: 'updated',
                            title: 'Last Updated',
                            render: t => (
                                <div>
                                    <div className='text-sm font-medium text-gray-900'>{Utils.humanize(t.updated_at)}</div>
                                    <div className='text-sm text-gray-500'>{t.updated_by.name}</div>
                                </div>
                            ),
                        },
                        {
                            key: 'created_at',
                            title: 'Created At',
                            render: t => (
                                <div>
                                    <div className='text-sm font-medium text-gray-900'>{Utils.humanize(t.created_at)}</div>
                                    <div className='text-sm text-gray-500 capitalize'>{t.user?.name}</div>
                                </div>
                            ),
                        },
                        {
                            key: 'source',
                            title: 'Source',
                            render: t => (
                                <div>
                                    <div className='text-sm font-medium text-gray-900'>{hyphenCase2Title(t.inputs[0]?.type)}</div>
                                </div>
                            ),
                        },
                        {
                            key: 'action',
                            title: '',
                            render: t => (
                                <Link to={`/entity/${t.id}`} className='text-indigo-600 hover:text-indigo-900 text-sm'>
                                    Details
                                </Link>
                            ),
                        },
                    ]}
                    data={data.result}
                />
            </div>
        </div>
    );
}