import Constants from "../configs/constants";

/**
 * Get a JSON object from LocalStorage
 *
 * @param key
 * @param defaultValue
 * @returns {any|null}
 */
function get(key, defaultValue = null) {
  const obj = localStorage.getItem(key);
  if (!obj) {
    return defaultValue;
  }

  try {
    const parsed = JSON.parse(obj);

    return parsed || defaultValue;
  } catch (e) {
    // invalid json stored, we'll reset the key
    localStorage.removeItem(key);
    return defaultValue;
  }
}

/**
 * Set a value into local storage
 *
 * @param key
 * @param value
 */
function set(key, value) {
  localStorage.setItem(key, JSON.stringify(value));
}

/**
 * Get the access token for current user
 * 
 * @returns {string|null}
 */
function getAccessToken() {
  return get(Constants.STORAGE_USER_KEY, {}).accessToken;
}

const LocalStorageService = {
  get,
  set,

  getAccessToken,
};

export default LocalStorageService;
