import cx from 'classnames';
import React from 'react';
import Pagination from './Pagination';

export default function Table({ columns, data, dataKey = 'id', 
    className, paginationProps, onPaginationChange, stickyHeader = false , 
    headerClass='', roundedBorder=true}) {
    
    
    const headerClasses = stickyHeader ?
        'sticky top-0 z-10 hidden px-6 py-3 text-left text-xs font-medium text-gray-600 backdrop-blur backdrop-filter sm:table-cell'
        : 'px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider';

    return (
        <div className={cx(className, 'flex flex-col min-w-full')}>
            <div className='-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8'>
                <div className='py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8'>
                    <div className={cx(roundedBorder ? 'sm:rounded-lg': '', 'shadow overflow-hidden border-b border-gray-200')}>
                        <table className='min-w-full divide-y divide-gray-200'>
                            <thead className='bg-gray-50'>
                                <tr>
                                    {columns.map(c => (
                                        <th
                                            key={c.key}
                                            scope='col'
                                            className={cx(headerClass, headerClasses)}
                                        >
                                            {c.title}
                                        </th>
                                    ))}
                                </tr>
                            </thead>
                            <tbody className='bg-white divide-y divide-gray-200'>
                                {(data || []).map((datum, index) => (
                                    <tr key={datum[dataKey]}>
                                        {columns.map((c) => (
                                            <td className='px-6 py-4 whitespace-nowrap' key={c.key}>
                                                {c.render(datum, index)}
                                            </td>
                                        ))}
                                    </tr>
                                ))}
                            </tbody>
                        </table>

                        {paginationProps && (
                            <Pagination
                                paginationProps={paginationProps}
                                onPaginationChange={onPaginationChange}
                            />
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
}
