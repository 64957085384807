import React, { useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useQuery, useQueries } from 'react-query';
import cx from 'classnames';
import Uuid from '../../components/Uuid';
import EntityApi from '../../apis/entity';
import JsonModal from '../../components/JsonModal';
import ItemInfo from '../../components/InfoCard';
import AnalyticsService from "../../services/AnalyticsService";

const ExportReview = () => {
    const history = useHistory();

    const [show, setShow] = useState(false);
    const [link, setLink] = useState(false);

    let { envId, revId } = useParams();
    const { isLoading: enLoading, isError: enError, data: enData, error: eError } = useQuery(['entity', envId], () => EntityApi.getEntity(envId));
    const { isLoading: reLoading, isError: reError, data: reData, error: rError } = useQuery(['revision', revId, { exclude: 'payload' }], EntityApi.getRevision);

    const isLoading = enLoading || reLoading;
    const isError = enError || reError;
    const error = [eError, rError];

    const generateLink = () => {
        const payload = {
            menu_revision_id: revId
        };
        EntityApi.menuExport(envId, 'LINK', 'public-url', payload).then(response => {
        // EntityApi.exportPublicUrl(envId, payload).then(response => {
            AnalyticsService.onExport('Merchant Review', {
                entity_name: enData?.name,
                entity_id: envId,
                revision_id: revId,
            });
            setLink(response.outputs.pop());
        });
    };

    if (!isLoading && !isError) {
        return (
            <div className="w-full">
                <div className="mt-6 max-w-md mx-auto w-1/4 h-min  overflow-hidden  ">
                    <ItemInfo name={enData.name} uid={envId} onClick={() => setShow(true)}></ItemInfo>
                    <div className="shadow-md bg-white sm:rounded-lg  mt-4 pb-7">
                        <div className="px-4 py-5 sm:px-6">
                            <h3 className="text-md leading-6 font-medium text-gray-900">Export Review</h3>
                            <p className="mt-1 max-w-xs text-sm text-gray-500">Generate a public review link</p>
                        </div>
                        <div className="px-4 sm:px-6">
                            <div className='text-sm text-gray-500 mt-1'><Uuid>{envId}</Uuid></div>
                            <div className="w-full flex justify-center">

                            </div>
                            <button
                                onClick={generateLink}
                                type="button"
                                disabled={link}
                                className={cx('mt-8 w-full inline-flex items-center justify-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500', { 'disabled:opacity-50': link })}
                            >
                                Generate Link
                            </button>
                            <JsonModal
                                show={show}
                                onClose={() => setShow(false)}
                                data={reData.payload}
                                title={'JSON'}
                            />
                        </div>
                    </div>
                    {link && (
                        <div className='shadow-md bg-white p-5 rounded-md mt-8 text-center'>
                            <h3 className='text-md leading-6 font-medium text-gray-900 mb-2'>Public url</h3>
                            <Uuid>{window.location.origin + '/public/menu/' + link.output_data.public_url_id}</Uuid>
                            <div>
                                <a
                                    href={'/public/menu/' + link.output_data.public_url_id}
                                    type="button"
                                    target="_blank"
                                    rel="noreferrer noopener"
                                    className="mt-4 text-xs font-medium rounded-md text-indigo-500 hover:text-indigo-600 focus:outline-none"
                                >
                                    View link
                                </a>
                            </div>

                        </div>)
                    }
                </div>
            </div>
        );
    } else {
        return (<div></div>);
    }

};

export default ExportReview;
