import React, { useState } from "react";
import { PencilIcon, TrashIcon } from '@heroicons/react/outline';
import Table from "../../../components/Table";


export default function OptionGroupView({ payload, editable, actions}) {
    const limit = 10;

    const [offset, setOffset] = useState(0);

    const items = (payload?.option_groups || []).slice(offset, offset + limit).sort((a, b) => a.sort_order - b.sort_order);;

    const updateOffset = (o) => {
        setOffset(o);    
    };

    return (
        <div className="flex min-w-full">
            <Table
                paginationProps={{
                    count: (payload?.option_groups || []).length,
                    limit: limit,
                    offset: offset,
                }}
                onPaginationChange={updateOffset}
                roundedBorder={false}
                dataKey='ref_id'
                columns={[
                    {
                        key: 'title',
                        title: 'Title',
                        render: t => (
                            <div>
                                <div className='text-sm font-medium text-gray-900'>{t.title}</div>
                            </div>
                        ),
                    },
                    {
                        key: 'min_selectable',
                        title: 'Min',
                        render: t => (
                            <div>
                                <div className='text-sm font-medium text-gray-900'>{t.min_selectable}</div>
                            </div>
                        ),
                    },
                    {
                        key: 'max_selectable',
                        title: 'Max',
                        render: t => (
                            <div>
                                <div className='text-sm font-medium text-gray-900'>{t.max_selectable}</div>
                            </div>
                        ),
                    },
                    {
                        key: 'action',
                        title: 'Actions',
                        render: (t, index) => (
                            <div>
                                {editable && (
                                    <div className="flex">
                                        <span
                                            className="mr-1"
                                            onClick={() => actions.delete(t, 'option_groups')}>
                                            <TrashIcon className="w-4 h-4 hover:text-red-800 text-red-500" />
                                        </span>
                                        <span
                                            onClick={() => actions.edit(t, 'option_groups')
                                            }>
                                            <PencilIcon className="w-4 h-4 hover:text-indigo-800 text-indigo-500" />
                                        </span>
                                    </div>
                                )}
                            </div>
                        ),
                    },
                ]}
                data={items}
            />
        </div>
    )
}