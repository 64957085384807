import React from 'react';
import { Dialog } from '@headlessui/react';
import Drawer from '../../components/Drawer';
import { XIcon } from '@heroicons/react/outline';
import { Controller, useForm } from "react-hook-form";
import Select from 'react-select';
import cx from 'classnames';
import Button from '../../components/Button';
import Toggle from '../../components/Toggle';


export default function CategoryDrawer({ form, payload, show, onClose, onSubmit }) {
    const { control, register, handleSubmit } = form;
        
    return (
        <Drawer show={show} onClose={onClose} maxWidth={'max-w-lg'} size='xl'>
            <div className='p-8 pt-2 pb-0 max-w-lg'>
                <div className='border-b flex justify-between'>
                    <Dialog.Title as='h3' className='text-lg text-left leading-6 font-medium text-gray-900 pb-3 mb-5'>
                        Edit
                    </Dialog.Title>
                    <span onClick={onClose}>
                        <XIcon className='h-4 w-4'></XIcon>
                    </span>
                </div>

            </div>

            <form 
                className='h-full'
                onSubmit={handleSubmit(onSubmit)}>
                <div className='h-full flex flex-col p-8 pt-2 pb-0'>
                    <div className="mt-4">
                        <label className='block text-sm font-medium text-gray-700'>
                            Name
                        </label>
                        <div className='mt-1'>
                            <input
                                className='appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm'
                                defaultValue={form.getValues("name")}
                                {...register("name")}
                            />
                        </div>
                    </div>
                    <div className="mt-4">
                        <label className='block text-sm font-medium text-gray-700'>
                            Description
                        </label>
                        <div className='mt-1'>
                            <textarea
                                className='appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm'
                                defaultValue={form.getValues("description")}
                                {...register("description")}
                            />
                        </div>
                    </div>
                    <div className="mt-4">
                        <label className='block text-sm font-medium text-gray-700'>
                            Sort Order
                        </label>
                        <div className='mt-1'>
                            <input
                                className='appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm'
                                type="number"
                                defaultValue={form.getValues("sort_order")}
                                {...register("sort_order", { valueAsNumber: true, })}
                            />
                        </div>
                    </div>
                    <div className='mt-4 flex justify-between'>
                        <label className='text-sm font-medium text-gray-700'>
                            Active
                        </label>
                        <Controller
                            name="active"
                            control={control}
                            render={
                                ({ field }) => <Toggle
                                    enabled={form.getValues('active')}
                                    onChange={(e) => field.onChange(e)}
                                />
                            }
                        >
                        </Controller>
                    </div>
                    <div className='flex mt-auto justify-between mt-8'>
                        <Button onClick={onClose} type="plain">Cancel</Button>
                        <Button htmlType='submit'>Submit</Button>
                    </div>
                </div>
            </form>
        </Drawer>
    )
}