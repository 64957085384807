import React, { useState } from "react";
import { DownloadIcon, EyeIcon, PencilIcon, ChevronDownIcon, TrashIcon } from '@heroicons/react/outline';
import { useMutation, useQuery, useQueryClient } from "react-query";
import { Menu, Transition } from '@headlessui/react';
import { Fragment } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import cx from 'classnames';
import Button from '../../components/Button';
import DescriptionCard from '../../components/DescriptionCard';
import JsonModal from '../../components/JsonModal';
// import QueryStatusCard from '../../components/QueryStatusCard';
import Uuid from '../../components/Uuid';
import EntityEditModal from './EntityEditModal';
import InputCards from './InputCards';
import OutputCards from './OutputCards';
import RevisionCards from './RevisionCards';
import Timeline from './Timeline';
import Utils from '../../apis/utils';
import AdapterModal from './AdapterModal';
import Badge from "../../components/Badge";
import V2Api from "../../apis/v2";
import DropDown from "../../components/DropDown";

const V2Entity = () => {
    const history = useHistory();
    let { id } = useParams();
    const queryClient = useQueryClient();
    const { isLoading, isError, data, error, refetch } = useQuery(['v2-entity', id], () => V2Api.getEntityV2(id));
    const adapterMutation = useMutation(['add_adapter', id], (values) => V2Api.addAdapter(id, values), {
        onSuccess: (data, variables, context) => {
            queryClient.setQueryData(['v2-entity', id], data);
        },
    });

    const [show, setShow] = useState(false);
    const [previewData, setPreviewData] = React.useState({});
    const [AdapterView, setAdapterView] = useState(false);
    const [selectedAdapter, setSelectedAdapter] = useState({});
    
    const items = [
        { 
            name: 'Request', 
            onClick: (id) => history.push({pathname: `/input/`})
        },
        {
            name: 'Add Adapter',
            onClick: (id) => {
                setAdapterView(true);
            }
        },
        {
            name: 'Workflows',
            onClick: (id) => {
                history.push({pathname: `/v2/entity/${id}/workflows`})
            }
        }
    ];
    
    const [editing, setEditing] = useState(false);

    function onEdit() {
        setEditing(true);
    }

    function onPreview(title, json) {
        setPreviewData({
            title: '',
            json,
        });
        setShow(true);
    }

    function onEditSave(values) {
        return V2Api.updateEntityV2(data._id, values)
            .then(() => {
                refetch();
                setEditing(false);
            });
    }

    function openAdapterEditing(adapter) {
        setSelectedAdapter(adapter);
        setAdapterView(true);
    }

    function addAdapter(values) {
        adapterMutation.mutate(values);
        setAdapterView(false);
    }

    function deleteAdapter(adapter) {
        
    }

    const adapterActions = {
        edit: openAdapterEditing,
        delete: deleteAdapter
    }

    if (isLoading || isError) {
        return <div>
            Error
        </div>;
        // return <QueryStatusCard isLoading={isLoading} isError={isError} error={error} />;
    }

    return (
        <div className='w-full px-16 mt-4 flex justify-center'>
            <div className='w-full max-w-4xl'>
                <DescriptionCard
                    title='Entity Details'
                    subtitle={`Last updated ${Utils.humanize(data.updated_at)}`}
                    actions={(
                        <div className='flex flex-row'>
                            <Button
                                size="xs"
                                to={`/revision/${data.id}/${data.revision[data.revision.length - 1]?.menu_revision_id}`}
                                Icon={EyeIcon}
                            >
                                Preview Menu
                            </Button>


                            <span className="relative z-0 inline-flex shadow-sm rounded-md">
                                <Button
                                    className='ml-2'
                                    size="xs"
                                    onClick={() => {
                                        history.push({ pathname: `/export/${data.id}/${data.revision[data.revision.length - 1].menu_revision_id}`, state: { data: data } });
                                    }}
                                    Icon={DownloadIcon}
                                    type='white'
                                >
                                    Export
                                </Button>
                                <DropDown size="xs" as="div" className="ml-1 relative block" items={items}>
                                    <ChevronDownIcon className="h-6 w-5" aria-hidden="true" />
                                </DropDown>
                                
                            </span>
                        </div>
                    )}
                    data={[
                        {
                            name: 'ID',
                            value: <Uuid>{data._id}</Uuid>,
                        },
                        {
                            name: 'Name',
                            value: <EditableField onEdit={onEdit}>{data.name}</EditableField>,
                        },
                        {
                            name: 'Identifier',
                            value: <EditableField onEdit={onEdit}>{data.identifier}</EditableField>,
                        },
                        {
                            name: 'Notes',
                            value: <EditableField onEdit={onEdit}>{data.note}</EditableField>,
                        },
                        {
                            name: 'Created',
                            value: `${Utils.humanize(data.created_at)} by ${data.user?.name}`,
                        },
                        {
                            name: 'Updated',
                            value: `${Utils.humanize(data.updated_at)} by ${data.updated_by.name}`,
                        },
                        {
                            name: 'Imports',
                            value: (
                                <InputCards
                                    inputs={data.inputs}
                                    onView={o => onPreview(`${o.type} Input`, o)}
                                />
                            ),
                        },
                        {
                            name: 'Exports',
                            value: (
                                <OutputCards
                                    entityId={id}
                                    outputs={data.outputs}
                                    onView={o => onPreview(`${o.type} Output`, o)}
                                />
                            ),
                        },
                        {
                            name: 'Revisions',
                            value: <RevisionCards entity={data} revisions={data.revision} />,
                        },
                        {
                            name: 'Timeline',
                            value: <Timeline data={data.timeline} />,
                        },
                        {
                            name: 'Available Adapters',
                            value: <AvailableAdapters data={data.available_adapters} actions={adapterActions}/>
                        },
                    ]}
                />
                {/* <JsonModal
                    show={show}
                    onClose={() => setShow(false)}
                    data={previewData.json}
                    title={previewData.title}
                /> */}
                
                <EntityEditModal
                    show={editing}
                    onClose={() => setEditing(false)}
                    onSave={onEditSave}
                    entity={data}
                />
                <AdapterModal
                    show={AdapterView}
                    onClose={() => setAdapterView(false)}
                    onUpdate={() => {}}
                    onSave={addAdapter}
                />
                
            </div>
        </div>
    );
};

function AvailableAdapters({data, actions}) {
    return <div>
        {data?.map((el, index) => 
            <Badge key={index} className="bg-blue-100 text-blue-700 mr-2">
                <div className="flex">
                    <div>
                        {el.ref_title}
                    </div>
                    <div className="flex items-center">
                        {/* <PencilIcon 
                            onClick={() => actions.edit(el)}
                            className='ml-2 -mr-1 h-4 w-4 text-gray-500 cursor-pointer hover:text-gray-900'
                        />
                        <TrashIcon 
                            onClick={() => actions.delete(el)}
                            className='ml-2 -mr-1 h-4 w-4 text-gray-500 cursor-pointer hover:text-gray-900'
                        /> */}
                    </div>
                </div>
            </Badge>
        )}
    </div>;
}

function EditableField({ children, onEdit }) {
    return (
        <div className='flex flex-row justify-between'>
            <div className='mr-5 flex-1'>
                {children}
            </div>
            <PencilIcon className='ml-2 -mr-1 h-4 w-4 text-gray-500 cursor-pointer hover:text-gray-900' onClick={onEdit} />
        </div>
    );
}

export default V2Entity;