import React from 'react';
import { CheckCircleIcon } from '@heroicons/react/solid';

const ThankYou = ({ confirmed = true }) => {
  return <div className="w-full min-h-screen px-6 flex justify-center items-center">
    <div className="max-w-xl flex text-center flex-col items-center">
      <CheckCircleIcon className="h-24 w-24 text-success"></CheckCircleIcon>
      {confirmed ? (
        <>
          <div className="text-3xl font-semibold mt-8">Thanks for confirming your <span className="text-primary">menu</span></div>
          <div className="text-base text-gray-500 mt-4">Our Team will setup your menu on the aggregator storefronts and get the storefronts mapped to UrbanPiper.</div>
        </>
      ) : (
        <>
          <div className="text-3xl font-semibold mt-8">We have received your <span className="text-primary">request</span></div>
          <div className="text-base text-gray-500 mt-4">Our Team will be in touch with you shortly.</div>
        </>
      )}
    </div>
  </div>;
};

export default ThankYou;
