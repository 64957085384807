import { DownloadIcon, EyeIcon, PencilIcon, ChevronDownIcon } from '@heroicons/react/outline';
import React, { useState } from 'react';
import { useQuery } from 'react-query';
import { useHistory, useParams } from 'react-router-dom';
import EntityApi from '../../apis/entity';
import Button from '../../components/Button';
import DescriptionCard from '../../components/DescriptionCard';
import JsonModal from '../../components/JsonModal';
import QueryStatusCard from '../../components/QueryStatusCard';
import Uuid from '../../components/Uuid';
import EntityEditModal from './EntityEditModal';
import InputCards from './InputCards';
import OutputCards from './OutputCards';
import RevisionCards from './RevisionCards';
import Timeline from './Timeline';
import Utils from '../../apis/utils';
import DropDown from '../../components/DropDown';
import Badge from '../../components/Badge';
import EditDefaultConfig from '../../components/EditDefaultConfig';

const EntityDetail = () => {
    const history = useHistory();
    let { id } = useParams();

    const { isLoading, isError, data, error, refetch } = useQuery(['entity', id], () => EntityApi.getEntity(id));

    const [show, setShow] = useState(false);
    const [previewData, setPreviewData] = React.useState({});

    const [editing, setEditing] = useState(false);

    const [editConfig, setEditConfig] = useState(false);

    if (isLoading || isError) {
        return <QueryStatusCard isLoading={isLoading} isError={isError} error={error} />;
    }

    const items = [
        {
            name: 'Refetch',
            onClick: () => history.push({ pathname: `/input/${id}` }),
            disabled: false
        },
        {
            name: 'Refetch (using default config)',
            onClick: () => importUsingDefaultConfig(id),
            disabled: false
        },
    ];
    function onEdit() {
        setEditing(true);
    }

    function editDefaultConfig() {
        setEditConfig(true);
    }

    function importUsingDefaultConfig(entityId) {
        EntityApi.menuImportUseDefaultConfig(entityId).then(response => {
            history.push({ pathname: `/entity/${response.id}` });
        });
    }

    function onPreview(title, json) {
        setPreviewData({
            title: '',
            json,
        });

        setShow(true);
    }

    function saveConfig(values) {
        let defaultConfigs = [values];
        return EntityApi.updateEntity(data.id, { default_configs: defaultConfigs }).then(response => {
            refetch();
            setEditConfig(false);
        });
    };

    function onEditSave(values) {
        return EntityApi.updateEntity(data.id, values)
            .then(() => {
                refetch();
                setEditing(false);
            });
    }

    return (
        <div className='w-full px-16 mt-4 flex justify-center'>
            <div className='w-full max-w-4xl'>
                <DescriptionCard
                    title='Workflow Details'
                    subtitle={`Last updated ${Utils.humanize(data.updated_at)}`}
                    actions={(
                        <div className='flex flex-row'>
                            <Button
                                size="md"
                                to={`/revision/${data.id}/${data.revision[data.revision.length - 1]?.menu_revision_id}`}
                                Icon={EyeIcon}
                            >
                                Preview Menu
                            </Button>

                            <span className="relative z-0 inline-flex shadow-sm rounded-md">
                                <Button
                                    className='ml-2'
                                    size="md"
                                    onClick={() => {
                                        history.push({ pathname: `/export/${data.id}/${data.revision[data.revision.length - 1].menu_revision_id}`, state: { data: data } });
                                    }}
                                    Icon={DownloadIcon}
                                    type='white'
                                >
                                    Export
                                </Button>
                                <DropDown size="lg" as="div" className="ml-1 relative block" id={id} items={items}>
                                    <ChevronDownIcon className="h-4 w-4" aria-hidden="true" />
                                </DropDown>
                            </span>
                        </div>
                    )}
                    data={[
                        {
                            name: 'ID',
                            value: <Uuid>{data.id}</Uuid>,
                        },
                        {
                            name: 'Name',
                            value: <EditableField onEdit={onEdit}>{data.name}</EditableField>,
                        },
                        {
                            name: 'Identifier',
                            value: <EditableField onEdit={onEdit}>{data.identifier}</EditableField>,
                        },
                        {
                            name: 'Notes',
                            value: <EditableField onEdit={onEdit}>{data.note}</EditableField>,
                        },
                        {
                            name: 'Created',
                            value: `${Utils.humanize(data.created_at)} by ${data.user?.name}`,
                        },
                        {
                            name: 'Updated',
                            value: `${Utils.humanize(data.updated_at)} by ${data.updated_by.name}`,
                        },
                        {
                            name: 'Default Configs',
                            value: 
                                <EditableField
                                    onEdit={editDefaultConfig}
                                >{
                                        (data.default_configs || []).map((config, index) => {
                                            return (

                                                <Badge
                                                    key={index}
                                                    className='mr-2'
                                                    size='xs'
                                                >{config.name}</Badge>
                                            );
                                        })}
                                </EditableField>

                        },
                        {
                            name: 'Imports',
                            value: (
                                <InputCards
                                    inputs={data.inputs}
                                    onView={o => onPreview(`${o.type} Input`, o)}
                                />
                            ),
                        },
                        {
                            name: 'Exports',
                            value: (
                                <OutputCards
                                    entityId={id}
                                    outputs={data.outputs}
                                    onView={o => onPreview(`${o.type} Output`, o)}
                                />
                            ),
                        },
                        {
                            name: 'Revisions',
                            value: <RevisionCards entity={data} revisions={data.revision} />,
                        },
                        {
                            name: 'Timeline',
                            value: <Timeline data={data.timeline} />,
                        },
                    ]}
                />

                <JsonModal
                    show={show}
                    onClose={() => setShow(false)}
                    data={previewData.json}
                    title={previewData.title}
                />

                <EntityEditModal
                    show={editing}
                    onClose={() => setEditing(false)}
                    onSave={onEditSave}
                    entity={data}
                />
                
                <EditDefaultConfig
                    show={editConfig}
                    onClose={() => setEditConfig(false)}
                    configs={data?.default_configs}
                    onSave={saveConfig}
                >
                </EditDefaultConfig>

            </div>
        </div>
    );
};

function EditableField({ children, onEdit }) {
    return (
        <div className='flex flex-row justify-between'>
            <div className='mr-5 flex-1'>
                {children}
            </div>
            <PencilIcon className='ml-2 -mr-1 h-4 w-4 text-gray-500 cursor-pointer hover:text-gray-900' onClick={onEdit} />
        </div>
    );
}

export default EntityDetail;
