import Axios from 'axios';

// const envVars = [
//   { name: 'prod', value: process.env.REACT_APP_EIS_PROD_URL },
//   { name: 'staging', value: process.env.REACT_APP_EIS_STAGING_URL },
//   { name: 'qa1', value: process.env.REACT_APP_EIS_QA1_URL },
//   { name: 'qa2', value: process.env.REACT_APP_EIS_QA2_URL },
// ];

const envVars = [
    { name: 'prod', value: "https://eis-api.urbanpiper.com" },
    { name: 'staging', value: "https://eis-api-beta.urbanpiper.com" },
    { name: 'qa1', value: "https://eis-qa1.urbanpiper.com" },
    { name: 'qa2', value: "https://eis-qa2.urbanpiper.com" },
  ];

const REACT_APP_CPS_TOKEN="1bb6635630793b54be7edd0ec26d8eafad24e2d7";

const makeEISApiRequest = (
  eisEnv,
  bizId,
  menuRequestType,
  purposeID = null,
  brandName = null,
  bizLocationIds = null,
  flush = null
) => {
  const data = {
    biz_id: parseInt(bizId, 10),
    menu_request_type: menuRequestType,
  };

  if (purposeID !== null && purposeID !== '') {
    data.purpose_id = purposeID;
  }

  if (brandName !== null && brandName !== '') {
    data.brand_name = brandName;
  }

  if (bizLocationIds !== null && bizLocationIds !== '') {
    data.biz_location_ids = bizLocationIds;
  }

  if (flush !== null && flush !== '') {
    data.flush = flush;
  }

  let eisEnvURL;
  const envVar = envVars.find((envVar) => envVar.name === eisEnv);
  eisEnvURL = envVar.value;

  const config = {
    headers: {
      Authorization: `Token ${REACT_APP_CPS_TOKEN}`,
    },
  };
  return Axios.post(
    `${eisEnvURL}/config/internal/api/v1/menu/request`,
    data,
    config
  )
    .then((response) => response.data)
    .catch((error) => {
      console.log('errorapi', error);
      const errorMessage =
        error.response?.data?.message ||
        'An error occurred while making the request';
      throw new Error(errorMessage);
    });
};

const menuRequest = (
  eisEnv,
  bizId,
  brandName = null,
  bizLocationIds = null,
  flush = null
) => {
  return makeEISApiRequest(
    eisEnv,
    bizId,
    'pull',
    null,
    brandName,
    bizLocationIds,
    flush
  );
};

const viewMenu = (eisEnv, bizId, purposeID) => {
  return makeEISApiRequest(eisEnv, bizId, 'view', purposeID);
};

const pushMenu = (eisEnv, bizId, purposeID) => {
  return makeEISApiRequest(eisEnv, bizId, 'push', purposeID);
};

const EISApi = {
  menuRequest,
  viewMenu,
  pushMenu,
};

export default EISApi;
