import React from "react";
import { useQuery } from "react-query";
import { useParams } from "react-router-dom";
import EntityApi from "../../apis/entity";
import Table from "../../components/Table";
// create a component with tailwind ui

const AssociatedLocations = ({ children, objKey, obj, usingCache }) => {
    // get Params
    const { envId, revId } = useParams();

    const getLocations = ({ queryKey }) => {
        if (queryKey[3]) {
            return EntityApi.getFromCache(revId, queryKey[1], queryKey[2]).then(response => response);
        } else {
            return Promise.resolve({ locations: obj.locations, count: obj?.locations?.length });
        }
    };

    // get location using api using useQuery
    const { isLoading, error, data } = useQuery(['locations', objKey, obj.ref_id, usingCache], getLocations);

    if (!isLoading && !error) {
        return (
            <div>
                {/* Table with condensed content */}
                <Table
                    className={'mt-2'}
                    columns={[
                        {
                            key: 'ref_id',
                            title: 'Ref ID',
                            render: t => (
                                <div>
                                    <div className='text-sm font-medium text-gray-700'>{t.ref_id}</div>
                                </div>
                            ),
                        },
                        {
                            key: 'price',
                            title: 'Price',
                            render: t => (
                                <div>
                                    <div className='text-sm font-medium text-gray-700'>{t.price}</div>
                                </div>
                            ),
                        },
                        {
                            key: 'available',
                            title: 'Available',
                            render: t => (
                                <div>
                                    <div className='text-sm font-medium text-gray-700'>{t.available ? 'True': 'False'}</div>
                                </div>
                            ),
                        },
                    ]}
                    data={data?.locations}
                    dataKey={'ref_id'}
                ></Table>

            </div>
        );
    } else {
        return (
            <div>
                <span className='text-gray-500'>
                    Associated Locations - 0
                </span>
            </div>
        );
    }
};

export default AssociatedLocations;