import { UploadIcon } from '@heroicons/react/outline';
import moment from 'moment';
import Utils from '../../apis/utils';
import React from 'react';
import { hyphenCase2Title } from '../../services/CommonUtils';
import DetailCard from './DetailCard';
import Indicator from '../../components/StatusIndicator';

export default function InputCards({ inputs, onView }) {

    return (
        <DetailCard
            data={inputs}
            render={(input) => (
                <>
                    <div className='w-0 flex-1 flex items-center items-stretch'>
                        <div className='flex flex-col items-center justify-between'>
                            <Indicator
                                status={input.execution_log?.status}
                                size='sm'
                                className='mt-1'
                            ></Indicator>
                            <UploadIcon
                                className='flex-shrink-0 h-5 w-5 text-gray-400'
                                aria-hidden='true'
                            />
                        </div>
                        <div className='flex-1 ml-3 flex-col'>
                            <div>
                                <span className='text-md'>{hyphenCase2Title(input?.type)}</span>
                            </div>
                            {!!input.params?.url && (
                                <div className='mt-2 text-blue-600 cursor-pointer underline text-xs'>
                                    <a href={input.params.url} rel='noreferrer' target='_blank'>
                                        {input.params.url}
                                    </a>
                                </div>
                            )}
                            <div className='mt-2 text-gray-500'>
                                Imported by {input?.created_by?.name}{' '}
                                {Utils.humanize(input?.created_at)}
                            </div>
                        </div>
                    </div>

                    <div className='ml-4 min-h-full flex flex-col items-end justify-between'>
                        <span
                            className='font-medium text-indigo-600 hover:text-indigo-500 cursor-pointer'
                            onClick={() => onView(input)}
                        >
                            View
                        </span>
                    </div>
                </>
            )}
        />
    );
}
