import Constants from '../configs/constants';
import LocalStorageService from './LocalStorage';

const logout = () => {
  LocalStorageService.set(Constants.STORAGE_USER_KEY, null);
};

const AuthService = {
  logout,
};

export default AuthService;
