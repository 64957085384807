import mixpanel from 'mixpanel-browser';

mixpanel.init('4de51a0e841043ac9afb9060e1b2208c');

const AnalyticsService = {
  onLogin(user) {
    mixpanel.identify(user.id);
    mixpanel.people.set({
      '$name': user.name,
      '$email': user.email,
      'role': user.role,
    });

  },

  onLogout() {
    mixpanel.reset();
  },

  onImport(type, params = {}) {
    mixpanel.track('Import', {
      type,
      ...params,
    });
  },

  onExport(type, params = {}) {
    mixpanel.track('Export', {
      type,
      ...params,
    });
  },

  onReviewConfirm(params = {}) {
    mixpanel.track('Merchant Confirm', params);
  },

  onReviewCallback(params = {}) {
    mixpanel.track('Merchant Callback', params);
  },
};

export default AnalyticsService;
