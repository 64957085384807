import React from "react";
import { useQuery } from "react-query";
import { useParams } from "react-router-dom";
import EntityApi from "../../apis/entity";
import { useLocalStorage } from "../../hooks/useLocalStorage";
import Constants from "../../configs/constants";

const AuthThankyou = () => {
    // get params from base url
    let { vendor } = useParams();

    const [state, setState] = useLocalStorage(Constants.OAUTH_KEY, {});

    const urlSearchParams = new URLSearchParams(window.location.search);

    const [temp, setTemp] = React.useState(null);

    const params = Object.fromEntries(urlSearchParams.entries());

    const checkState = (receivedState) => {
        let current_state = state;
        if (current_state.state === receivedState.state) {
            receivedState.env = current_state.env;
            return receivedState;
        } else
            return false;
    };  

    const authCallback = () => {
        let newData = {
            'code': params.code,
            'response_type': 'code',
            'state': params.state,
        };
        let result = checkState(newData);

        if (!window.opener) {
            throw new Error('No window opener');
        }

        if (error) {
            window.opener.postMessage({
                type: Constants.OAUTH_RESPONSE_KEY,
                error: decodeURI(error) || 'OAuth error: An error has occured.',
            });
        } else if (state && result) {
            setTemp(result);
            window.opener.postMessage({
                type: Constants.OAUTH_RESPONSE_KEY,
                result: result,
            });
        } else {
            window.opener.postMessage({
                type: Constants.OAUTH_RESPONSE_KEY,
                error: 'OAuth error: State mismatch.',
            });
        }
    };


    // send post request to server
    const { isLoading, isError, data, error, refetch } = useQuery(['oauth', vendor], () => authCallback());


    if (isLoading || isError) {
        return <div>Authorizing the application</div>;
    }
    return <div>Thank you for authorizing the application</div>;
};

export default AuthThankyou;