import React from "react";
import Uuid from "../Uuid";

const ItemInfo = ({ uid, name, onClick }) => {
  return <div className='shadow-md bg-white p-5 rounded-md mt-8 text-center'>
    <h3 className='text-md leading-6 font-medium text-gray-900 mb-2'>{name}</h3>
    <Uuid>{uid}</Uuid>
    {
      onClick && (
        <div>
          {/* <button
            type="button"
            className="mt-4 text-xs font-medium rounded-md text-indigo-500 hover:text-indigo-600 focus:outline-none"
            onClick={() => onClick()}
          >
            ViewJson
          </button> */}
        </div>
      )
    }

  </div>;
};

export default ItemInfo;