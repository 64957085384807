import { Dialog } from '@headlessui/react';
import { CheckIcon, ClipboardCopyIcon } from '@heroicons/react/outline';
import React from 'react';
import ReactJson from 'react-json-view';
import Button from '../Button';
import Modal from '../Modal';


export default function JsonModal({ show, onClose, title, data, width, height }) {
  const [icon, setIcon] = React.useState('copy');

  React.useEffect(() => {
    if (icon === 'done') {
      const timeoutId = setTimeout(() => {
        setIcon('copy');
      }, 2000);

      return () => clearTimeout(timeoutId);
    }
  }, [icon, setIcon]);

  function onClick() {
    setIcon('done');
    navigator.clipboard.writeText(JSON.stringify(data, null, 2));
  }

  const iconStyles = {
    width: 15,
    height: 15,
    marginLeft: 8 ,
  };

  return (
    <Modal show={show} onClose={onClose} maxWidth="max-w-xl" width={width} height={height}>
      <div className="p-5 max-w-3xl">
        <div>
          <div className='mt-3 text-center sm:mt-5'>
            {title && (
              <Dialog.Title as='h3' className='text-lg leading-6 font-medium text-gray-900 capitalize'>
                {title}
              </Dialog.Title>
            )}
            <div className='mt-2 text-left'>
              <ReactJson
                src={data || {}}
                displayDataTypes={false}
                displayObjectSize={false}
                displayArrayKey={false}
                enableClipboard={false}
              />
            </div>
          </div>
        </div>
        <div className="sticky bottom-2 flex flex-row justify-center">
          <div className='mt-5 sm:mt-6 text-center mr-5'>
            <Button
              onClick={onClick}
              type="default"
            >
              Copy to Clipboard
              {icon === 'copy'
                ? <ClipboardCopyIcon style={iconStyles} className='inline' />
                : <CheckIcon className="inline text-green-500" style={iconStyles} />}
            </Button>
          </div>
          <div className='mt-5 sm:mt-6 text-center'>
            <Button onClick={onClose}>Done</Button>
          </div>
        </div>
      </div>
    </Modal>
  );
}
