import { DocumentIcon } from '@heroicons/react/outline';
import React from 'react';
import { Link } from 'react-router-dom';
import DetailCard from './DetailCard';
import Utils from '../../apis/utils';

export default function RevisionCards({ revisions, entity }) {
  
  return (
    <DetailCard
      data={revisions}
      render={revision => (
        <>
          <div className='w-0 flex-1 flex items-center'>
            <DocumentIcon className='flex-shrink-0 h-5 w-5 text-gray-400' aria-hidden='true' />
            <div className='flex-1 ml-3'>
              <span className='text-md'>Revision #{revision?.number}</span>
              <div className='text-gray-500'>Created
                by {revision?.created_by?.name} {Utils.humanize(revision?.created_at)}</div>
            </div>
          </div>
          <div className='ml-4 flex-shrink-0'>
            <Link
              to={`/revision/${entity.id}/${revision.menu_revision_id}`}
              className='font-medium text-indigo-600 hover:text-indigo-500'
            >
              View
            </Link>
          </div>
        </>
      )}
    />
  );
}
