import { CheckIcon, DownloadIcon, ThumbUpIcon, UploadIcon, UserIcon } from '@heroicons/react/solid';
import cx from 'classnames';
import React from 'react';
import Utils from '../../apis/utils';

const eventMap = {
  input: {
    icon: UploadIcon,
    iconBackground: 'bg-blue-500',
  },

  output: {
    icon: DownloadIcon,
    iconBackground: 'bg-green-500',
  },

  other: {
    icon: UserIcon,
    iconBackground: 'bg-gray-400',
  },
};

export default function Timeline({ data }) {

  const dataReversed = [...data];
  dataReversed.reverse();

  const timeline = data.map(t => {
    let event = t.type;
    if ((t.type || '').includes('Import')) {
      event = 'input';
    }

    if ((t.type || '').includes('Export')) {
      event = 'output';
    }

    if (!eventMap[event]) {
      event = 'other';
    }

    return {
      id: t.id,
      content: t.name,
      type: t.type === 'Import' ? 'Imported' : t.type === 'Export' ? 'Exported' : '',
      user: t.created_by?.name || 'Unknown',
      date: Utils.humanize(t.created_at),
      ...eventMap[event],
    };
  });

  return (
    <div className='flow-root py-3 px-2'>
      <ul role='list' className='-mb-8'>
        {timeline.map((event, eventIdx) => (
          <TimelineRow event={event} last={eventIdx === timeline.length - 1} key={event.id} />
        ))}
      </ul>
    </div>
  );
}

function TimelineRow({ event, last }) {
  return (
    <li key={event.id}>
      <div className='relative pb-8'>
        {!last ? (
          <span className='absolute top-4 left-4 -ml-px h-full w-0.5 bg-gray-200' aria-hidden='true' />
        ) : null}
        <div className='relative flex space-x-3'>
          <div>
            <span
              className={cx(
                event.iconBackground,
                'h-8 w-8 rounded-full flex items-center justify-center ring-8 ring-white',
              )}
            >
              <event.icon className='h-5 w-5 text-white' aria-hidden='true' />
            </span>
          </div>
          <div className='min-w-0 flex-1 flex justify-between space-x-4'>
            <div>
              <p className='text-sm text-gray-900'>
                {event.content}{' '}
                <div className='text-gray-500'>{event.type} by {event.user}</div>
              </p>
            </div>
            <div className='text-right text-sm whitespace-nowrap text-gray-500'>
              {event.date}
            </div>
          </div>
        </div>
      </div>
    </li>
  );
}
