import React from 'react';
import { Collapse } from 'react-collapse';
import ItemCardsMob from './ItemCardsMob';
import { ChevronDownIcon, ChevronRightIcon } from '@heroicons/react/outline';

export default function CategoriesMob({ payload, category, setCategory, onEdit }) {
  return (
    <div className="pt-3 pb-16">
      {payload.categories.map(c => (
        <Category
          key={c.ref_id}
          category={c}
          activeCategory={category}
          onClick={() => setCategory(c.ref_id)} payload={payload}
          onEdit={onEdit}
        />
      ))}
    </div>
  );
}

function Category({ category, activeCategory, onClick, payload, onEdit }) {
  return (
    <div>
      <div className="px-3 py-1 text-base font-bold flex items-center" onClick={onClick}>
        {
          activeCategory == category.ref_id && (
            <ChevronDownIcon className="h-4 w-4 mr-2"></ChevronDownIcon>
          )
        }
        {
          activeCategory != category.ref_id && (
            <ChevronRightIcon className="h-4 w-4 mr-2"></ChevronRightIcon>
          )
        }
        {category.name}
      </div>
      <Collapse isOpened={activeCategory == category.ref_id}>
        <ItemCardsMob payload={payload} activeCategory={activeCategory} onEdit={onEdit} />
      </Collapse>
    </div>
  );
}