import { useEffect } from 'react';
import { useMutation } from 'react-query';
import AuthApi from '../../../apis/auth';
import Constants from '../../../configs/constants';
import { useLocalStorage } from '../../../hooks/useLocalStorage';
import AnalyticsService from "../../../services/AnalyticsService";
import AuthService from '../../../services/Auth';

const Authenticate = ({ children }) => {
  const [userInfo, setUserInfo] = useLocalStorage(Constants.STORAGE_USER_KEY, {});
  const { mutate, isLoading } = useMutation(AuthApi.authenticateGoogleToken, {
    onError: () => {
      AuthService.logout();
      AnalyticsService.onLogout();
    },
    onSuccess: (response) => {
      setUserInfo({ ...userInfo, role: response?.user?.role });
      AnalyticsService.onLogin(response.user);
    }
  });

  useEffect(() => {
    const accessToken = userInfo?.tokenId;
    if (!accessToken) {
      return;
    }
    mutate({
      accessToken,
    });
  }, []);

  return !isLoading && children;
};

export default Authenticate;
