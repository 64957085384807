import React from 'react';
import ListBox from '../../components/ListBox';
import { useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import Button from '../../components/Button';
import EntityApi from '../../apis/entity';
import AnalyticsService from "../../services/AnalyticsService";

const env = [
    { value: 'stage', label: 'pos-int' },
    { value: 'prod', label: 'prod' },
];

const InputCodex = () => {
    let { envId = '' } = useParams();
    const history = useHistory();
    const [selected, setSelected] = useState(env[0]);
    const [form, setFormField] = useState({
        username: '',
        password: '',
        biz_id: ''
    });
    const submit = () => {
        let data = { ...form };
        data['environment'] = selected.value;
        EntityApi.menuImport(envId, 'INTERNAL', 'Codex', data).then(response => {
            AnalyticsService.onImport('Codex', {
                name: 'Codex',
            });
            history.push({
                pathname: '/entity/' + response.id
            });
        });
    };
    const inputHandler = (e) => {
        const newForm = { ...form };
        newForm[e.target.name] = e.target.value;
        setFormField(newForm);
    };


    return (
        <div className="w-full">
            <div className="mt-32 max-w-md mx-auto bg-white w-1/4 pb-7 bg-white h-min shadow overflow-hidden sm:rounded-lg">
                <div className="px-4 py-6 sm:px-6">
                    <h3 className="text-md leading-6 font-medium text-gray-900">Codex Menu</h3>
                    <p className="mt-1 max-w-xs text-sm text-gray-500">Import menu directly from Codex</p>
                </div>
                <div className="px-4 sm:px-6 flex flex-col items-center">

                    <div className="mt-2 w-full">
                        <ListBox value={selected}
                            onChange={setSelected}
                            label="Environment"
                            options={env}
                            labelKey="label"
                        ></ListBox>
                        <div className="mt-4">
                            <label className='block text-sm font-medium text-gray-700'>
                                Biz Id
                            </label>
                            <div className='mt-1'>
                                <input
                                    className='appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm'
                                    defaultValue={form.biz_id}
                                    name='biz_id'
                                    onChange={inputHandler}
                                    placeholder="biz_id"
                                />
                            </div>
                        </div>
                        <div className="mt-4">
                            <label className='block text-sm font-medium text-gray-700'>
                                Username
                            </label>
                            <div className='mt-1'>
                                <input
                                    className='appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm'
                                    defaultValue={form.username}
                                    name='username'
                                    onChange={inputHandler}
                                    placeholder="Username"
                                />
                            </div>
                        </div>


                        <div className="mt-4">
                            <label className='block text-sm font-medium text-gray-700'>
                                Api Key
                            </label>
                            <div className='mt-1'>
                                <input
                                    className='appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm'
                                    defaultValue={form.password}
                                    name='password'
                                    type="password"
                                    onChange={inputHandler}
                                    placeholder="Key"
                                />
                            </div>
                        </div>

                        <div className='flex flex-row justify-center'>
                            <Button onClick={submit} className='mt-8 w-full justify-center'>Submit</Button>
                        </div>

                    </div>
                </div>

            </div>
        </div>
    );
};

export default InputCodex;
